import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import phoneField from '../../utils/formFields/phoneField';
import emailField from '../../utils/formFields/emailField';

const useFields = (purpose) => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const [advisors, setAdvisors] = useState([]);
  const [currentAdvisor, setCurrentAdvisor] = useState();
  const [mails, setMails] = useState([]);

  const getAdvisor = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/advisors/${id}`
      });
      setCurrentAdvisor(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getAdvisor();
      })();
    }
  }, []);

  const getAdvisors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=users:MANAGER-ADVISOR`
      });
      setAdvisors(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMails = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users/mails/' });
    setMails(data);
  };

  useEffect(() => {
    getAdvisors();
    getMails();
  }, []);

  const fields = [
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'last_name',
      name: ['last_name'],
      rules: [{ required: true }]
    },
    emailField([...advisors, ...mails], currentAdvisor?.email, purpose, true),
    phoneField(true),
    ...(['users:MANAGER-ADVISOR', 'admins:DIRECTION'].includes(user?.role)
      ? [
          {
            label: 'ref_manager_advisor',
            name: ['ref_manager_advisor'],
            rules: [{ required: true }],
            input: (
              <Select
                options={advisors.map((adv) => ({
                  label: `${adv.first_name} ${adv.last_name}`,
                  value: adv._id
                }))}
              />
            )
          }
        ]
      : [])
  ];

  return {
    fields
  };
};

export default useFields;
