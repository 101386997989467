import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import commissionContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';

const ShowCommission = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [commission, setCommission] = useState(null);
  const getCommission = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commissions/${id}?populate=contract,partner,type`
      });
      setCommission(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCommission();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('commissions.show.title')} />
      <ContentCustom>
        <DescriptionList data={commissionContent(commission || {})} translate />
      </ContentCustom>
    </>
  );
};

export default ShowCommission;
