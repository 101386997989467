import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Row,
  Col,
  Divider,
  Tooltip,
  Tag,
  Button,
  Switch,
  Table,
  Radio,
  Modal,
  AutoComplete,
  message,
  Input,
  Spin,
  Space,
  Select
} from 'antd';

// Import Swiper styles
import 'swiper/swiper.scss';
import '../../../assets/styles/SCSS/comparisonTable.scss';
import '../../../assets/styles/SCSS/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import {
  CarouselContainer,
  HeaderCarousel,
  DescriptionCarousel,
  HeaderCarouselFirst,
  DescriptionCarouselFirst
} from './ExtraComponents';
import { styles } from '../styles_home/home_seller.js';
import SFRlogo from '../../../assets/images/sfr-logo.png';
import useHandleResize from '../../../utils/HandleResize';

const CarouselSlider = ({
  redirection_url,
  tabImages,
  tabHeaderText,
  tabBodyText,
  tableHighlightingtext,
  customColorText,
  offset,
  is_from_partner
}) => {
  SwiperCore.use([Navigation, Autoplay]);

  const { width } = useHandleResize();

  const SwiperContainer = styled.div`
    display: flex;
    background: white;
    align-items: center;
    justify-content: center;
    flex-direction: ${width < 768 ? 'column' : 'row'}
    height: 150px;
    &::before {
      content: '';
      ${'' /* TODO: remettre ça pour sfr */}
      ${'' /* 98% 50% / 7% no-repeat url(${SFRlogo}), */}
      background: 0% 36% / cover url(${props => props.img});
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.75;
    }
  `;

  return (
    <>
      {!is_from_partner ? (
        <CarouselContainer offsetTop={offset}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
          >
            {tabHeaderText.map((slide, index) => (
              <SwiperSlide key={index}>
                <div
                  style={{
                    display: 'flex',
                    background: 'white',
                    alignItems: 'center',
                    height: '150px'
                  }}
                >
                  <img
                    src={tabImages[index]}
                    style={{
                      flex: 0.5,
                      alignSelf: 'end',
                      display: width < 650 ? 'none' : 'initial'
                    }}
                  />
                  <div
                    style={{
                      padding: width < 768 ? '1rem' : '2rem',
                      flex: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: width < 768 ? 'column' : 'row'
                    }}
                  >
                    <div>
                      <HeaderCarouselFirst>
                        {slide}
                        <span
                          style={{
                            color: customColorText,
                            marginLeft: '0.4rem'
                          }}
                        >
                          {tableHighlightingtext[index]}
                        </span>
                      </HeaderCarouselFirst>
                      <DescriptionCarouselFirst>
                        {tabBodyText[index]}
                      </DescriptionCarouselFirst>
                    </div>
                    <Link to={redirection_url}>
                      <Button type="primary" style={styles.carousel_button}>
                        Je découvre
                      </Button>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainer>
      ) : (
        <CarouselContainer offsetTop={offset}>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
          >
            {tabHeaderText.map((slide, index) => (
              <SwiperSlide key={index}>
                <SwiperContainer img={tabImages[index]}>
                  <div style={{ width: '100%', padding: '10rem' }}>
                    <HeaderCarousel offsetLeft={index === 0 ? '25%' : '37.5%'}>
                      {slide}
                    </HeaderCarousel>
                    <DescriptionCarousel>
                      {tabBodyText[index]}
                    </DescriptionCarousel>
                  </div>
                </SwiperContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainer>
      )}
    </>
  );
};

CarouselSlider.propTypes = {
  redirection_url: PropTypes.string,
  tabImages: PropTypes.array,
  tabHeaderText: PropTypes.array,
  tabBodyText: PropTypes.array,
  tableHighlightingtext: PropTypes.array,
  offset: PropTypes.string,
  is_from_partner: PropTypes.bool
};

CarouselSlider.defaultProps = {
  redirection_url: null,
  tabImages: [],
  tabHeaderText: [],
  tabBodyText: [],
  tableHighlightingtext: [],
  offset: null,
  is_from_partner: false
};

export default CarouselSlider;
