import React from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { rationMonth } from '../../../utils';

const { Text } = Typography;

const columns = (purpose) => [
  {
    title: purpose === '/sellers' ? 'Vendeurs' : 'Magasins',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Taux de résiliation', // totalcontract
    dataIndex: ['terminated', 'lastMonth'],
    render: (_, row) => `${rationMonth(row.terminated, row.lastMonth) || '-'} %`
  },
  {
    title: 'Nombre de résiliation', // totalcontract
    dataIndex: 'terminated',
    key: 'terminated'
  },
  {
    title: 'Résiliations client', // total resil client
    dataIndex: 'advisorTerminated',
    key: 'advisorTerminated'
  },
  {
    title: 'Résiliations magasin', // total resil par le magazin
    dataIndex: 'storeTerminated',
    key: 'storeTerminated'
  },

  {
    dataIndex: ['route', 'id'],
    render: (_, row) => (
      <Link to={{ pathname: `${row.route}/show/${row.id}` }}>
        <EyeOutlined style={{ fontSize: '18px' }} />
      </Link>
    )
  }
];

const ListTerminate = ({ data }) => {
  const purpose = data?.[0]?.route;

  return (
    <Table
      columns={columns(purpose)}
      dataSource={data}
      scroll={{ x: 'max-content' }}
      rowKey="name"
      summary={(pageData) => {
        let totalRateTer = 0;
        let totalLastMont = 0;
        let totalTerminated = 0;
        let totalAdvisorTerminated = 0;
        let totalStoreTerminated = 0;

        pageData.forEach(
          ({ lastMonth, terminated, advisorTerminated, storeTerminated }) => {
            totalLastMont += lastMonth;
            totalTerminated += terminated;
            totalAdvisorTerminated += advisorTerminated;
            totalStoreTerminated += storeTerminated;
          }
        );
        totalRateTer = totalTerminated / totalLastMont;
        return (
          <>
            <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
              <Table.Summary.Cell>Total</Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{ fontWeight: 'bold' }}>
                  {(totalRateTer * 100).toFixed(2) !== 'NaN'
                    ? (totalRateTer * 100).toFixed(2)
                    : '-'}
                  %
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{ fontWeight: 'bold' }}>{totalTerminated}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{ fontWeight: 'bold' }}>
                  {totalAdvisorTerminated}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text style={{ fontWeight: 'bold' }}>
                  {totalStoreTerminated}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

ListTerminate.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ route: PropTypes.string }))
};

ListTerminate.defaultProps = {
  data: []
};

export default ListTerminate;
