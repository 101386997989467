import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const boxShadow = 'rgba(0, 0, 0, 0.04) 0px 3px 5px';
const ProfileHeader = styled.div`
  background: white;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${boxShadow};
  border-radius: 5px;
  /*flex-flow: row wrap*/
`;

const ContainerBody = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const AdvantagesContainer = styled.div`
  background: white;
  padding: 1.5rem;
  flex: 0.9;
  @media screen and (max-width: 1300px) {
    flex: none;
    width: 100%;
  }
  position: relative;
  box-shadow: ${boxShadow};
`;

const ActionsContainer = styled.div`
  background: white;
  padding: 1.5rem;
  flex: 0.9;
  margin-left: 1rem;
  /* height: 500px */
  box-shadow: ${boxShadow};
  @media screen and (max-width: 1300px) {
    flex: none;
    width: 100%;
    margin-top: 1rem;
    margin-left: 0rem;
  }
`;

const styles = {
  graph_header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  graph_header_first: {
    fontSize: '1rem',
    fontWeight: 500
  },
  graph_header_second: {
    color: 'gray'
  },
  app_divider_container_benefit: {
    width: '50px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_comment: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_timeline: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  }
};

export {
  styles,
  ProfileHeader,
  AdvantagesContainer,
  ActionsContainer,
  ContainerBody
};
