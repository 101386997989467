import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';
import { ReactComponent as GoldIcon } from '../home_icons/GoldIcon.svg';
import { ReactComponent as PlatIcon } from '../home_icons/PlatIcon.svg';
import { styles } from '../styles_home/home_seller.js';
import Card from '../components_home/Card';
import '../../../assets/styles/SCSS/comparisonTable.scss';
import {
  Row,
  Col,
  Divider,
  Tooltip,
  Tag,
  Button,
  Switch,
  Table,
  Radio,
  Modal,
  AutoComplete,
  message,
  Input,
  Spin,
  Space
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { ReactComponent as ContractIcon } from '../home_icons/ContractIcon.svg';
import { ReactComponent as TerminatedContract } from '../home_icons/TerminatedContract.svg';
import { ReactComponent as GoalIcon } from '../home_icons/GoalIcon.svg';
import { Donut_chart } from '../charts_folder/Donut_chart';
import { Line_chart } from '../charts_folder/Line_chart';
import { BarChart } from '../charts_folder/BarChart';
import { Two_axis_chart } from '../charts_folder/Two_axis_chart';
import '../../../assets/styles/SCSS/tableHome.scss';
import useAuthContext from '../../../contexts/AuthContext';
import { motion } from 'framer-motion';
import ComparisonTableModal from '../components_home/ComparisonTableModal';
import TableContracts from '../components_home/TableContracts';
import SearchDatas from '../components_home/SearchDatas';
import {
  Divider_header,
  Graph_container,
  Major_graph_container,
  RowCard,
  BenefitContent,
  TabContract,
  DonutChart,
  FirstContainer,
  ContainerCard,
  LineGraph,
  TabHeader,
  PlatCard,
  GoldCard,
  SearchContainer,
  ContentBenefitGold,
  ContentBenefitPlat,
  ComparisonTable,
  ThTable,
  TdTable,
  ButtonComparisonGold,
  ButtonComparisonPlat,
  CarouselContainer
} from '../components_home/ExtraComponents';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';

import '../../../assets/styles/SCSS/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import ad1 from '../images/clay-banks-Xv.jpg';
import ad2 from '../images/clay-banks-Xv.jpg';
import ad3 from '../images/discount3.jpg';
import CarouselSlider from '../components_home/CarouselSlider';

const cols = {
  goal_prev: {
    min: 0
  },
  goal_real: {
    min: 0
  }
};

const cols_donuts = {
  percent: {
    formatter: val => (val = `${val * 100}%`)
  }
};

const Home_seller = () => {
  SwiperCore.use([Navigation, Autoplay]);
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [datas_benefits, setDatas_benefits] = useState([]);
  const [startingBenefit, setStartingBenefit] = useState([]);
  const [datas_contracts, setDatas_contracts] = useState([]);
  const [datas_metrics, setDatas_metrics] = useState({
    nbr_contract: 0,
    goal_real: 0,
    nbr_terminated_monthly: 0,
    nbr_contract_monthly: 0,
    goal_prev: 0
  });
  const [datas_sided_contracts, setDatas_sided_contracts] = useState([]);
  const [datas_activityContract, setDatas_activityContract] = useState([]);

  const routesNames = [
    `/getSearchResults/`,
    `/getBenefits/`,
    `/getLastContracts/`,
    `/getMetrics/`,
    `/getNumberSidedContracts/`,
    `/getActivityContracts/`
  ];

  const getDatas = useCallback(async () => {
    try {
      for (let index = 0; index < routesNames.length; index++) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_seller${routesNames[index]}${user._id}`
        });
        if (index === 0) {
          setSearchResults(data);
        } else if (index === 1) {
          setStartingBenefit(data[data.length - 1]);
          setDatas_benefits(data.slice(0, -1));
        } else if (index === 2) {
          setDatas_contracts(data);
        } else if (index === 3) {
          setDatas_metrics(data);
        } else if (index === 4) {
          setDatas_sided_contracts(data);
        } else if (index === 5) {
          setDatas_activityContract(data);
        }
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(true);
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const fields = [
    'Jan',
    'févr',
    'Mars',
    'Avr',
    'Mai',
    'Juin',
    'Jul',
    'Août',
    'Sept',
    'Oct',
    'Nov',
    'Déc'
  ];
  const [name_card, setName_card] = useState(false);
  const [isModalVisibleCard, setIsModalVisibleCard] = useState(false);
  const [datas_goal, setDatas_goal] = useState([]);
  const [titleCard, setTitleCard] = useState();
  const [datas_resiliated, setDatas_resiliated] = useState([]);
  const routesNamesCard = [
    `/getDetailedTerminatedContracts/`,
    `/getDetailedGoal/`
  ];

  const getDatas_modal = useCallback(async () => {
    try {
      for (let index = 0; index < routesNamesCard.length; index++) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_seller${routesNamesCard[index]}${user._id}`
        });
        if (index === 0) {
          setDatas_resiliated(data);
        } else if (index === 1) {
          setDatas_goal(data);
        }
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  }, [user._id]);

  useEffect(() => {
    getDatas_modal();
  }, [getDatas_modal]);

  const handleOkCard = () => {
    setIsModalVisibleCard(false);
  };
  const handleCancelCard = () => {
    setIsModalVisibleCard(false);
  };

  const showModalCard = name_card => {
    setName_card(name_card);
    if (name_card == 'Goal') {
      setTitleCard('Progression des objectifs');
    } else {
      setTitleCard('Progression du taux de résiliation');
    }
    setIsModalVisibleCard(true);
  };

  const [isModalVisibleContract, setIsModalVisibleContract] = useState(false);
  const handleOkContract = () => {
    setIsModalVisibleContract(false);
  };
  const handleCancelContract = () => {
    setIsModalVisibleContract(false);
  };

  const showModalContract = id => {
    setIsModalVisibleContract(true);
  };

  const SampleContainerSearch = styled.div`
    flex: 1.02;
    @media screen and (max-width: 1500px) {
      flex: none;
    }
  `;

  return !isLoading ? (
    <div
      style={{
        padding: '20px',
        marginTop: '0rem',
        paddingTop: '2rem',
        maxWidth: '1600px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <Spin tip="Veuillez patienter..." size="large" />
    </div>
  ) : (
    <div
      style={{
        padding: '20px',
        marginTop: '0rem',
        paddingTop: '2rem',
        maxWidth: '1600px',
        margin: 'auto'
      }}
    >
      <motion.div
        animate={{ width: ['0%', '100%'], opacity: [0, 1] }}
        transition={{ duration: 0.1, delay: 0.1 }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchContainer className="Search_container">
            <SearchDatas searchResults={searchResults} />
          </SearchContainer>
          <SampleContainerSearch />
        </div>
      </motion.div>
      <motion.div
        animate={{ y: -20, opacity: [0, 1] }}
        transition={{ duration: 0.1 }}
      >
        <FirstContainer>
          <RowCard justify="space-around" order="2">
            <div style={styles.graph_header}>
              <p style={styles.graph_header_first}>Mes activités</p>
              <p style={styles.graph_header_second} />
            </div>
            <div style={styles.app_divider_container_metric} />
            <ContainerCard>
              <Card
                id_card="contract"
                is_middle={false}
                number={`${datas_metrics.nbr_contract}`}
                tagColor="#e18891"
                iconComponent={ContractIcon}
                is_present_action
                name_action="Contrats"
                extra_name=""
                link_action="/customers/create?from=dashboard"
              />
              <Card
                id_card="goal"
                is_middle
                number={`${datas_metrics.goal_real}`}
                tagColor="#75cd99"
                iconComponent={GoalIcon}
                is_present_action={false}
                name_action="Objectif"
                extra_name={`/${datas_metrics.goal_prev}`}
              />
              <Card
                id_card="terminate"
                is_middle={false}
                number={`${datas_metrics.nbr_terminated_monthly}`}
                tagColor="#efb055"
                iconComponent={TerminatedContract}
                is_present_action={false}
                name_action="Taux de résiliation"
                extra_name={`/${datas_metrics.nbr_contract_monthly}`}
              />
            </ContainerCard>
          </RowCard>
        </FirstContainer>
      </motion.div>
      <motion.div
        animate={{ y: -20, opacity: [0, 1], delay: 12 }}
        transition={{ duration: 0.1, delay: 0.05 }}
      >
        <Major_graph_container>
          <LineGraph>
            <Graph_container>
              <div style={styles.graph_header}>
                <p style={styles.graph_header_first}>
                  Acquisition des contrats
                </p>
                <p style={styles.graph_header_second} />
              </div>
              <div style={styles.app_divider_container_line} />
              <div
                // onClick={() => showModalCard('Goal')}
                style={{ cursor: 'pointer' }}
              >
                <Two_axis_chart
                  data={datas_sided_contracts}
                  xAxis="date"
                  yAxis="goal_real"
                  zAxis="goal_prev"
                  cols={cols}
                />
              </div>
            </Graph_container>
          </LineGraph>
          <DonutChart>
            <Graph_container>
              <div style={styles.graph_header}>
                <p style={styles.graph_header_first}>Taux de rétention</p>
                <p style={styles.graph_header_second} />
              </div>
              <div style={styles.app_divider_container_donut} />
              <div
                // onClick={() => showModalCard('Terminate')}
                style={{ cursor: 'pointer' }}
              >
                <Donut_chart
                  data={datas_activityContract}
                  xAxis="item"
                  yAxis="percent"
                  cols={cols_donuts}
                />
              </div>
            </Graph_container>
          </DonutChart>
        </Major_graph_container>
      </motion.div>
      <Modal
        title={titleCard}
        visible={isModalVisibleCard}
        width={1000}
        onOk={handleOkCard}
        onCancel={handleCancelCard}
        footer={null}
      >
        {name_card && name_card === 'Goal' ? (
          <BarChart
            data={datas_goal}
            xAxis="month"
            yAxis="goal"
            fields={fields}
            type="interval"
          />
        ) : (
          name_card &&
          name_card === 'Terminate' && (
            <BarChart
              data={datas_resiliated}
              xAxis="month"
              yAxis="terminate"
              fields={fields}
              type="intervalStack"
            />
          )
        )}
      </Modal>
    </div>
  );
};

export default Home_seller;
