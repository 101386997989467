import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Divider, message, Popconfirm, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const StyledTable = styled.div`
  .rowStyle {
    cursor: pointer;
  }
`;

const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  forceRefresh,
  editAction,
  deleteAction,
  showAction,
  onDoubleClickAction
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const pageSize = Number(params.get('pS') || 10);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 0,
    showSizeChanger: true
  });
  const iconSize = 18;

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);
      const searchURL = searchValue ? `/search/${searchValue}` : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers['x-total-count'], 10)
        });
        setResources(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
      } catch (e) {
        message.error(e.message);
      }
      setIsLoading(false);
    },
    [
      searchValue,
      currentPage,
      currentSorter,
      pageSize,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      // This reloads the notifications for the menu, it was added here
      // because I was too lasy to make a new component for 1 line changed
      await fetchData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response) message.error(e.response.data.message);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    history.push({
      pathname,
      search: `?p=${page.current}&pS=${page.pageSize}${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      fixed: 'right',
      width: '8%',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => (
        <div style={{ float: 'right' }}>
          {showAction && (
            <Link
              to={{
                pathname: `${path || pathname}/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          )}
          {editAction && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `${path || pathname}/edit/${key}`
                }}
                style={{
                  color: 'var(--clientColor)'
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}
          {deleteAction && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteResource(key)}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: '#b51010', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </div>
      )
    }
  ];

  const adjustedColumns = columns.map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList
        .filter(([key, value]) => key && value)
        .forEach((f) => {
          if (f.split('=').length) {
            const [key, values] = f.split('=');
            filters[key] = values;
          }
        });
    }

    return {
      ...col,
      // Dropping this here because most of our devs forget the key in their cols
      ...(col.dataIndex && !col.key
        ? {
            key:
              col.dataIndex && Array.isArray(col.dataIndex)
                ? col.dataIndex.join('.')
                : col.dataIndex
          }
        : {}),
      ...(col.dataIndex === orderKey || col.key === orderKey
        ? {
            sortOrder: order
          }
        : { sortOrder: false }),
      ...(filters[col.dataIndex] || filters[col.key]
        ? { filteredValue: filters[col.key || col.dataIndex].split(',') }
        : { filteredValue: [] }),
      ...{ ellipsis: true }
    };
  });
  return (
    <>
      <StyledTable
        as={Table}
        style={style}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onDoubleClick: onDoubleClickAction
            ? () =>
                history.push({
                  pathname: `${path || pathname}/show/${key}`
                })
            : () => {}
        })}
        dataSource={resources}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={{ ...pagination, current: currentPage, pageSize }}
        columns={
          customActionColumn
            ? adjustedColumns
            : [...adjustedColumns, ...actionColumn]
        }
        scroll={{ x: 1500 }}
      />
    </>
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filterSearch: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          value: PropTypes.string
        })
      )
    })
  ),
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.bool,
  deleteAction: PropTypes.bool,
  showAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.bool
};

Datatable.defaultProps = {
  path: null,
  columns: [],
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: false,
  deleteAction: false,
  showAction: false,
  onDoubleClickAction: true
};

export default Datatable;
