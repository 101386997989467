import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('stores.form.title'),
      key: 'title',
      dataIndex: 'title',
      render: null,
      sorter: true
    },
    {
      title: t('stores.form.full_name'),
      dataIndex: ['first_name', 'last_name'],
      key: ['first_name', 'last_name'],
      sorter: true,
      render: (_, row) => `${row.first_name} ${row.last_name}`
    },
    {
      title: t('stores.form.mail_address'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('stores.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        `${phone_number?.country_code}${phone_number?.number}`,
      sorter: true
    },
    {
      title: t('stores.form.nbGold'),
      key: 'nbGold',
      dataIndex: 'nbGold',
      render: null,
      sorter: true
    },
    {
      title: t('stores.form.nbPlat'),
      key: 'nbPlat',
      dataIndex: 'nbPlat',
      render: null,
      sorter: true
    },
    {
      title: t('stores.form.nbTotal'),
      key: 'nbTotal',
      dataIndex: 'nbTotal',
      render: null,
      sorter: true
    }
  ];
};

export default useColumns;
