import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import {
  styles,
  AdvantagesContainer,
  ActionsContainer,
  ContainerBody
} from './styles';
import ActionCommentContainer from './ActionCommentContainer';
import './styles.css';
import CreateUpdateRequest from '../../Requests/CreateUpdateRequest';
import Eligiblebenefits from '../../Requests/Eligiblebenefits';
import HistoriqueBenefits from '../../Requests/HistoriqueBenefits';
import ContractDetails from './ContractDetails';

const ShowContractAlt = ({ extra }) => {
  const { dispatchAPI, user, checkShouldDisplayActionItem } = useAuthContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [contract, setContract] = useState({});
  const [partner, setPartner] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const output = [];

  const [name, setName] = useState();
  const [callOptionState, setCallOptionState] = useState();
  const [eligibilityBenefits, setEligibilityBenefits] = useState();
  const [benefitsInprogress, setBenefitsInprogress] = useState();
  const [contractData, setContractData] = useState();
  const fetchData = async () => {
    setIsLoading(true);
    const contractData = await dispatchAPI('GET', {
      url: `/contracts/${id}`
    });
    setContractData(contractData);
    const eligibileBenefits = await dispatchAPI('GET', {
      url: `/requests/getEligibilityBenefitList/${contractData.data.customer}`
    });
    setEligibilityBenefits(eligibileBenefits.data);
    const tmpBenefitsInprogress = await dispatchAPI('GET', {
      url: `/requests/getBenefitsInprogress/${contractData.data.customer}`
    });
    setBenefitsInprogress(tmpBenefitsInprogress.data);
    const currentContract = await dispatchAPI('GET', {
      url: `/contracts/${id}?populate=ref_type_contract, partner, customer, customer.benefits`
    });
    const dataCustomer = await dispatchAPI('GET', {
      url: `/users/${currentContract.data.ref_seller}?fields=email`
    });
    const data_seller = await dispatchAPI('GET', {
      url: `sellers/mail/${dataCustomer.data.email}`
    });
    const availableBenefits = currentContract.data.customer.benefits;
    eligibileBenefits.data.forEach(benefit => {
      const outputStream = {};
      outputStream.title = benefit.title;
      outputStream.eligibility = benefit.eligibility;
      outputStream.begin_date = currentContract.data.begin_date;
      outputStream.description = benefit.description;
      outputStream.attr = currentContract.data.type.toUpperCase();
      outputStream.key = benefit.key;

      let getter = false;
      let date_posted;
      availableBenefits.forEach(grantee => {
        if (
          grantee.title_benefit.toLowerCase() === benefit.title.toLowerCase()
        ) {
          if (grantee.status_activation === 'ACTIVATED') {
            getter = true;
            date_posted = grantee.updated_at;
          }
        }
      });
      if (getter === true) {
        outputStream.validation = 'ACCEPTED';
        outputStream.date_posted = date_posted;
      } else {
        outputStream.validation = 'REFUSED';
      }
      output.push(outputStream);
    });
    output.sort((a, b) => {
      return a.date - b.date;
    });
    setPartner(data_seller?.data?.title || 'La direction');
    setContract(currentContract.data);
    setIsLoading(false);
  };
  const [reload, setReload] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [reload]);

  useEffect(() => {
    setName(
      contract && contract.customer?.gender === 'MISTER'
        ? `Mr ${contract.customer?.first_name} ${contract.customer?.last_name}`
        : `Mme ${contract.customer?.first_name} ${contract.customer?.last_name}`
    );
  }, [contract]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value_radio, setValue_radio] = useState(1);
  const [isSelected, setIsSelected] = useState(false);
  const [callValue, setCallValue] = useState();
  const [loaderElement, setLoaderElement] = useState();

  const handleCancel = () => {
    // Check le isSelected and save the action in the db
    setIsSelected(false);
    // Open the voting modal
    setIsModalVisible(false);
    setLoaderElement();
  };

  useEffect(() => {
    (async () => {
      const params = location.search.split('?')[1];
      const objectParams = new URLSearchParams(params);
      const outputKeys = [...objectParams.keys()];
      const outputValue = [...objectParams.values()];
      if (outputValue.length !== 0) {
        if (
          outputValue[0] === 'allocation_benefit' ||
          outputValue[0] === 'resiliation_completed'
        ) {
          let reasonStory;
          let body;
          if (outputValue[0] === 'allocation_benefit') {
            reasonStory = 'benefit';
          } else if (outputValue[0] === 'resiliation_completed') {
            reasonStory = 'resiliate';
          }
          if (outputValue[1]) {
            body = {
              ref_user: user._id,
              ref_contract: id,
              message: outputValue[1],
              reason: reasonStory
            };
          } else {
            body = {
              ref_user: user._id,
              ref_contract: id,
              reason: reasonStory,
              message: '-'
            };
          }
          await dispatchAPI('POST', { url: `/story/`, body });
          /* setLoaderElement(<RatingForm />); */
          if (callOptionState === 1) {
            message.success(
              "La demande d'avantage a été initialisée avec succès !"
            );
          } else if (callOptionState === 2) {
            message.success(
              'La demande de résiliation a été établie avec succès !'
            );
            await dispatchAPI('POST', {
              url: '/advisors/postCall/',
              body: {
                ref_contract: id,
                ref_advisor: user._id,
                reason: 1,
                type: 'TERMINATE'
              }
            });
          }
          handleCancel();
        }
      }
      if (outputValue[0] === 'resiliation_failed') {
        if (outputValue[1] === '3') {
          // The advisor convinced the user to not resiliate it's contract, rather to allocate one of it's benefits
          setCallValue("Attribution d'avantage");
          setCallOptionState(1);
          setValue_radio(1);
          setLoaderElement(<CreateUpdateRequest purpose="create" />);
        } else if (outputValue[1] === '4') {
          message.success("Le changement d'offre a été établi avec succès !");
          handleCancel();
        }
        await dispatchAPI('POST', {
          url: '/advisors/postCall/',
          body: {
            ref_contract: id,
            ref_advisor: user._id,
            reason: parseInt(outputValue[1], 10),
            type: 'TERMINATE'
          }
        });
      }
      if (outputKeys.length !== 0) {
        if (outputKeys[0] === 'rating_completed') {
          message.success('Le vote a été bien pris en compte !');
        }
        if (
          outputKeys[0] === 'rating_completed' ||
          outputKeys[0] === 'rating_not_completed'
        ) {
          if (callOptionState === 1) {
            message.success(
              "La demande d'avantage a été initialisée avec succès !"
            );
          } else if (callOptionState === 2) {
            message.success(
              'La demande de résiliation a été établie avec succès !'
            );
          }
          handleCancel();
        }
      }
      if (outputValue.length || outputKeys.length) {
        history.replace(`${id}`);
      }
    })();
  }, [location]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Spin tip="Veuillez patienter..." size="large" />
        </div>
      ) : (
        <div style={{ maxWidth: '1600px', margin: 'auto' }}>
          {eligibilityBenefits && (
            <ContractDetails eligibilityBenefits={eligibilityBenefits} />
          )}
          <ContainerBody>
            <AdvantagesContainer>
              {contractData && (
                <>
                  <Eligiblebenefits data={contractData.data.customer} />
                  <HistoriqueBenefits data={contractData.data.customer} />
                </>
              )}
            </AdvantagesContainer>
            <ActionsContainer>
              <div style={styles.graph_header}>
                <p style={styles.graph_header_first}>{t(`stories.title`)}</p>
                <p style={styles.graph_header_second} />
              </div>
              <div style={styles.app_divider_container_comment} />
              <ActionCommentContainer />
            </ActionsContainer>
          </ContainerBody>
        </div>
      )}
    </>
  );
};

ShowContractAlt.propTypes = {
  extra: PropTypes.element
};

ShowContractAlt.defaultProps = {
  datas_benefits: null
};

export default ShowContractAlt;
