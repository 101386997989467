import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Input, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import countryListMap from 'country-flags-dial-code';
import { isValidPhoneNumber } from 'react-phone-number-input';
import IBAN from 'iban';
import useAuthContext from '../../contexts/AuthContext';
import emailField from '../../utils/formFields/emailField';

const { Option } = Select;

const useFields = (purpose) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const { id } = useParams();
  const [newContact, setNewcontact] = useState(false);
  const [options, setOptions] = useState([]);
  const [businessInformations, setBusinessInformations] = useState(null);
  const [partners, setPartners] = useState([]);
  const [mails, setMails] = useState([]);
  const [defaultCode, setDefaultCode] = useState('+33');
  const countries_code = countryListMap.getCountryListMap();
  const [currentPartner, setCurrentPartner] = useState();
  const [defaultIban, setDefaultIban] = useState('');
  const [customers, setCustomers] = useState([]);
  const ibantools = require('ibantools');
  let code_array = [];
  Object.keys(countries_code).forEach((key) => {
    code_array.push(countries_code[key].dialCode);
  });
  const compare_code = (a, b) => {
    const a_num = parseInt(a.substring(1), 10);
    const b_num = parseInt(b.substring(1), 10);
    return a_num - b_num;
  };
  code_array.sort(compare_code);
  code_array = [...new Set(code_array)];

  const validate_phone = (_, value) => {
    if (!isValidPhoneNumber(defaultCode + value) && value.length > 0) {
      return Promise.reject(new Error('Numéro non valide'));
    }
    return Promise.resolve();
  };

  const getPartner = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/partners/${id}`
      });
      setCurrentPartner(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const validate_iban = (_, value) => {
    // Checking if it's a valid iban
    const value_formatted = value.replace(/ /g, '');
    if (!IBAN.isValid(value_formatted) && value_formatted.trim().length > 0) {
      return Promise.reject(new Error('IBAN non valide !!'));
    }
    // Checking if the currently entered iban already exists in the db
    if (!checkIBAN(value_formatted) && value_formatted.trim().length > 0) {
      return Promise.reject(
        new Error("IBAN déja utilisé par quelqu'un d'autre")
      );
    }
    return Promise.resolve();
  };
  /* Part responsible for handling inputs dealing with iban && bic */
  const checkIBAN = (iban) => {
    let exist = false;
    const dataFilter = customers.filter(
      (customer) => customer.rib.IBAN === iban && customer._id !== id
    );
    if (!dataFilter.length) {
      exist = true;
    }
    return exist;
  };
  /* useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getPartner();
      })();
    }
  }, []); */

  const onSelect = async (option, datas) => {
    const { data } = await dispatchAPI('GET', {
      url: `/partnerSearch/getBySiren/${datas.key}`
    });
    data && setBusinessInformations(data);
  };

  const onSearch = async (name) => {
    if (!name) {
      setOptions([]);
    } else {
      // const { data } = await dispatchAPI('GET', {
      //   url: `/partnerSearch/${name}`
      // });
      // data && setOptions(data);
    }
  };

  const getStores = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/partners`
      });
      setPartners(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMails = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users/mails/' });
    setMails(data);
  };

  const setChange_iban = (value) => {
    const value_formatted = value.replace(/ /g, '');
    setDefaultIban(value_formatted);
  };

  const validate_bic = (_, value) => {
    if (!ibantools.isValidBIC(value) && value.length > 0) {
      return Promise.reject(new Error('BIC non valide'));
    }
    return Promise.resolve();
  };

  /*   useEffect(() => {
    (async () => {
      await getStores();
      await getMails();
    })();
  }, []); */

  const fields = [
    {
      label: 'partner.fields.name',
      name: ['name'],
      rules: [{ required: true }],
      input: <Input placeholder="Entrez une entreprise" />
    },
    {
      label: 'partner.fields.siren',
      name: ['siren_number'],
      rules: [{ required: true }]
    },
    {
      name: ['rib', 'IBAN'],
      rules: [{ required: true }, { validator: validate_iban }],
      input: (
        <Input
          value={defaultIban}
          onChange={(e) => setChange_iban(e.target.value)}
        />
      )
    },
    {
      name: ['rib', 'BIC'],
      rules: [{ required: true }, { validator: validate_bic }]
    },
    {
      label: 'partner.fields.tva',
      name: ['tva'],
      rules: [{ required: true }]
    },
    {
      label: 'partner.fields.rcs',
      name: ['rcs'],
      rules: [{ required: true }]
    },
    {
      label: 'partner.fields.ape',
      name: ['ape_number'],
      rules: [{ required: true }]
    },
    {
      label: 'partner.fields.capital',
      name: ['capital'],
      input: <Input type="number" />,
      rules: [{ required: true }]
    },
    {
      label: 'partner.fields.contact',
      name: ['contact'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['contact', 'country_code']}
            initialValue={defaultCode}
          >
            <Select
              style={{ width: '25%' }}
              onChange={(e) => setDefaultCode(e)}
            >
              {code_array.map((code) => (
                <Option key={code} value={code}>
                  {code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['contact', 'number']}
            rules={[{ required: true }, { validator: validate_phone }]}
          >
            <Input type="text" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'partner.fields.street',
      name: ['adress'],
      rules: [{ required: true }]
    },
    {
      label: 'partner.fields.city',
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: 'partner.fields.manager',
      name: ['manager'],
      rules: [{ required: true }]
    },
    emailField([...partners, ...mails], currentPartner?.email, purpose, true),
    {
      label: 'partner.fields.contact',
      name: ['contact'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['contact', 'country_code']}
            initialValue={defaultCode}
          >
            <Select
              style={{ width: '25%' }}
              onChange={(e) => setDefaultCode(e)}
            >
              {code_array.map((code) => (
                <Option key={code} value={code}>
                  {code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['contact', 'number']}
            rules={[{ required: true }, { validator: validate_phone }]}
          >
            <Input type="text" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'partner.fields.slogan',
      name: ['slogan']
    }
  ];

  const steps = [
    {
      title: 'Informations professionnelles',
      prev: 0,
      next: 11,
    },
    {
      title: 'Informations personelles',
      prev: 9,
      next: 12
    },
    {
      title: 'Slogan',
      prev: 12,
      next: 13
    }
  ];
  const getManagers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts' });
      setContacts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getManagers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions, newContact]);

  return {
    fields,
    isFieldsLoading,
    steps,
    businessInformations
  };
};

export default useFields;
