import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Form, Select, DatePicker, Input, Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import AddCustomer from './AddCustomer';
import '../../../assets/styles/SCSS/datepicker.scss';

const { Option } = Select;

const useFields = (
  containsIdContract,
  setType,
  type,
  form,
  is_terminate,
  purpose
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState([]);
  const [enumsType, setEnumsType] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [contractTypesFilter, setContractTypesFilter] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  let id = useParams().id;
  if (!id) id = containsIdContract;

  const handleTeacherChange = (value) => {
    setType(value);
  };

  useEffect(() => {
    setContractTypesFilter(contractTypes.filter((elem) => elem.name === type));
  }, [type, contractTypes]);

  /* Part handling the datepicker UX part (Only select dates after the today's date and always be to the current date on panel opened */
  /*  moment.locale('fr', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: 'seconde',
      ss: '%ss',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'une année',
      yy: '%d ans'
    }
  }); */
  const [value_date, setValue_date] = useState(moment());
  const [select_date, setSelect_date] = useState(moment());
  const [formated_value, setFormated_value] = useState(value_date);
  const [datas_date, setDatas_date] = useState({
    state: false,
    date: value_date
  });

  // Function useful for disabling by default all dates before the actual date
  const [className_datepicker, setClassName_datepicker] = useState([]);

  const disabledDate = (current) => {
    return current < moment().subtract(1, 'day');
  };

  const get_state_opened = (value, data) => {
    if (value === true) {
      // Here we must come back to the default settled value
      setFormated_value(value_date);
      setDatas_date({ state: true, date: value_date });
    } else {
      setDatas_date({ state: false, date: value_date });
    }
  };

  const get_panel_change = (value, mode) => {
    setFormated_value(moment(value._d).format());
  };

  useEffect(() => {
    const diff = moment(formated_value) - moment();
    if (diff <= 110612704) {
      // Freeze the prev button of the datepicker
      setClassName_datepicker(['disable-arrow1', 'disable-arrow2']);
    } else {
      setClassName_datepicker([]);
    }
  }, [formated_value]);

  const [isRequiredCustomer, setIsRequiredCustomer] = useState(true);

  useEffect(() => {
    if (window.location.search) {
      setIsRequiredCustomer(false);
    }
  }, []);

  const formatDate = 'DD/MM/YYYY';

  const getContractDate = async () => {
    try {
      if (id) {
        const { data } = await dispatchAPI('GET', {
          url: `/contracts/${id}?populate=customer`
        });
        const parsed_date = moment().format(data.begin_date, 'YYYY-MM-DD');
        setValue_date(parsed_date);
        setSelectedCustomer(data?.customer);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContractTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contract-types' });
      setContractTypes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomers = async () => {
    try {
      let result = [];
      if (window.location.search) {
        const { data } = await dispatchAPI('GET', {
          url: `/customers/${window.location.search.split('=')[2]}`
        });
        result.push(data);
        setSelectedCustomer(data);
        setCustomers(result);
      } else if (
        user.role === 'admins:DIRECTION' ||
        user.role === 'users:MANAGER-ADVISOR' ||
        user.role === 'users:TELECONSEILLER'
      ) {
        result = await dispatchAPI('GET', {
          url: `/customers`
        });
        setCustomers(result.data);
      } else {
        result = await dispatchAPI('GET', {
          url: `/customers?ref_seller=${user._id}`
        });
        setCustomers(result.data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contracts/enums' });
      setEnums(data.status);
      setEnumsType(data.contract_type);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getContractTypes();
    await getCustomers();
    await getContractDate();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    (async () => {
      await getCustomers();
    })();
  }, [newCustomer]);

  const fields = [
    {
      label: 'type',
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          disabled={is_terminate ? false : purpose !== 'create' && true}
          onChange={handleTeacherChange}
        >
          {enumsType.map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['begin_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={purpose !== 'create' && true}
          style={{ width: '100%' }}
          allowClear={false}
          value={value_date}
          defaultValue={value_date}
          format={formatDate}
          onChange={(date, dateString) => {
            setValue_date(date);
            setSelect_date(date);
          }}
          disabledDate={disabledDate}
          onOpenChange={get_state_opened}
          onPanelChange={get_panel_change}
          showToday={false}
          renderExtraFooter={() =>
            id
              ? `Période d'activité:
              ${
                moment().diff(value_date, 'days') >= 0
                  ? moment(value_date, 'YYYY-MM-DD').fromNow()
                  : 'Pas encore défini'
              }`
              : `Début d'activité:
              ${
                select_date.diff(moment(), 'days') >= 0
                  ? moment(select_date, 'YYYY-MM-DD').fromNow() === 'seconde'
                    ? 'Pas encore défini'
                    : `Dans ${moment(select_date, 'YYYY-MM-DD').fromNow()}`
                  : 0
              }`
          }
          dateRender={(current) => {
            const style = {};
            if (id) {
              if (
                current.diff(value_date, 'day') > 0 &&
                current.diff(moment(), 'day') <= 0
              ) {
                style.background = 'var(--primaryColor)';
                style.borderRadius = '3px';
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }
            return (
              <div className="ant-picker-cell-inner">{current.date()}</div>
            );
          }}
          dropdownClassName={className_datepicker.join(' ')}
        />
      )
    }
  ];

  !is_terminate &&
    fields.push({
      name: ['customer'],
      rules: [{ required: isRequiredCustomer }],
      input:
        window.location.search && selectedCustomer ? (
          <Input
            disabled
            defaultValue={`${selectedCustomer.first_name} ${selectedCustomer.last_name}`}
          />
        ) : (
          <Input.Group style={{ display: 'flex' }}>
            <Drawer
              width={320}
              visible={visible}
              onClose={() => setVisible(false)}
            >
              <AddCustomer
                visible={visible}
                close={() => setVisible(false)}
                setNewCustomer={setNewCustomer}
                newCustomer={newCustomer}
              />
            </Drawer>
            <Form.Item name={['customer']} style={{ width: '100%' }}>
              <Select
                disabled
                style={{ width: '100%' }}
                loading={isFieldsLoading}
                onChange={(values) => {
                  setSelectedCustomer(
                    customers.find((customer) => customer._id === values)
                  );
                }}
              >
                {customers.map((elem) => (
                  <Option key={elem._id} value={elem._id}>
                    {elem.first_name} {elem.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/*  <Form.Item>
              <Button
                type="primary"
                style={{ marginLeft: '10px' }}
                onClick={() => setVisible(true)}
              >
                {`${t('buttons.create')} `}
                <PlusOutlined />
              </Button>
            </Form.Item> */}
          </Input.Group>
        ),
      help: selectedCustomer ? (
        <>
          <p style={{ marginBottom: '0rem', marginTop: '0.5rem' }}>
            Email : {selectedCustomer.email}
          </p>
          <p>Tel : {selectedCustomer.phone_number}</p>
        </>
      ) : null
    });
  return {
    fields,
    isFieldsLoading,
    datas_date
  };
};

export default useFields;
