import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Avatar, Button, Layout, Menu, message, Modal } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  ShopOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import '../../../assets/styles/SCSS/orgChart.scss';
import ThemeContext from '../../../contexts/ThemeContext';
import { SearchCustomerModal } from '../../SearchCustomerModal/SearchCustomerModal';

const { Header } = Layout;

const capitalizeFirstLetter = (string) =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1);

const StyledContainerSub = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledHeader = styled.div`
  background: var(--menuDropdownBackground);
  box-shadow: 0 2px 6px var(--boxShadowColor);
  padding: 0;
  z-index: 11;
  width: 100vw;
  position: fixed;
  height: 70px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const StyledContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
  width: 85%;
  .ant-btn {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: bold;
`;

const StyledRoleP = styled.p`
  font-size: 10px;
  color: var(--textColor);
  margin-bottom: 0;
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  margin-left: unset; //${(props) => props.marginleft}
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

const LogoDiv = styled.div`
  position: relative;
  height: 100%;
  line-height: 64px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  img {
    display: inline-block;
    height: 44px;
    vertical-align: middle;
  }

  @media (min-width: 993px) {
    min-width: 256px;
    max-width: 256px;
  }

  @media (max-width: 992px) {
    margin: 0 24px;
    padding-bottom: 0;
    img {
      height: 40px;
    }
  }
`;

const bounceAnimation = keyframes`
  0% {
    left: 0px;
  }
  50% {
    left: 5px;
  }
  100% {
    left: 0px;
  }
`;

const AnimateArrow = styled.div`
  position: relative;
  left: 0px;
  animation: 1s ${bounceAnimation} linear infinite;
`;

const styles = {
  divider_header: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.65)',
    paddingRight: '20px',
    borderBottomRightRadius: '5px',
    cursor: 'pointer'
  },
  p_header: {
    marginBottom: '0rem',
    marginTop: 5
  },
  icon_header: {
    fontSize: '25px',
    marginRight: '8px'
  },
  primary_button: {
    width: 'fit-content',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      'rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 1px 8px, rgb(0 0 0 / 5%) 0px 4px 70px 1px',
    marginRight: '10px',
    flexFlow: 'row'
  },
  primary_button_alt: {
    width: '190px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      'rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 1px 8px, rgb(0 0 0 / 5%) 0px 4px 70px 1px',
    marginRight: '10px',
    flexFlow: 'row'
  }
};

const HeaderLayout = ({ collapseSider, collapsed, collapseWidth }) => {
  const theme = ThemeContext();
  const history = useHistory();
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [marginTrigger, setMarginTrigger] = useState('0px');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isSearchCustomerModalVisible,
    setIsSearchCustomerModalVisible
  ] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>
              {t(`users.tags.${user.role.split(':')[1]}`)}
            </StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="profile" onClick={() => history.push('/profile')}>
        <UserOutlined
          style={{
            fontSize: 16
          }}
        />
        {` ${t('header.menu.user.profile')}`}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={() => history.push('/')}>
        <LogoutOutlined
          style={{
            fontSize: 16
          }}
        />
        {` ${t('header.menu.user.logout')}`}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger('0px');
      } else {
        setMarginTrigger('80px');
      }
    } else {
      setMarginTrigger('256px');
    }
  }, [collapsed, collapseWidth]);

  const [store, setStore] = useState();
  const [partnerTitle, setPartnerTitle] = useState();

  useEffect(() => {
    const getStore = async () => {
      let data_seller;
      if (user.role === 'users:VENDEUR') {
        const { data } = await dispatchAPI('GET', {
          url: `/users/${user._id}`
        });
        data_seller = await dispatchAPI('GET', {
          url: `sellers/mail/${data.email}`
        });
        setStore(capitalizeFirstLetter(data_seller?.data?.title));
      } else if (user.role === 'users:MANAGER') {
        data_seller = await dispatchAPI('GET', {
          url: `/home_manager/getStoreFromManager/${user._id}`
        });
        setStore(capitalizeFirstLetter(data_seller?.data?.title));
      } else if (user.role === 'users:PARTENAIRE') {
        data_seller = await dispatchAPI('GET', {
          url: `/home_manager/getStoreFromPartner/${user._id}`
        });
        setStore(capitalizeFirstLetter(data_seller?.data?.title));
      }
    };
    const getPartnerTitle = async () => {
      const { data } = await dispatchAPI('GET', {
        url: `/home_partner/getPartnerTitle/${user.email}`
      });
      setPartnerTitle(data.name);
    };
    if (user.role === 'users:VENDEUR' || user.role === 'users:MANAGER') {
      getStore();
    }
    if (user.role === 'users:PARTENAIRE') {
      getPartnerTitle();
    }
  }, []);

  const newContractButton = (buttonStyle = styles.primary_button) => {
    return (
      <Button
        style={buttonStyle}
        type="primary"
        onClick={() => {
          setIsSearchCustomerModalVisible(true);
        }}
      >
        <AnimateArrow>
          <ArrowLeftOutlined />
        </AnimateArrow>
        <span style={{ marginLeft: '10px' }}>{t('contracts.new')}</span>
      </Button>
    );
  };

  const newDashboardButton = (
    link,
    label,
    buttonStyle = styles.primary_button
  ) => {
    return (
      <Link to={link} style={{ marginLeft: 'auto' }} key={link}>
        <Button style={buttonStyle} type="primary">
          <AnimateArrow>
            <ArrowLeftOutlined />
          </AnimateArrow>
          <span style={{ marginLeft: '10px' }}>{label}</span>
        </Button>
      </Link>
    );
  };

  const getDashboardButtons = () => {
    const buttons = [];

    switch (user.role) {
      case 'admins:DIRECTION':
        buttons.push(
          newDashboardButton('/partners/create', 'Nouveau partenaire')
        );
        buttons.push(newDashboardButton('/sellers/create', 'Nouveau vendeur'));
        break;
      case 'users:MANAGER':
        buttons.push(newDashboardButton('/sellers/create', 'Nouveau vendeur'));
        buttons.push(newContractButton());
        break;
      case 'users:MANAGER-ADVISOR':
        buttons.push(
          newDashboardButton(
            '/advisors/create',
            'Nouveau téléconseiller',
            styles.primary_button_alt
          )
        );
        break;
      case 'users:VENDEUR':
        buttons.push(newContractButton());
        break;
      default:
        break;
    }
    return buttons;
  };

  return (
    <StyledHeader as={Header}>
      {window.innerWidth < 992 && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          marginleft={marginTrigger}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      <LogoDiv>
        <Link to="/">
          <img
            alt="Logo"
            src={collapsed ? theme.themedLogoCollapsed : theme.themedLogo}
          />
        </Link>
      </LogoDiv>
      <StyledContainer>
        {store ? (
          <div style={styles.divider_header}>
            <ShopOutlined style={styles.icon_header} />
            <p style={styles.p_header}>{store}</p>
          </div>
        ) : (
          <div />
        )}
        <StyledContainerSub>
          {getDashboardButtons()}
          <HeaderDropdown overlayMenu={profileMenu}>
            {window.innerWidth <= 992 ? (
              <StyledDropdownContainer>
                <UserOutlined />
              </StyledDropdownContainer>
            ) : (
              <StyledDropdownContainer>
                <Avatar
                  size="medium"
                  icon={user && user.photo ? '' : <UserOutlined />}
                  src={user && user.photo ? user.photo : ''}
                >
                  {user && `${user.first_name[0]}${user.last_name[0]}`}
                </Avatar>
                <Button type="link">
                  <StyledFullNameSpan>
                    {partnerTitle
                      ? capitalizeFirstLetter(partnerTitle)
                      : `${capitalizeFirstLetter(
                          user.first_name
                        )} ${capitalizeFirstLetter(user.last_name)}`}
                  </StyledFullNameSpan>
                  <StyledRoleP>
                    {t(`users.tags.${user.role.split(':')[1]}`)}
                  </StyledRoleP>
                </Button>
              </StyledDropdownContainer>
            )}
          </HeaderDropdown>
        </StyledContainerSub>
      </StyledContainer>
      <Modal
        className="modalChart"
        title="Vue d'ensemble"
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        width={1200}
        onCancel={handleCancel}
      />
      <SearchCustomerModal
        visible={isSearchCustomerModalVisible}
        onCancel={() => setIsSearchCustomerModalVisible(false)}
        onOk={() => setIsSearchCustomerModalVisible(false)}
      />
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired
};

export default HeaderLayout;
