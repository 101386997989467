import React from 'react';
import moment from 'moment';

const useManagerAdvisorList = ({
  first_name,
  last_name,
  email,
  phone_number
}) => {
  return [
    {
      label: 'manager-advisors.form.last_name',
      span: 3,
      content: last_name || '-'
    },
    {
      label: 'manager-advisors.form.first_name',
      span: 3,
      content: first_name || '-'
    },
    {
      label: 'manager-advisors.form.email',
      span: 3,
      content: email || '-'
    },
    {
      label: 'manager-advisors.form.phone_number',
      span: 3,
      content: phone_number || '-'
    }
  ];
};

export default useManagerAdvisorList;
