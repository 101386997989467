import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({
  children,
  background = 'var(--componentBackground)'
}) => {
  return (
    <div>
      <div style={{ width: '100%' }}>
        <Content
          style={{
            padding: '10px 24px 20px 24px',
            marginTop: 0,
            maxWidth: '100%',
            height: '100%',
            background
          }}
        >
          {children}
        </Content>
      </div>
    </div>
  );
};

export default ContentCustom;
