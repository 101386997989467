import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { outOfNavRoutes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';

const Profile = () => {
  const {
    dispatchAPI,
    user: { _id }
  } = useAuthContext();
  const { t } = useTranslation();
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  const fetchProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/profiles/${_id}` });
      setProfile(data);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('profile.title')}
        extra={
          <>
            <Link to={`${outOfNavRoutes.PROFILE}/change-pwd`}>
              <Button type="primary">{t('profiles.settings.changePwd')}</Button>
            </Link>

            <Link to={{ pathname: `${outOfNavRoutes.PROFILE}/edit/${_id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          {profile && <DescriptionList data={listContent(profile)} translate />}
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default Profile;
