import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Switch, Tag } from 'antd';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';

const ListSellers = () => {
  const { t } = useTranslation();
  const { checkShouldDisplayActionItem } = useAuthContext();
  const [extraQuery, setExtraQuery] = useState();
  const [all, setAll] = useState(false);

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'isActive',
      key: 'isActive'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: 'Archivé',
      key: 'isActive',
      dataIndex: 'isActive',
      render: (tag) => (
        <span>
          {
            <Tag color={tag === false ? 'volcano' : 'green'} key={tag}>
              {tag === false ? 'Archivé' : 'Non archivé'}
            </Tag>
          }
        </span>
      )
    }
  ];

  useEffect(() => {
    setExtraQuery(`all=${all}`);
  }, [all]);

  return (
    <>
      <SimpleResourceLandingLayout
        extraQuery={extraQuery}
        resourceName="sellers"
        columns={columns}
        headers={headers}
        resourceModelName="SELLERS"
        populate="ref_partner"
        withUploadButton={false}
        withCreateButton={checkShouldDisplayActionItem('sellers', 'create')}
        worth_styling
      >
        <Col span={4} offset={20} style={{ right: 0 }}>
          Afficher les vendeurs archivés:{' '}
          <Switch
            style={{ right: 0 }}
            onChange={(checked) => setAll(checked)}
          />
        </Col>
      </SimpleResourceLandingLayout>
    </>
  );
};

export default ListSellers;
