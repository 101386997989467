import React from 'react';
import HomeSeller from './home_types/HomeSeller';
import HomeAdvisor from './home_types/HomeAdvisor';
import HomeManager from './home_types/HomeManager';
import HomePartner from './home_types/HomePartner';
import HomeCustomerRouter from './home_types/HomeCustomerRouter';
import HomeManagerAdvisor from './home_types/HomeManagerAdvisor';
import HomeDirection from './home_types/HomeDirection';
import useAuthContext from '../../contexts/AuthContext';

const homeGivenRole = {
  'users:USER': <HomeCustomerRouter />,
  'users:VENDEUR': <HomeSeller />,
  'users:TELECONSEILLER': <HomeAdvisor />,
  'users:MANAGER': <HomeManager />,
  'users:MANAGER-ADVISOR': <HomeManagerAdvisor />,
  'users:PARTENAIRE': <HomePartner />,
  'admins:DIRECTION': <HomeDirection />
};

const Home = () => {
  const { user } = useAuthContext();

  return homeGivenRole[user?.role];
};

export default Home;
