import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import FileUploader from './FileUploader';

const CreateTemplate = ({
  purpose,
  id,
  typeSelector,
  forcedType,
  acceptedTypes = 'image/*,.pdf, .docx'
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('files.create.title')} />
      <FileUploader
        acceptedTypes={acceptedTypes}
        purpose={purpose}
        forcedType={forcedType}
        typeSelector={typeSelector}
        id={id}
      />
    </>
  );
};

export default CreateTemplate;
