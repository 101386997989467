import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Rate } from 'antd';
import {
  ArrowRightOutlined,
  FrownOutlined,
  MehOutlined,
  SmileOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import useAuthContext from '../../../contexts/AuthContext';

import './styles.css';

const RatingForm = () => {
  const { dispatchAPI, user } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const [valueRate, setValueRate] = useState(2);
  const handleRate = async rate => {
    setValueRate(rate);
    await dispatchAPI('POST', {
      url: `/rate/`,
      body: { id: user._id, value: rate }
    });
    history.push('?rating_completed=true');
  };
  const RatingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
  `;

  const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />
  };
  return (
    <>
      <RatingContainer>
        <p style={{ fontSize: '1.1rem', marginBottom: '0rem' }}>
          Voter Mr {`${user.first_name} ${user.last_name}`} ?
        </p>
        <Rate
          allowHalf
          value={valueRate}
          onChange={handleRate}
          character={({ index }) => customIcons[index + 1]}
        />
        <Button
          type="primary"
          onClick={() => history.push('?rating_not_completed=true')}
          style={{ marginTop: '1rem' }}
        >
          Passer
          <ArrowRightOutlined />
        </Button>
      </RatingContainer>
    </>
  );
};

export default RatingForm;
