import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row } from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as EmptyIcon } from '../home_icons/EmptyIcon.svg';

const EmptyChart = ({ height }) => {
  const EmptyDiv = styled.div`
    width: 100%;
    height: ${props => props.height}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fb9b25;
  `;
  return (
    <>
      <EmptyDiv height={height}>
        <EmptyIcon />
        Aucune donnée disponible !
      </EmptyDiv>
    </>
  );
};

export default EmptyChart;
