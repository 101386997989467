import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import HomeCustomer from './HomeCustomer';
import ShowRequest from '../../Requests/ShowRequest';
import ListRequests from '../../Requests/ListRequestsCustomer';
// import ListRequests from './ListRequestsInprogress';
// import CreateUpdateRequest from './CreateUpdateRequest';
// import ShowRequest from './ShowRequest';
// import CreateTemplate from '../Documents/Templates/CreateTemplate';

const HomeCustomerRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/*<Route path={`${path}requests/show/:id`} render={() => <ShowRequest />} />*/}
      {/*<Route path={`${path}requests`} render={() => <ListRequests />} />*/}
      <Route path={`${path}`} render={() => <HomeCustomer />} />
    </Switch>
  );
};

export default HomeCustomerRouter;
