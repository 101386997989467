import { isValidPhoneNumber } from 'react-phone-number-input';
import PropTypes from 'prop-types';

const phoneField = (
  isRequired = false,
  countryCode = '+33',
  errorMessage = 'Numéro non valide'
) => {
  const validate_phone = (_, value) => {
    let convertedPhoneNumber = value;

    if (value.length === 1 && value[0] === '+') {
      console.log('coucou');
      Promise.resolve();
    }
    // If the phone number starts with 0 we instead put the countryCode
    if (value.length > 0 && value[0] === '0') {
      convertedPhoneNumber = `${countryCode}${value.slice(1)}`;
    }
    if (
      !isValidPhoneNumber(convertedPhoneNumber) &&
      convertedPhoneNumber.length > 0
    ) {
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.resolve();
  };

  return {
    name: ['phone_number'],
    rules: [{ required: isRequired }, { validator: validate_phone }]
  };
};

phoneField.propTypes = {
  required: PropTypes.bool,
  countryCode: PropTypes.string,
  errorMessage: PropTypes.string
};

phoneField.defaultProps = {
  required: false
  // Defaults to the french country code
};
export default phoneField;
