/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';

import { styles, ActionsContainer } from '../../assets/styles/styles';
import '../../assets/styles/SCSS/comment.scss';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const HistoriqueBenefits = ({ data }) => {
  const { dispatchAPI, user } = useAuthContext();
  const [benefitsInprogress, setBenefitsInprogress] = useState();

  const columnsInprogressBenefits = [
    {
      title: '',
      dataIndex: 'title_benefit',
      key: 'title_benefit'
    },
    {
      title: 'Statut',
      dataIndex: 'status_validation',
      key: 'status_validation'
    }
  ];

  useEffect(() => {
    (async () => {
      try {
        const tmpBenefitsInprogress = await dispatchAPI('GET', {
          url: `/requests/getBenefitsInprogress/${data}`
        });
        setBenefitsInprogress(tmpBenefitsInprogress.data);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    })();
  }, [user]);

  return (
    <>
      <ActionsContainer>
        <div style={styles.graph_header}>
          <p style={styles.graph_header_first}>Historique des avantages</p>
          <p style={styles.graph_header_second} />
        </div>
        <div style={styles.app_divider_container_comment} />
        <Table
          columns={columnsInprogressBenefits}
          dataSource={benefitsInprogress}
        />
      </ActionsContainer>
    </>
  );
};

export default HistoriqueBenefits;
