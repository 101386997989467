import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Button } from 'antd';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';

const SignModal = ({ sigPad, save, signManuel, setSignManuel, disable }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={signManuel}
      onClose={() => {
        !disable && setSignManuel(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Dessiner votre signature</DialogTitle>
      <DialogContent>
        <SignatureCanvas
          canvasProps={{ width: 500, height: 300 }}
          ref={sigPad}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="sendButton"
          disabled={disable}
          onClick={() => {
            setSignManuel(false);
          }}
        >
          {`${t('buttons.cancel')} `}
        </Button>
        <Button
          className="sendButton"
          disabled={disable}
          onClick={() => {
            sigPad.clear();
          }}
        >
          {`${t('buttons.clear')} `}
        </Button>
        <Button type="primary" disabled={disable} onClick={save}>
          {t('buttons.save_sign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignModal;
