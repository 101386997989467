import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';

const ListDirections = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const { user, checkShouldDisplayListItem } = useAuthContext();

  const truncate = (str, n) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  };
  const columns = [
    {
      title: t('directions.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('directions.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('directions.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('directions.form.address_full'),
      key: 'address',
      dataIndex: 'address',
      sorter: true,
      render: text => truncate(text.street, 35)
    },
    {
      title: t('directions.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true
    }
  ];
  return (
    <>
      <SimpleResourceLandingLayout
        resourceName="directions"
        columns={columns.filter(column =>
          checkShouldDisplayListItem('directions', column.key)
        )}
        resourceModelName="Direction"
        withUploadButton={false}
        withCreateButton={authContext.checkShouldDisplayActionItem(
          'directions',
          'create'
        )}
        editAction={authContext.checkShouldDisplayActionItem(
          'directions',
          'edit'
        )}
        deleteAction={authContext.checkShouldDisplayActionItem(
          'directions',
          'delete'
        )}
        worth_styling
      />
    </>
  );
};

export default ListDirections;
