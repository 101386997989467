import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Row, Col, Select, Button } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;
const ModalSendMails = ({ templateId, isModalVisible, setIsModalVisible }) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [selectedId, setSelectedId] = useState([]);
  const [users, setContacts] = useState({});
  const { message } = useErrorMessage();

  const fetchData = useCallback(async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `/contacts`
      });

      setContacts(data.data);
      const contract = await dispatchAPI('GET', {
        url: `/contracts/${id}?populate=customer`
      });
      data.data &&
        data.data.map(contact =>
          contact.emails === contract.data.customer.email
            ? setSelectedId(contact._id)
            : ''
        );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const downloadContract = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/contracts/download/${id}`
      });
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const sendEmail = async () => {
    const params = JSON.stringify(selectedId);
    downloadContract();
    await dispatchAPI('GET', {
      url: `/email_sender/${id}?templateId=${templateId}&contactIds=${selectedId}`
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
  };
  const handleChange = value => {
    setSelectedId(value);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        width={800}
        footer={false}
      >
        <Row>
          <Col span={4}>
            <span>Destinataires</span>
          </Col>
          <Col span={8}>
            <Select
              value={selectedId}
              onChange={handleChange}
              mode="multiple"
              style={{ width: '100%' }}
            >
              {users.length &&
                users.map(user => (
                  <Option value={user._id} key={user._id}>
                    {`${user.first_name} ${user.last_name}`}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col offset={20} span={4}>
            <Button type="add" onClick={sendEmail}>
              Envoyer
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalSendMails;
