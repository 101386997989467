import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Tooltip, Label, Legend } from 'bizcharts';
import DataSet from '@antv/data-set';
import EmptyChart from '../components_home/EmptyChart';

const BarChart = ({
  data,
  xAxis,
  yAxis,
  fields,
  type,
  height,
  from_manager,
  has_special_name,
  developingFields,
  is_simple,
  is_histogram,
  has_plat_contract_type,
  customYLabel,
  has_multi_colors
}) => {
  let ds;
  let dv;
  let color;
  let axis;
  if (!is_simple && !is_histogram) {
    if (has_special_name) {
      if (data[0] !== null) {
        data.map(d => {
          const total = fields.reduce((pre, f) => {
            pre += d[f];
            return pre;
          }, 0);
          d.Total = total;
        });
      }
    }
    ds = new DataSet();
    dv = ds.createView().source(data);
    if (!has_special_name) {
      dv.transform({
        type: 'fold',
        fields,
        key: xAxis,
        value: yAxis
      });
      axis = `${xAxis}*${yAxis}`;
      if (type === 'interval') {
        color = ['#3aa1ff', '#75cd99'];
      } else {
        color = ['#ff8b3d', '#efb055'];
      }
    } else {
      if (data[0] !== null) {
        dv.transform({
          type: 'fold',
          fields,
          key: developingFields,
          value: has_special_name,
          retains: [xAxis, 'Total']
        });
      } else {
        dv = [];
      }
      axis = `${xAxis}*${has_special_name}`;
      color = ['#f1bc6e', '#d8d7d7', '#ff9c5ade', '#ff9d5c'];
    }
  } else if (is_simple && !is_histogram) {
    axis = `${xAxis}*${yAxis}`;
  } else if (is_histogram) {
    axis = `${xAxis}*${yAxis}`;
  }

  if (has_plat_contract_type) {
    color = ['#d8d7d7', '#efb055'];
  }

  if (has_multi_colors) {
    color = ['#ff8b3d', '#ffc069', '#efb055', '#ce6c2b'];
  }
  return (
    <>
      {((!data || data.length < 1) && <EmptyChart />) ||
        (is_histogram && (
          <Chart
            plotBackground={{ fill: '#f9f8f6' }}
            height={300}
            width={200}
            data={data}
            padding={[40, 20, 'auto', 'auto']}
            placeholder
            forceFit
            scale={fields}
          >
            {(is_simple && (
              <>
                <Axis name="Types d'appels" />
                <Axis name="Appels" />
                <Tooltip />
                <Geom
                  type="interval"
                  position={axis}
                  color={[xAxis, ['#ffad43', '#fc8b00', '#fa691d']]}
                >
                  <Label content={yAxis} />
                </Geom>
              </>
            )) || (
              <>
                <Axis name={xAxis} />
                <Axis name={yAxis} />
                <Tooltip inPlot={false} crosshairs={false} position="top" />
                <Geom
                  type="interval"
                  position={axis}
                  color={[xAxis, ['#ffad43', '#fc8b00', '#fa691d', '#ffad43']]}
                />
              </>
            )}
          </Chart>
        )) || (
          <Chart
            plotBackground={{ fill: '#f9f8f6' }}
            height={has_multi_colors ? 300 : height}
            width={from_manager ? 450 : height === 400 ? 300 : 200}
            data={dv}
            padding={[40, 20, 'auto', 'auto']}
            placeholder
            forceFit
          >
            <Legend />
            <Axis
              name={customYLabel ? xAxis : has_special_name ? xAxis : 'Mois'}
            />
            <Axis
              name={
                customYLabel ? yAxis : has_special_name || 'Objectifs mensuels'
              }
              label={customYLabel}
            />
            <Tooltip />
            <Geom
              type={type}
              position={axis}
              color={
                has_special_name ? [developingFields, color] : ['name', color]
              }
              style={{
                stroke: '#fff',
                lineWidth: 1
              }}
              adjust={[
                {
                  type: 'dodge',
                  marginRatio: 1 / 32
                }
              ]}
            />
          </Chart>
        )}
    </>
  );
};

BarChart.propTypes = {
  data: PropTypes.array,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  fields: PropTypes.array,
  type: PropTypes.string,
  height: PropTypes.number,
  from_manager: PropTypes.bool,
  has_special_name: PropTypes.string,
  developingFields: PropTypes.string,
  is_simple: PropTypes.bool,
  is_histogram: PropTypes.bool,
  has_plat_contract_type: PropTypes.bool,
  customYLabel: PropTypes.object,
  has_multi_colors: PropTypes.bool
};

BarChart.defaultProps = {
  data: null,
  xAxis: null,
  yAxis: null,
  cols: null,
  type: 'intervalStack',
  height: 400,
  from_manager: false,
  has_special_name: null,
  developingFields: null,
  is_simple: false,
  is_histogram: false,
  has_plat_contract_type: false,
  customYLabel: null,
  has_multi_colors: false
};

export { BarChart };
