import React, { useCallback, useEffect, useState } from 'react';
import { Input, Switch, Upload, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import BenefitsFormList from './BenefitsFormList';

const { Dragger } = Upload;
const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');
  const [enums, setEnums] = useState([]);

  // This function convert the PDF to base64 format
  const fileToBase64 = async file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'doc' ||
        fileExtension === 'docx' ||
        fileExtension === 'DOC' ||
        fileExtension === 'DOCX'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a DOC or DOCX file.');
      return true;
    },
    fileList
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/contract-types/enums'
      });
      setEnums(data.contract_type);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      label: 'name',
      name: ['name'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {enums.map(contract_type => (
            <Option key={contract_type} value={contract_type}>
              {contract_type}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'duration_engagement',
      name: ['duration_engagement'],
      rules: [{ required: true }]
    },
    {
      name: ['price'],
      rules: [{ required: true }],
      input: <Input type="number" />
    }
    /* {
      name: ['show'],
      rules: [{ required: true }],
      input: <Switch />
    }, */
    /* {
      name: ['benefits'],
      input: <BenefitsFormList />
    }, */
    /* {
      name: ['template_file'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }, */
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
