import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Exception from '../../components/Exceptions/Exception';
import ListInvoices from './ListInvoices';

const InvoicesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ListInvoices} />
      <Route path={path} component={Exception} />
    </Switch>
  );
};

export default InvoicesRouter;
