import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import IBAN from 'iban';
import { DatePicker, Input, Select, Form, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import countryList from 'react-select-country-list';
import { isValidPhoneNumber } from 'react-phone-number-input';
import countryListMap from 'country-flags-dial-code';
import useEmailPattern from '../../utils/emailPattern';
import { genders } from '../../utils/constants/misc';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const ibantools = require('ibantools');

const { Step } = Steps;
const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [customers, setCustomers] = useState([]);
  const [mails, setMails] = useState([]);
  const { id } = useParams();

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current + 1);
  };

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers`
      });
      setCustomers(data);
      data.forEach((json_array, index) => {
        if (json_array._id === id) {
          const parsed_date = moment().format(json_array.dob, 'YYYY-MM-DD');
          setValue_date(parsed_date);
          setDefault_iban(json_array.rib.IBAN);
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomers();
    })();
  }, []);

  const disabledDate = current => moment().diff(current, 'years') >= 18;

  /* Part responsible for handling inputs dealing with iban && bic */
  const checkIBAN = iban => {
    let exist = false;
    const dataFilter = customers.filter(
      customer => customer.rib.IBAN === iban && customer._id !== id
    );
    if (!dataFilter.length) {
      exist = true;
    }
    return exist;
  };

  const validate_iban = (_, value) => {
    // Checking if it's a valid iban
    const value_formatted = value.replace(/ /g, '');
    if (!IBAN.isValid(value_formatted) && value_formatted.trim().length > 0) {
      return Promise.reject(new Error('IBAN non valide !!'));
    }
    // Checking if the currently entered iban already exists in the db
    if (!checkIBAN(value_formatted) && value_formatted.trim().length > 0) {
      return Promise.reject(
        new Error("IBAN déja utilisé par quelqu'un d'autre")
      );
    }
    return Promise.resolve();
  };

  const validate_bic = (_, value) => {
    if (!ibantools.isValidBIC(value) && value.length > 0) {
      return Promise.reject(new Error('BIC non valide'));
    }
    return Promise.resolve();
  };

  const getMails = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users/mails/' });
    setMails(data);
  };

  const checkEmail = mail => {
    let exist = false;
    const merged_mails = [...customers, ...mails];
    const dataFilter = merged_mails.filter(user => user.email === mail);
    if (!dataFilter.length) {
      exist = true;
    }
    return exist;
  };

  useEffect(() => {
    (async () => {
      await getCustomers();
      await getMails();
    })();
  }, []);

  const countries_code = countryListMap.getCountryListMap();
  let code_array = [];
  Object.keys(countries_code).forEach(key => {
    code_array.push(countries_code[key].dialCode);
  });
  const compare_code = (a, b) => {
    const a_num = parseInt(a.substring(1), 10);
    const b_num = parseInt(b.substring(1), 10);
    return a_num - b_num;
  };
  code_array.sort(compare_code);
  code_array = [...new Set(code_array)];

  const validate_phone = (_, value) => {
    if (!isValidPhoneNumber(value) && value.length > 0) {
      return Promise.reject(new Error('Numéro non valide'));
    }
    return Promise.resolve();
  };

  const [default_iban, setDefault_iban] = useState('');
  const setChange_iban = value => {
    // Remove all spaces between characters before setting again that one with the formatted value
    const value_formatted = value.replace(/ /g, '');
    setDefault_iban(value_formatted);
  };

  // Part responsible for handling autocomplete address
  const [suggestion, setSuggestion] = useState(
    []
  ); /* For autocomplete purpose */
  const [address, setAddress] = useState('');

  // Getting the postal code and at the same time loading it to the next fields down below itself.
  const load_datas = async value => {
    setSuggestion(value); /* For autocomplete purpose */
  };

  // Handling the select countries's functionnality
  const [value_country, setValue_country] = useState('');
  const options_country = useMemo(() => countryList().getData(), []);

  /* Part handling the datepicker UX part (Only select dates after the today's date and always be to the current date on panel opened */
  moment.locale('fr', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: 'seconde',
      ss: '%ss',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'une année',
      yy: '%d ans'
    }
  });
  const [panel_date, setPanel_date] = useState(moment());
  const [value_date, setValue_date] = useState(
    moment().subtract('18', 'years')
  );
  const [select_date, setSelect_date] = useState(moment());
  const [formated_value, setFormated_value] = useState(value_date);
  const [datas_date, setDatas_date] = useState({
    state: false,
    date: value_date
  });

  const default_birth = moment().subtract('18', 'years');

  // Function useful for disabling by default all dates before the actual date
  const [className_datepicker, setClassName_datepicker] = useState([]);

  const disabledDate_birth = current => {
    // Can not select days before today
    return current > moment().subtract(1, 'day');
  };

  const get_state_opened = (value, data) => {
    if (value == true) {
      // Here we must come back to the default settled value
      setFormated_value(value_date);
      setDatas_date({ state: true, date: value_date });
    } else {
      setDatas_date({ state: false, date: value_date });
    }
  };

  const get_panel_change = (value, mode) => {
    setFormated_value(moment(value._d).format());
  };

  useEffect(() => {
    const diff = moment() - moment(formated_value);
    if (diff <= 110612704) {
      // Freeze the prev button of the datepicker
      setClassName_datepicker(['disable-arrow3', 'disable-arrow4']);
    } else {
      setClassName_datepicker([]);
    }
  }, [formated_value]);

  const formatDate = 'DD/MM/YYYY';

  const fields = [
    // personal informations
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    { name: ['company_name'], rules: [{ required: true }] },
    {
      name: ['email'],
      rules: [
        { required: true },
        ...useEmailPattern(),
        {
          validator: (_, value) =>
            checkEmail(value)
              ? Promise.resolve()
              : Promise.reject(
                  new Error("L'email est déja utilisé ou est invalide")
                )
        }
      ]
    },
    {
      name: ['phone_number'],
      rules: [{ required: true }, { validator: validate_phone }]
    },

    // address
    {
      name: ['address', 'number'],
      input: <Input type="number" />,
      rules: [{ required: true }]
    },
    {
      name: ['address', 'street'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Renseignez une adresse ...',
                  className: 'location-search-input'
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={{
                  boxShadow:
                    '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
                  position: 'absolute',
                  top: '100%',
                  zIndex: 10
                }}
              >
                {loading && <div>Veuillez patienter...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span onClick={value => load_datas(suggestion)}>
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      name: ['address', 'additional']
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    // Bank stuff
    {
      name: ['rib', 'IBAN'],
      rules: [{ required: true }, { validator: validate_iban }],
      input: (
        <Input
          value={default_iban}
          onChange={e => setChange_iban(e.target.value)}
        />
      )
    },
    {
      name: ['rib', 'BIC'],
      rules: [{ required: true }, { validator: validate_bic }]
    }
  ];

  // Configure the differents steps and associated fields indexes
  const steps = [
    {
      title: 'Informations personnelles',
      prev: 0,
      next: 5
    },
    {
      title: 'Coordonnées',
      prev: 5,
      next: 10
    },
    {
      title: 'Informations bancaires',
      prev: 10,
      next: 12
    }
  ];

  return {
    fields,
    suggestion,
    datas_date,
    default_iban,
    steps
  };
};

export default useFields;
