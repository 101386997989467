import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Tooltip, Coord, Label } from 'bizcharts';
import { message, Spin, Typography } from 'antd';
import EmptyChart from '../components_home/EmptyChart';
import useAuthContext from '../../../contexts/AuthContext';
import { roundUp } from '../../../utils';

export const RetentionPieChart = ({ range }) => {
  const { dispatchAPI } = useAuthContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: d } = await dispatchAPI('GET', {
        url: `/advisors/retention-stats?start=${range[0]}&end=${range[1]}`
      });

      const retentionRate = d?.[0]?.retention_rate || 0;
      setData([
        { value: roundUp(retentionRate), label: 'Taux de rétention' },
        { value: roundUp(100 - retentionRate), label: 'Taux de résiliation' }
      ]);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
    setIsLoading(false);
  }, [range]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <>
      <Typography.Title level={5}>Rétention vs Résiliation</Typography.Title>
      <Spin spinning={isLoading}>
        {!data.length ? (
          <EmptyChart />
        ) : (
          <Chart data={data} forceFit>
            <Coord type="theta" />
            <Tooltip
              showTitle={false}
              itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}%</li>'
            />
            <Geom type="intervalStack" position="value" color="label">
              <Label
                content="label"
                formatter={(label, item) => `${label} ${item.point.value}%`}
              />
            </Geom>
          </Chart>
        )}
      </Spin>
    </>
  );
};

RetentionPieChart.propTypes = {
  range: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
