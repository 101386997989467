import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/plots';

const TerminatedChart = ({ terminated }) => {
  const config = {
    data: terminated,
    xField: 'name',
    yField: 'value',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6
      },
      meta: {
        value: {
          alias: 'Taux'
        }
      }
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        style: {
          fontSize: 10
        }
      }
    },
    scrollbar: {
      type: 'horizontal'
    },
    meta: {
      value: {
        formatter: (value) => `${value} %`,
        alias: 'Taux'
      }
    },
    minColumnWidth: 50,
    maxColumnWidth: 50
  };
  return <Column {...config} />;
};

TerminatedChart.propTypes = {
  terminated: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default TerminatedChart;
