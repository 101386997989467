import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import moment from 'moment';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const iconSize = 18;

const DebitsContracts = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [debitsFiles, setDebitsFiles] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});

  const generateXMLFile = async (file) => {
    setIsDownloading({ ...isDownloading, [file._id]: true });
    try {
      const response = await dispatchAPI('GET', {
        url: `/debit-contracts/generate-global-xml/${file._id}`
      });
      if (response.data) {
        const blob = new Blob([response.data], {
          type: 'text/xml;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
      }
    } catch (error) {
      message(`Aucune facture à prelevé`);
    }
    setIsDownloading({ ...isDownloading, [file._id]: false });
  };

  const getDebitsFiles = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/debit-contracts?sort=-date'
      });
      setDebitsFiles(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getDebitsFiles();
    })();
  }, []);

  const updateSentStatus = async (updatedStatus, id) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/debit-contracts/${id}`,
        body: { sent: updatedStatus }
      });
      await getDebitsFiles();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      dataIndex: 'sent',
      width: 50,
      align: 'center',
      render: (sent, { _id, recurRib, firstRib }) =>
        recurRib?.length || firstRib?.length ? (
          <Button
            type={sent ? 'add' : 'primary'}
            danger={!sent}
            onClick={() => updateSentStatus(!sent, _id)}
          >
            {sent ? 'Envoyé' : 'Non envoyé'}
          </Button>
        ) : (
          'N/A'
        )
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Période concernée',
      dataIndex: 'range_concerned',
      render: (range) =>
        range?.length
          ? `${moment(range[0])
              .utc()
              .format('DD/MM/YYYY')}-${moment(range[1])
              .utc()
              .format('DD/MM/YYYY')}`
          : 'N/R'
    },
    {
      key: 'action',
      align: 'center',
      render: (file) =>
        file?.recurRib?.length || file?.firstRib?.length ? (
          <Button
            loading={isDownloading[file._id]}
            type="link"
            onClick={async () => {
              await generateXMLFile(file);
            }}
            icon={<DownloadOutlined style={{ fontSize: iconSize }} />}
          />
        ) : (
          'Pas de prélèvements'
        )
    }
  ];

  return (
    <Table loading={isLoading} dataSource={debitsFiles} columns={columns} />
  );
};

export default DebitsContracts;
