export const routes = {
  HOME: '/',
  USERS: '/users',
  PARTNER: '/partners',
  STORES: '/stores',
  SELLERS: '/sellers',
  CUSTOMERS: '/customers',
  CONTRACTS: '/contracts',
  INVOICES: '/invoices',
  ADVISORS: '/advisors',
  'CONTRACTS-SETTINGS': '/contracts-settings',
  BENEFITS: '/benefits',
  REQUESTS: '/requests',
  'MANAGER-ADVISORS': '/manager-advisors',
  DIRECTIONS: '/directions',
  DOCUMENTS: '/documents'
};

export const subRoutes = {
  'CONTRACTS-SETTINGS': {
    CONTRACT_TYPES: '/contract-types',
    COMMISSIONS: '/commissions'
  },
  COMMERCIAL: {
    DEALS: '/deals',
    CONTACTS: '/contacts',
    CUSTOMERS: '/customers'
  },
  ACCOUNTING: {
    INCOMES: '/incomes',
    EXPENSES: '/expenses',
    ACCOUNTS: '/accounts',
    SUPPLIERS: '/suppliers',
    QUOTATIONS: '/quotations',
    INVOICES: '/invoices',
    ORDERS: '/orders',
    BANK_RECONCILIATION: '/reconciliations',
    COMMISSIONS: '/commissions'
  },
  HUMANRESOURCE: {
    ABSENCES: '/absences',
    EMPLOYEES: '/employees'
  },
  PRODUCTION: {
    ARTICLES: '/articles',
    STOCKS: '/stocks'
  },
  DOCUMENTS: {
    TEMPLATES: '/templates'
  }
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  CONTACTS: '/contacts'
};

export const pathSearches = {
  CONTRACTS: '?s=-begin_date',
  INVOICES: '?s=-created_at'
};
