import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import {
  ComparisonTable,
  ThTable,
  TdTable,
  ButtonComparisonGold,
  ButtonComparisonPlat
} from './ExtraComponents';
import { styles } from '../styles_home/home_seller';
import useAuthContext from '../../../contexts/AuthContext';

const ComparisonTableModal = ({
  datas_benefits,
  startingBenefit,
  isModalVisibleContract,
  handleOkContract,
  handleCancelContract
}) => {
  const history = useHistory();
  const { dispatchAPI, user } = useAuthContext();
  const [offersGot, setOffersGot] = useState([]);
  const getDatas = useCallback(async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/home_advisor/getBenefitList/`
    });
    setOffersGot(data);
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  return (
    <Modal
      title="Description des types de contrats"
      visible={isModalVisibleContract}
      onOk={handleOkContract}
      onCancel={handleCancelContract}
      footer={null}
      width={1000}
    >
      <ComparisonTable className="comparisonTable">
        <thead>
          <tr>
            <ThTable />
            <ThTable style={{ padding: '1rem' }} className="platinium">
              <p style={{ marginBottom: '0rem', fontWeight: 300 }}>Platinium</p>
              <p>
                <span style={{ fontWeight: 300 }}>Dès </span>
                <span style={{ fontSize: '1.2rem' }}>
                  {`${startingBenefit[1]}€`}
                </span>
                <span style={{ fontWeight: 300 }}>/mois</span>
              </p>
              <ButtonComparisonPlat
                onClick={() => history.push('/customers/create?from=dashboard')}
              >
                Nouveau contrat
              </ButtonComparisonPlat>
            </ThTable>
            <ThTable className="choosed" style={{ padding: '1rem' }}>
              <p style={{ marginBottom: '0rem', fontWeight: 300 }}>Gold</p>
              <p>
                <span style={{ fontWeight: 300 }}>Dès </span>
                <span style={{ fontSize: '1.2rem' }}>
                  {`${startingBenefit[0]}€`}
                </span>
                <span style={{ fontWeight: 300 }}>/mois</span>
              </p>
              <ButtonComparisonGold
                onClick={() => history.push('/customers/create?from=dashboard')}
              >
                Nouveau contrat
              </ButtonComparisonGold>
            </ThTable>
          </tr>
        </thead>
        <tbody>
          {offersGot.map(offer => (
            <tr key={offer.key}>
              <TdTable>
                <div>
                  <p style={{ marginBottom: '0rem' }}>{offer.title}</p>
                  <p>
                    {offer.eligibility !== '-' &&
                      `Dès ${offer.eligibility.split('///')[0]} jours`}
                    {offer.eligibility !== '-' &&
                      parseInt(offer.eligibility.split('///')[0], 10) !== 12 &&
                      " d'adhésion"}
                  </p>
                </div>
              </TdTable>
              <TdTable className="platinium">
                {offer.descriptionPlat.split('///')[0]}
              </TdTable>
              <TdTable className="choosed">
                {offer.descriptionGold.split('///')[0]}
              </TdTable>
            </tr>
          ))}
          <tr>
            <TdTable>Types de contrats</TdTable>
            <TdTable className="platinium">
              <ul>
                {datas_benefits
                  .filter(contract => {
                    return contract.name === 'Platinium';
                  })
                  .map(benefit => (
                    <div key={benefit.ref} style={styles.outer_benefit_table}>
                      <ArrowRightOutlined />
                      <p style={styles.value_benefit}>
                        <span>{`${benefit.ref}: ${benefit.price}€`}</span>
                      </p>
                    </div>
                  ))}
              </ul>
            </TdTable>
            <TdTable className="choosed">
              <ul>
                {datas_benefits
                  .filter(contract => {
                    return contract.name === 'Gold';
                  })
                  .map(benefit => (
                    <div key={benefit.ref} style={styles.outer_benefit_table}>
                      <ArrowRightOutlined />
                      <p style={styles.value_benefit}>
                        {`${benefit.ref}: ${benefit.price}€`}
                      </p>
                    </div>
                  ))}
              </ul>
            </TdTable>
          </tr>
        </tbody>
      </ComparisonTable>
    </Modal>
  );
};

ComparisonTableModal.propTypes = {
  datas_benefits: PropTypes.array,
  startingBenefit: PropTypes.array,
  isModalVisibleContract: PropTypes.bool,
  handleOkContract: PropTypes.func,
  handleCancelContract: PropTypes.func
};

ComparisonTableModal.defaultProps = {
  datas_benefits: null,
  startingBenefit: null,
  isModalVisibleContract: false,
  handleOkContract: null,
  handleCancelContract: null
};

export default ComparisonTableModal;
