import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateRequest = ({ purpose, id_benefit }) => {
  const { fields, steps } = useFields();
  let id;
  id = useParams().id;
  if (!id) {
    id = id_benefit;
  }
  const { user } = useAuthContext();
  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        dob: data.dob && moment(data.dob)
      })
    },
    onCreateResource: {
      setBody: data => ({
        ...data,
        advisorName: `${user.first_name} ${user.last_name}`
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="benefits"
      resource="benefits"
      config={config}
      steps={steps}
      extraValues={{ customer: id }}
      isTrackable={false}
      isVotable={true}
    />
  );
};

CreateUpdateRequest.propTypes = {
  purpose: PropTypes.string.isRequired,
  id_benefit: PropTypes.string
};

CreateUpdateRequest.defaultProps = {
  id_benefit: null
};

export default CreateUpdateRequest;
