import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, message, Modal, Spin, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import convert from 'xml-js';
import useAuthContext from '../../contexts/AuthContext';

const ImportButton = ({ resourceName, customImportAction }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const checkLineSize = data => {
    const entriesNumber = data[1].length;

    for (let i = 2; i < data.length; i += 1) {
      if (data[i].length !== entriesNumber && i !== data.length - 1) {
        message.error(`L${i}: Le nombre de valeurs est invalide.`);
        return false;
      }
    }
    return true;
  };

  const handleOnFileLoad = async data => {
    data.splice(0, 0, resourceName);

    if (checkLineSize(data)) {
      setVisible(true);

      try {
        await dispatchAPI('POST', {
          url: `/import-template`,
          body: data
        });
        setVisible(false);
        message.success(t(`import.messages.success`));
      } catch (e) {
        setVisible(false);
        if (e.response) message.error(e.response.data.message);
      }
    }
  };

  const props = {
    customRequest: () => {},
    showUploadList: false,
    onChange: info => {
      const { file } = info;

      // if (file.name.endsWith('.csv')) {
      //   return Papa.parse(file.originFileObj, {
      //     complete: results => {
      //       if (results.errors.length === 0) {
      //         if (customImportAction) {
      //           return customImportAction(results.data);
      //         }
      //         return handleOnFileLoad(results.data);
      //       }
      //       message.error(t(`import.messages.has-errors`));
      //     }
      //   });
      // }

      // if (file.name.endsWith('.xml')) {
      //   const read = new FileReader();
      //   read.readAsBinaryString(file.originFileObj);

      //   return (read.onloadend = () => {
      //     const data = convert.xml2json(read.result.substring(3));
      //     console.log(JSON.parse(data));
      //     // TODO: data handling
      //   });
      // }

      if (file.name.toLowerCase().endsWith('.txt')) {
        const read = new FileReader();
        read.readAsBinaryString(file.originFileObj);

        return (read.onloadend = () => {
          const data = read.result
            .substring(3)
            .split('\n')
            .map(line => line.split('|'));
          // TODO: default data handling
          if (customImportAction) {
            return customImportAction(data);
          }
        });
      }

      message.error(t(`import.messages.invalid-format`));
    }
  };

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        visible={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          {`${t(`import.messages.process-request`)} `}
        </Typography.Text>
      </Modal>
      <Upload {...props}>
        <Button type="link" icon={<UploadOutlined />}>
          &nbsp;&nbsp;
          {t('buttons.upload')}
        </Button>
      </Upload>
    </>
  );
};

ImportButton.propTypes = {
  resourceName: PropTypes.string.isRequired,
  customImportAction: PropTypes.func
};

ImportButton.defaultProps = {
  customImportAction: null
};

export default ImportButton;
