import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdatePartner = ({ purpose }) => {
  const { fields, isFieldsLoading, steps, businessInformations } = useFields(
    purpose
  );

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="partners"
      resource="partners"
      withSubRoutes
      steps={steps}
      legalInfos={businessInformations}
    />
  );
};

CreateUpdatePartner.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePartner;
