import PropTypes from 'prop-types';
import getEmailPattern from '../emailPattern';

const emailField = (
  existingEmails,
  previousEmail,
  formPurpose,
  isRequired = false,
  fieldName = 'email',
  labelName = 'email',
  errorMessage = "L'email est déja utilisé ou est invalide"
) => {
  const checkEmailIsTaken = (_, mail) => {
    const dataFilter = existingEmails.filter((user) => user.email === mail);

    let exists = false;
    if (formPurpose !== 'edit') {
      if (dataFilter && dataFilter.length > 0) {
        exists = true;
      }
    } else if (previousEmail !== mail) {
      if (dataFilter && dataFilter.length > 0) {
        exists = true;
      }
    }
    return exists ? Promise.reject(new Error(errorMessage)) : Promise.resolve();
  };

  return {
    name: [fieldName],
    labelName: [labelName],
    rules: [
      { required: isRequired },
      ...getEmailPattern(),
      {
        validator: checkEmailIsTaken
      }
    ]
  };
};

emailField.propTypes = {
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string,
  labelName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  existingEmails: PropTypes.array.isRequired,
  previousEmail: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  formPurpose: PropTypes.string.isRequired
};

emailField.defaultProps = {
  fieldName: 'email',
  labelName: 'email',
  isRequired: false,
  errorMessage: "L'email est déja utilisé ou est invalide"
};

export default emailField;
