import React, { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import countryListMap from 'country-flags-dial-code';
import { Select, Input, Form, message } from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useParams } from 'react-router-dom';
import useAuthContext from '../../contexts/AuthContext';
import emailField from '../../utils/formFields/emailField';

const { Option } = Select;

const useFields = (purpose) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [defaultCode, setDefaultCode] = useState('+33');
  const [partner_name, setPartner_name] = useState();
  const countries_code = countryListMap.getCountryListMap();
  let code_array = [];
  Object.keys(countries_code).forEach((key) => {
    code_array.push(countries_code[key].dialCode);
  });

  const getPartnerDatas = async (currentId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/stores/getPartnerDatas/${currentId}`
      });
      setPartner_name(data.name);
      setSelectedPartner(data);
    } catch (e) {
      message.error(
        "Les informations du partenaire n'ont pas été correctement chargées"
      );
    }
  };

  useEffect(() => {
    if (id) {
      getPartnerDatas(id);
    }
  }, []);

  const validate_phone = (_, value) => {
    const defaultCountryCode = '+33';
    const originalPhoneNumber = value;
    let convertedPhoneNumber = value;

    if (value.length > 0 && value[0] === '0') {
      convertedPhoneNumber = `${defaultCountryCode}${originalPhoneNumber.slice(
        1
      )}`;
    }
    if (
      !isValidPhoneNumber(convertedPhoneNumber) &&
      convertedPhoneNumber.length > 0
    ) {
      return Promise.reject(new Error('Numéro non valide'));
    }
    return Promise.resolve();
  };
  code_array = [...new Set(code_array)];
  const compare_code = (a, b) => {
    const a_num = parseInt(a.substring(1), 10);
    const b_num = parseInt(b.substring(1), 10);
    return a_num - b_num;
  };
  code_array.sort(compare_code);

  // Part responsible for handling autocomplete address
  const [suggestion, setSuggestion] = useState([]);
  const [address, setAddress] = useState('');

  const load_datas = (value) => {
    setSuggestion(value); /* For autocomplete purpose */
  };

  let fields = [
    {
      name: ['title'],
      rules: [{ required: true }]
    },
    {
      label: 'address.street',
      name: ['address', 'street'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Renseignez une adresse ...',
                  className: 'location-search-input'
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={{
                  boxShadow:
                    '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
                  position: 'absolute',
                  top: '100%',
                  zIndex: 10
                }}
              >
                {loading && <div>Veuillez patienter...</div>}
                {suggestions.map((sugg) => {
                  const className = sugg.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = sugg.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      };

                  return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                      {...getSuggestionItemProps(sugg, {
                        className,
                        style
                      })}
                      onClick={() => load_datas(sugg)}
                      key={sugg.description}
                    >
                      <span>{sugg.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      label: 'address.additional',
      rules: [{ required: false }],
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'address.city',
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'last_name',
      name: ['last_name'],
      rules: [{ required: true }]
    },
    emailField([], purpose, true, true),
    {
      label: '',
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue={defaultCode}
          >
            <Select
              style={{ width: '25%' }}
              onChange={(e) => setDefaultCode(e)}
            >
              {code_array.map((code) => (
                <Option key={code} value={code}>
                  {code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            rules={[{ required: true }, { validator: validate_phone }]}
          >
            <Input type="text" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  if (id && partner_name && selectedPartner) {
    fields = [
      ...fields,
      {
        name: ['partner_infos'],
        input: <Input defaultValue={`${partner_name}`} disabled />,
        help: (
          <>
            <p style={{ marginBottom: '0rem', marginTop: '0.5rem' }}>
              {`Email: ${selectedPartner.email}`}
            </p>
            <p>
              {`Tel: ${
                selectedPartner?.contact?.number
                  ? `${selectedPartner.contact.country_code}${selectedPartner.contact.number}`
                  : 'N/R'
              }`}
            </p>
          </>
        )
      }
    ];
  }

  return {
    fields,
    suggestion,
    partner_name
  };
};

export default useFields;
