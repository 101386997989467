import React, { createContext, useContext, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';

const items = {
  directions: ['admins:SUPER-ADMIN'],
  users: ['admins:SUPER-ADMIN'],
  'contracts-settings': ['admins:DIRECTION'],
  partner: ['admins:DIRECTION', 'admins:SUPER-ADMIN'],
  commissions: [
    'admins:DIRECTION',
    'users:PARTENAIRE',
    'users:MANAGER',
    'users:VENDEUR'
  ],
  customers: [
    'admins:DIRECTION',
    'users:TELECONSEILLER',
    'users:VENDEUR',
    'users:PARTENAIRE',
    'users:MANAGER',
    'users:MANAGER-ADVISOR'
  ],
  contracts: [
    'admins:DIRECTION',
    'users:TELECONSEILLER',
    'users:VENDEUR',
    'users:PARTENAIRE',
    'users:MANAGER',
    'users:MANAGER-ADVISOR'
  ],
  documents: ['admins:SUPER-ADMIN', 'admins:DIRECTION'],
  templates: ['admins:SUPER-ADMIN', 'admins:DIRECTION'],
  sellers: ['admins:DIRECTION', 'users:PARTENAIRE', 'users:MANAGER'],
  requests: [
    'admins:DIRECTION',
    'users:TELECONSEILLER',
    'users:MANAGER-ADVISOR',
    'users:USER'
  ],
  advisors: ['admins:DIRECTION', 'users:MANAGER-ADVISOR'],
  stores: ['admins:DIRECTION', 'users:PARTENAIRE'],
  home: [
    'admins:DIRECTION',
    'admins:SUPER-ADMIN',
    'users:TELECONSEILLER',
    'users:COMMERCIAL',
    'users:PARTENAIRE',
    'users:VENDEUR',
    'users:MANAGER',
    'users:MANAGER-ADVISOR',
    'users:USER'
  ],
  'manager-advisors': ['admins:DIRECTION'],
  invoices: ['admins:DIRECTION']
};

const actions_items = {
  directions: [{ 'admins:SUPER-ADMIN': ['edit', 'create', 'delete'] }],
  advisors: [
    { 'admins:DIRECTION': ['create'] },
    { 'users:MANAGER-ADVISOR': ['edit', 'create', 'delete'] }
  ],
  contracts: [
    { 'admins:DIRECTION': ['upload', 'download', 'create', 'delete'] },
    { 'users:TELECONSEILLER': ['edit'] },
    { 'users:MANAGER-ADVISOR': ['edit'] },
    { 'users:PARTENAIRE': [] },
    { 'users:MANAGER': [] },
    { 'users:VENDEUR': ['create', 'delete'] }
  ],
  customers: [
    { 'admins:DIRECTION': ['edit'] },
    { 'users:TELECONSEILLER': ['edit'] },
    { 'users:MANAGER-ADVISOR': ['edit'] },
    { 'users:VENDEUR': ['edit', 'create', 'delete'] },
    { 'users:PARTENAIRE': [] },
    { 'users:MANAGER': [] }
  ],
  sellers: [
    { 'admins:DIRECTION': [] },
    { 'users:PARTENAIRE': [] },
    { 'admins:MANAGER': ['edit', 'create', 'delete'] }
  ],
  stores: [
    { 'admins:DIRECTION': [] },
    { 'users:PARTENAIRE': ['edit', 'create', 'delete'] }
  ],
  benefits: [
    { 'users:TELECONSEILLER': ['edit', 'create'] },
    { 'admins:DIRECTION': ['edit', 'create', 'delete'] },
    { 'users:MANAGER-ADVISOR': ['edit', 'create'] }
  ],
  requests: [{ 'users:MANAGER-ADVISOR': ['validate'] }]
};

const listItems = {
  directions: [
    {
      'admins:SUPER-ADMIN': [
        'first_name',
        'last_name',
        'company_name',
        'email',
        'phone_number',
        'address',
        'rib'
      ]
    }
  ],
  partners: [
    {
      'admins:SUPER-ADMIN': [
        'name',
        'adress',
        'capital',
        'siren_number',
        'manager',
        'email',
        'totalNumber'
      ]
    },
    {
      'admins:DIRECTION': [
        'name',
        'adress',
        'capital',
        'siren_number',
        'manager',
        'email',
        'totalNumber'
      ]
    },
    {
      'users:MANAGER-ADVISOR': []
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:VENDEUR': []
    },
    {
      'users:PARTENAIRE': []
    },
    {
      'users:MANAGER': []
    }
  ],
  stores: [
    {
      'admins:SUPER-ADMIN': [
        'title',
        'full_address',
        'created_at',
        'full_name',
        'email',
        'phone_number'
      ]
    },
    {
      'admins:DIRECTION': [
        'title',
        'full_address',
        'created_at',
        'full_name',
        'email',
        'phone_number'
      ]
    },
    {
      'users:MANAGER-ADVISOR': []
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:PARTENAIRE': [
        'title',
        'full_address',
        'created_at',
        'full_name',
        'email',
        'phone_number'
      ]
    },
    {
      'users:MANAGER': []
    },
    {
      'users:VENDEUR': []
    }
  ],
  sellers: [
    {
      'admins:SUPER-ADMIN': [
        'title',
        'full_address',
        'created_at',
        'full_name',
        'email',
        'phone_number'
      ]
    },
    {
      'admins:DIRECTION': []
    },
    {
      'users:MANAGER-ADVISOR': []
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:PARTENAIRE': []
    },
    {
      'users:MANAGER': []
    },
    {
      'users:VENDEUR': []
    }
  ],
  customers: [
    {
      'admins:SUPER-ADMIN': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    },
    {
      'admins:DIRECTION': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    },
    {
      'users:MANAGER-ADVISOR': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    },
    {
      'users:TELECONSEILLER': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    },
    {
      'users:PARTENAIRE': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    },
    {
      'users:MANAGER': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    },
    {
      'users:VENDEUR': [
        'last_name',
        'first_name',
        'email',
        'address',
        'phone_number'
      ]
    }
  ],
  'manager-advisors': [
    {
      'admins:SUPER-ADMIN': ['last_name', 'first_name', 'email', 'phone_number']
    },
    {
      'admins:DIRECTION': ['last_name', 'first_name', 'email', 'phone_number']
    },
    {
      'users:MANAGER-ADVISOR': []
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:PARTENAIRE': []
    },
    {
      'users:MANAGER': []
    },
    {
      'users:VENDEUR': []
    }
  ],
  advisors: [
    {
      'admins:SUPER-ADMIN': ['last_name', 'first_name', 'email', 'phone_number']
    },
    {
      'admins:DIRECTION': ['last_name', 'first_name', 'email', 'phone_number']
    },
    {
      'users:MANAGER-ADVISOR': [
        'last_name',
        'first_name',
        'email',
        'phone_number'
      ]
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:PARTENAIRE': []
    },
    {
      'users:MANAGER': []
    },
    {
      'users:VENDEUR': []
    }
  ],
  'contract-types': [
    {
      'admins:SUPER-ADMIN': ['ref', 'name', 'price', 'commission']
    },
    {
      'admins:DIRECTION': ['ref', 'name', 'price', 'commission']
    },
    {
      'users:MANAGER-ADVISOR': []
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:PARTENAIRE': []
    },
    {
      'users:MANAGER': []
    },
    {
      'users:VENDEUR': []
    }
  ],
  commissions: [
    {
      'admins:SUPER-ADMIN': [
        'commission',
        'partner',
        'seller',
        'created_at',
        'amount_commission',
        'type',
        'ref'
      ]
    },
    {
      'admins:DIRECTION': [
        'commission',
        'partner',
        'seller',
        'created_at',
        'amount_commission',
        'type',
        'ref'
      ]
    },
    {
      'users:MANAGER-ADVISOR': []
    },
    {
      'users:TELECONSEILLER': []
    },
    {
      'users:PARTENAIRE': [
        'commission',
        'created_at',
        'amount_commission',
        'type',
        'ref'
      ]
    },
    {
      'users:MANAGER': [
        'commission',
        'created_at',
        'amount_commission',
        'type',
        'ref'
      ]
    },
    {
      'users:VENDEUR': [
        'commission',
        'created_at',
        'amount_commission',
        'type',
        'ref'
      ]
    }
  ],
  requests: [
    {
      'admins:SUPER-ADMIN': [
        'status',
        'comment',
        'date',
        'ref_contract',
        'client',
        'title',
        'type'
      ]
    },
    {
      'admins:DIRECTION': [
        'status',
        'comment',
        'date',
        'ref_contract',
        'client',
        'title',
        'type'
      ]
    },
    {
      'users:MANAGER-ADVISOR': [
        'status',
        'comment',
        'date',
        'ref_contract',
        'client',
        'title',
        'type'
      ]
    },
    {
      'users:TELECONSEILLER': [
        'status',
        'comment',
        'date',
        'ref_contract',
        'client',
        'title',
        'type'
      ]
    },
    {
      'users:PARTENAIRE': []
    },
    {
      'users:MANAGER': []
    },
    {
      'users:VENDEUR': []
    }
  ],
  contracts: [
    {
      'admins:SUPER-ADMIN': [
        'ref_type_contract',
        'type',
        'customer',
        'ref_seller',
        'payment_status',
        'begin_date',
        'begin_date',
        'isActive',
        'time_since_signed',
        'signed',
        'eligibility',
        'customer.benefits.title_benefit'
      ]
    },
    {
      'admins:DIRECTION': [
        'ref_type_contract',
        // 'ref',
        'type',
        'customer',
        'ref_seller',
        'payment_status',
        'isActive',
        'begin_date',
        'terminated_date',
        'time_since_signed',
        'signed',
        'contract_status',
        // 'eligibility',
        'customer.benefits.title_benefit'
      ]
    },
    {
      'users:MANAGER-ADVISOR': [
        'ref_type_contract',
        // 'ref',
        'type',
        'isActive',
        'customer',
        'ref_seller',
        'begin_date',
        'terminated_date',
        'time_since_signed',
        'signed',
        // 'eligibility',
        'contract_status',
        'customer.benefits.title_benefit'
      ]
    },
    {
      'users:TELECONSEILLER': [
        'ref_type_contract',
        // 'ref',
        'type',
        'isActive',
        'customer',
        'ref_seller',
        'begin_date',
        'terminated_date',
        'time_since_signed',
        'signed',
        // 'eligibility',
        'contract_status',
        'customer.benefits.title_benefit'
      ]
    },
    {
      'users:VENDEUR': [
        'ref_type_contract',
        // 'ref',
        'type',
        'customer',
        'ref_seller',
        'begin_date',
        'time_since_signed',
        'signed'
      ]
    },
    {
      'users:PARTENAIRE': [
        'ref_type_contract',
        'type',
        'isActive',
        // 'ref',
        'customer',
        'ref_seller',
        'begin_date',
        'time_since_signed',
        'signed'
      ]
    },
    {
      'users:MANAGER': [
        'ref_type_contract',
        'type',
        // 'ref',
        'isActive',
        'customer',
        'ref_seller',
        'begin_date',
        'time_since_signed',
        'signed'
      ]
    }
  ]
};

const AuthContext = createContext({});
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [, setRememberMe] = useStateWithLocalStorage('remember_me');
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN',
    permission: {
      grant: [],
      permission_label: ''
    }
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [isValid, setIsValid] = useState(false);
  const [isGranted, setIsGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setSession = (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };

  const loginAPI = async (email, password, remember) => {
    try {
      const result = await axiosInstance.post('/login', {
        email,
        password
      });
      setUser(result.data.user);
      setRememberMe(remember);
      setSession(result.data.token);
      return result;
    } catch (e) {
      return throw e;
    }
  };

  const registerAPI = async (values) => {
    try {
      return await axiosInstance.post('/register', values);
    } catch (e) {}
  };

  const logout = () => {
    setSession(null);
    setUser(null);
  };

  const isTokenValid = () => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        message.warn(t('login.expiredSession'));
        setSession(null);
        return false;
      }
    } catch (e) {
      message.warn(t('login.tokenError'));
      setSession(null);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  const checkIsGranted = (requestPath) => {
    const matchRuleShort = (str, rule) => {
      const escapeRegex = (str) =>
        str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
      return new RegExp(
        `^${rule
          .split('.*')
          .map(escapeRegex)
          .join('.*')}$`
      ).test(str);
    };

    const { length } = requestPath.split('.');

    if (!user.permission) {
      // TODO: handle users wihtout permission field
      return true;
    }
    const grantedArray = [
      ...user?.permission.grant,
      'settings',
      'login',
      '',
      '.'
    ].map((v) => v.toLowerCase());

    for (let index = 0; index < length; index = +1) {
      const array = requestPath.split('.');
      if (length === 1) {
        // allow top
        if (grantedArray.some((item) => item.split('.')[0] === requestPath))
          return true;
      }
      if (index === 0) {
        if (
          grantedArray.some((item) => {
            return matchRuleShort(array.join('.'), item);
          })
        )
          return true;
      } else {
        if (grantedArray.some((item) => item === array.join('.'))) return true;
        if (
          grantedArray.some((item) => {
            return matchRuleShort(array.join('.'), item);
          })
        )
          return true;
      }

      return false;
    }
  };

  const checkShouldDisplayMenuItem = (requestedItem) => {
    if (items[requestedItem]) {
      const found = items[requestedItem].find((role) => role === user?.role);
      if (found) {
        return true;
      }
    }

    return false;
  };
  const checkShouldDisplayListItem = (requestedList, item) => {
    if (listItems[requestedList].find((role) => role[user?.role])) {
      const found = listItems[requestedList]
        .find((role) => role[user?.role])
        [user?.role].find((listItem) => listItem === item);
      if (found) {
        return true;
      }
    }
  };

  const checkShouldDisplayActionItem = (requestedItem, action) => {
    if (actions_items[requestedItem].find((role) => role[user?.role])) {
      const found = actions_items[requestedItem]
        .find((role) => role[user?.role])
        [user?.role].find((action_elem) => action_elem === action);
      if (found) {
        return true;
      }
    }

    return false;
  };

  isTokenValid();

  const fetchAPI = async (
    url,
    method = 'GET',
    body = null,
    responseType = 'json',
    cancelToken
  ) => {
    try {
      isTokenValid();
      setIsLoading(true);
      const result = await axiosInstance({
        url,
        method,
        responseType,
        cancelToken,
        data: body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
      return throw e;
    }
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        return throw new Error('Unknown dispatchAPI type!');
    }
  };

  // Part responsible for handling the docusign's request for having the url code for authentication
  const dispatchCODE = async (options) => {
    try {
      const { url } = options;
      const { body } = options;
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      const result = await axiosInstance.post(url, body);
      return result;
    } catch (e) {}
  };

  // Part responsible for getting the docusign url to allow users sign their contracts
  // It also helps to get the signed document from the docusign server
  const dispatchDOC = async (options) => {
    try {
      const { url } = options;
      const { body } = options;
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      const result = await axiosInstance.post(url, body);
      return result;
    } catch (e) {}
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        isValid,
        isGranted,
        dispatchAPI,
        checkIsGranted,
        checkShouldDisplayMenuItem,
        checkShouldDisplayActionItem,
        checkShouldDisplayListItem,
        isLoading,
        dispatchCODE,
        dispatchDOC
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default () => useContext(AuthContext);
