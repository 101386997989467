import React, { useState } from 'react';
import { Row, Col, Upload, Button, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { subRoutes } from '../../../utils/constants/routes';

const { Dragger } = Upload;
const { TreeNode } = TreeSelect;

function FileUploader({
  purpose,
  id,
  typeSelector,
  forcedType,
  beforeUpload,
  hasAdditionnalButton,
  additionnalButtonCallback,
  acceptedTypes
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [type, setType] = useState('');
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState('');

  const displayUploadDescription = (uploadPurpose, fileUploaded) => {
    return (
      (fileUploaded && (
        <>
          <p className="ant-upload-text">{t('files.create.hasUploaded')}</p>
        </>
      )) || (
        <div>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
          <p className="ant-upload-hint">
            {t(`files.create.formats.title`) + acceptedTypes}
          </p>
        </div>
      )
    );
  };

  const onFileChange = info => {
    const { status } = info.file;

    if (status === 'error') {
      message(`${info.file.name} file upload failed.`);
      setHasUploaded(false);
    } else {
      setDisabled(true);
      setHasUploaded(true);
    }
  };

  return (
    <ContentCustom loading={isLoading}>
      <Row>
        {typeSelector && (
          <>
            <Col
              span={8}
              style={{
                textAlign: 'right'
              }}
            >
              <div
                style={{
                  marginTop: '4px'
                }}
              >
                <span
                  style={{
                    color: 'red'
                  }}
                >
                  *
                </span>
                &nbsp;{t(`files.form.type`)}&nbsp;:&nbsp;
              </div>
            </Col>
            <Col
              span={8}
              style={{
                marginBottom: 10
              }}
            >
              <TreeSelect
                style={{
                  width: '100%'
                }}
                onChange={v => setType(v)}
              >
                {Object.keys(subRoutes).map((route, i) => (
                  <TreeNode
                    value={`parent_${route}`}
                    title={t(`basiclayout.menu.${route.toLowerCase()}`)}
                  >
                    {Object.keys(subRoutes[route]).map(subRoute => (
                      <TreeNode
                        value={subRoute}
                        title={t(
                          `basiclayout.submenu.${subRoute.toLowerCase()}`
                        )}
                      />
                    ))}
                  </TreeNode>
                ))}
              </TreeSelect>
            </Col>
          </>
        )}
        <Col span={8} offset={8}>
          <Dragger
            name="file"
            multiple={false}
            accept={acceptedTypes}
            disabled={disabled}
            action={`${process.env.REACT_APP_API_URL}/files`}
            data={{
              type: forcedType ? forcedType : type
            }}
            headers={{ Authorization: `Bearer ${token}` }}
            onChange={onFileChange}
            beforeUpload={beforeUpload}
            showUploadList
          >
            {displayUploadDescription(purpose, disabled)}
          </Dragger>
        </Col>
        <Col
          span={8}
          offset={8}
          style={purpose === '/contracts' ? { textAlign: 'center' } : {}}
        >
          {hasAdditionnalButton && (
            <Button
              disabled={!hasUploaded}
              type="primary"
              onClick={() => {
                additionnalButtonCallback(id, uploadedFileId);
              }}
              style={{ marginRight: 5 }}
            >
              {t('files.create.check')}
            </Button>
          )}
          <Button
            type="primary"
            style={{
              color: '#fff',
              alignSelf: 'center',
              backgroundColor: 'rgb(71, 210, 103)',
              border: 'rgb(71, 210, 103)',
              marginTop: 40
            }}
            onClick={() => history.goBack()}
          >
            {t('files.create.done')}
          </Button>
        </Col>
      </Row>
    </ContentCustom>
  );
}

FileUploader.propTypes = {
  typeSelector: PropTypes.bool,
  purpose: PropTypes.string,
  id: PropTypes.string,
  forcedType: PropTypes.string,
  beforeUpload: PropTypes.func,
  hasAdditionnalButton: PropTypes.bool,
  additionnalButtonCallback: PropTypes.func,
  acceptedTypes: PropTypes.string
};

FileUploader.defaultProps = {
  typeSelector: false,
  purpose: '',
  id: '',
  forcedType: '',
  beforeUpload: () => {},
  hasAdditionnalButton: false,
  additionnalButtonCallback: () => {},
  acceptedTypes: ''
};
export default FileUploader;
