import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateContract from './Contracts/CreateUpdateContract';
import DetailContract from './Contracts/ShowContract';
import ListContracts from './Contracts/ListContracts';
import CreateTemplate from '../Documents/Templates/CreateTemplate';

const ContractRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/show/:contractId/create`}
        render={() => (
          <CreateTemplate
            acceptedTypes=".xml"
            purpose="/contracts"
            id="contractId"
            forcedType="xml_response"
          />
        )}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateContract purpose="create" />}
      />
      <Route
        path={`${path}/create?from=dashboard&id=:id`}
        render={() => <CreateUpdateContract purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateContract purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={DetailContract} />
      <Route path={`${path}`} render={() => <ListContracts />} />
    </Switch>
  );
};

export default ContractRouter;
