import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../../utils/constants/routes';
import CreateUpdateDirection from './CreateUpdateDirection';
import ListDirections from './ListDirections';
import ShowDirection from './ShowDirection';

const PartnerRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${routes.DIRECTIONS}/create`}
        render={() => <CreateUpdateDirection purpose="create" />}
      />
      <Route
        path={`${routes.DIRECTIONS}/edit/:id`}
        render={() => <CreateUpdateDirection purpose="edit" />}
      />
      <Route
        path={`${routes.DIRECTIONS}/show/:id`}
        render={() => <ShowDirection />}
      />
      <Route path={routes.DIRECTIONS} component={ListDirections} />
    </Switch>
  );
};

export default PartnerRouter;
