import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Table } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import { roundUp } from '../../utils';

export const RetentionDetailedTable = ({ range, advisors }) => {
  const { dispatchAPI } = useAuthContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: d } = await dispatchAPI('GET', {
        url: `/manager-advisors/retention-stats?start=${range[0]}&end=${
          range[1]
        }${
          advisors ? `&advisors=${advisors.map((advisor) => advisor._id)}` : ''
        }`
      });

      setData(d);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
    setIsLoading(false);
  }, [range, advisors]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <Table
      dataSource={data}
      loading={isLoading}
      scroll={{ x: true }}
      columns={[
        {
          title: 'Téléconseillers',
          dataIndex: 'supervisor',
          key: 'supervisor'
        },
        {
          title: 'Taux de Rétention',
          dataIndex: 'retention_rate',
          key: 'retention_rate',
          align: 'right',
          render: (rate) => `${roundUp(rate)}%`
        },
        {
          title: 'Résiliation',
          dataIndex: 'total_terminated',
          key: 'total_terminated',
          align: 'right'
        },
        {
          title: "Changement d'offre",
          dataIndex: 'offer_change',
          key: 'offer_change',
          align: 'right'
        },
        {
          title: 'Demande information',
          dataIndex: 'informations',
          key: 'informations',
          align: 'right'
        },
        {
          title: "Attribution d'avantages",
          dataIndex: 'benefits',
          key: 'benefits',
          align: 'right'
        }
      ]}
    />
  );
};

RetentionDetailedTable.propTypes = {
  range: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape({}))
};

RetentionDetailedTable.defaultProps = {
  advisors: undefined
};
