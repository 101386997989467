import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Button, message, Modal, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import './styles.css';
import CreateUpdateRequest from '../../Requests/CreateRequest';
import ResiliateModal from './ResiliateModal';
import CreateUpdateContract from './CreateUpdateContract';
import SellerTransfert from './SellerTransfert';

const ReceiveCall = ({
  eligibilityBenefits,
  receiveCall,
  setReceiveCall,
  store
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [contract, setContract] = useState({});
  const [callOptionState, setCallOptionState] = useState();
  const [reload, setReload] = useState(true);
  const [valueRadio, setValueRadio] = useState(1);
  const [isSelected, setIsSelected] = useState(false);
  const [callValue, setCallValue] = useState();
  const [loaderElement, setLoaderElement] = useState();
  const [reasonTermination, setReasonTermination] = useState();
  const reasonsCall = [
    "Attribution d'avantage",
    'Demande de résiliation',
    "Demande d'informations",
    "Changement d'offre",
    'Transférer le contrat sur un autre vendeur'
  ];
  useEffect(() => {
    (async () => {
      const currentContract = await dispatchAPI('GET', {
        url: `/contracts/${id}?populate=ref_type_contract, partner, customer, customer.benefits`
      });
      setContract(currentContract.data);
    })();
  }, []);

  const terminateContract = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/contracts/terminateContract/`,
        body: {
          ref_contract: id,
          ref_advisor: user._id,
          reason: reasonTermination,
          advisorName: `${user.first_name} ${user.last_name}`
        }
      });

      message.success('La demande de résilisation a été bien transmise');
      setReload(!reload);
    } catch (e) {
      message.error(`${e}`);
    }
  };

  const handleOk = () => {
    terminateContract();
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    // Check le isSelected and save the action in the db
    setReceiveCall(false);
    setIsSelected(false);
    // Open the voting modal
    // setIsModalVisible(false);
    setLoaderElement();
  };
  const validateCallChoice = async () => {
    let body;
    switch (valueRadio) {
      case reasonsCall[0]:
        setLoaderElement(
          <Modal
            title={reasonsCall[0]}
            visible
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
          >
            <CreateUpdateRequest
              purpose="create"
              setReload={setReload}
              reload={reload}
              eligibilityBenefits={eligibilityBenefits}
            />
          </Modal>
        );
        break;
      case reasonsCall[1]:
        setLoaderElement(
          <Modal
            title={reasonsCall[1]}
            visible
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
          >
            <ResiliateModal
              validation_func={handleOk}
              setReasonTermination={setReasonTermination}
              id_contract={contract._id}
              ref_seller={contract.ref_seller}
              ref_customer={contract.customer._id}
            />
          </Modal>
        );
        break;
      case reasonsCall[2]:
        /* Just close the modal */
        /* But save the informations in the db */
        body = {
          ref_user: user._id,
          ref_contract: id,
          reason: 'info',
          message: '-'
        };
        await dispatchAPI('POST', { url: `/story/`, body });
        handleCancel();
        break;
      case reasonsCall[3]:
        setLoaderElement(
          <Modal
            title={reasonsCall[1]}
            visible
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
          >
            <CreateUpdateContract
              purpose="edit"
              is_terminate
              id_contract={id}
            />
          </Modal>
        );
        /* handleCancel(); */
        break;
      case reasonsCall[4]:
        setLoaderElement(
          <Modal
            title=" Liste des vendeurs"
            visible
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
          >
            <SellerTransfert
              id={id}
              title={store?.title}
              email={store?.email}
            />
          </Modal>
        );
        break;
      case 5:
      default:
        /* Just close the modal also */
        break;
    }
  };

  useEffect(() => {
    (async () => {
      const params = location.search.split('?')[1];
      const objectParams = new URLSearchParams(params);
      const outputKeys = [...objectParams.keys()];
      const outputValue = [...objectParams.values()];
      if (outputValue.length !== 0) {
        if (
          outputValue[0] === 'allocation_benefit' ||
          outputValue[0] === 'resiliation_completed'
        ) {
          let reasonStory;
          let body;
          if (outputValue[0] === 'allocation_benefit') {
            reasonStory = 'benefit';
          } else if (outputValue[0] === 'resiliation_completed') {
            reasonStory = 'resiliate';
          }
          if (outputValue[1]) {
            body = {
              ref_user: user._id,
              ref_contract: id,
              message: outputValue[1],
              reason: reasonStory
            };
          } else {
            body = {
              ref_user: user._id,
              ref_contract: id,
              reason: reasonStory,
              message: '-'
            };
          }
          await dispatchAPI('POST', { url: `/story/`, body });
          if (callOptionState === 1) {
            message.success(
              "La demande d'avantage a été initialisée avec succès !"
            );
          } else if (callOptionState === 2) {
            message.success(
              'La demande de résiliation a été établie avec succès !'
            );
            await dispatchAPI('POST', {
              url: '/advisors/postCall/',
              body: {
                ref_contract: id,
                ref_advisor: user._id,
                reason: 1,
                type: 'TERMINATE'
              }
            });
          }
          handleCancel();
        }
      }
      if (outputValue[0] === 'resiliation_failed') {
        if (outputValue[1] === '3') {
          // The advisor convinced the user to not resiliate it's contract, rather to allocate one of it's benefits
          setCallValue("Attribution d'avantage");
          setCallOptionState(1);
          setValueRadio(1);
          setLoaderElement(<CreateUpdateRequest purpose="create" />);
        } else if (outputValue[1] === '4') {
          message.success("Le changement d'offre a été établi avec succès !");
          handleCancel();
        }
        await dispatchAPI('POST', {
          url: '/advisors/postCall/',
          body: {
            ref_contract: id,
            ref_advisor: user._id,
            reason: parseInt(outputValue[1], 10),
            type: 'TERMINATE'
          }
        });
      }
      if (outputKeys.length !== 0) {
        if (outputKeys[0] === 'rating_completed') {
          message.success('Le vote a été bien pris en compte !');
        }
        if (
          outputKeys[0] === 'rating_completed' ||
          outputKeys[0] === 'rating_not_completed'
        ) {
          if (callOptionState === 1) {
            message.success(
              "La demande d'avantage a été initialisée avec succès !"
            );
          } else if (callOptionState === 2) {
            message.success(
              'La demande de résiliation a été établie avec succès !'
            );
          }
          handleCancel();
        }
      }
      if (outputValue.length || outputKeys.length) {
        history.replace(`${id}`);
      }
    })();
  }, [location]);

  const cancelCall = async () => {
    if (callValue === 'Demande de résiliation') {
      await dispatchAPI('POST', {
        url: '/advisors/postCall/',
        body: {
          ref_contract: id,
          ref_advisor: user._id,
          reason: 2,
          type: 'TERMINATE'
        }
      });
    }
    setIsSelected(false);
  };

  return (
    <>
      <Modal
        title={[
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <p style={{ marginBottom: '0rem', fontSize: '0.7rem' }}>
                  {contract && contract.ref}
                  {contract && contract.type?.toUpperCase()}
                </p>
              </div>
              {isSelected && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '1.5rem'
                  }}
                >
                  <p
                    style={{
                      marginBottom: '0rem',
                      fontSize: '0.9rem',
                      marginLeft: 'auto'
                    }}
                  >
                    Motif
                  </p>
                  <div style={{ fontSize: '0.8rem' }}>
                    <ArrowLeftOutlined
                      style={{ marginRight: '0.25rem', color: '#989898' }}
                      onClick={cancelCall}
                    />
                    {callValue}
                  </div>
                </div>
              )}
            </div>
          </>
        ]}
        visible={receiveCall}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={800}
        className="modalBenefit"
      >
        <>
          <p style={{ marginTop: '-0.5rem' }}>{t(`call.radio.title`)}</p>
          <Radio.Group
            onChange={(e) => setValueRadio(e.target.value)}
            value={valueRadio}
          >
            <Space direction="vertical">
              {reasonsCall.map((reason) => (
                <Radio key={reason} value={reason}>
                  {reason}
                </Radio>
              ))}
              <Button
                type="primary"
                style={{ marginTop: '0.5rem' }}
                onClick={validateCallChoice}
              >
                {t(`call.select`)}
                <CheckOutlined />
              </Button>
            </Space>
          </Radio.Group>
        </>
      </Modal>
      {loaderElement}
      {/* {offreChange && (
        <div>
          <CreateUpdateContract purpose="edit" is_terminate id_contract={id} />
        </div>
      )} */}
    </>
  );
};

ReceiveCall.propTypes = {
  extra: PropTypes.element
};

ReceiveCall.defaultProps = {
  datas_benefits: null
};

export default ReceiveCall;
