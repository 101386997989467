import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Radio, Space, Tag, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import CreateUpdateContract from './CreateUpdateContract';
import { Donut_chart } from '../../home/charts_folder/Donut_chart';
import './styles.css';

const cols_donuts = {
  percent: {
    formatter: val => `${val * 100}%`
  }
};

const ResiliateModal = ({
  validation_func,
  id_contract,
  ref_seller,
  ref_customer
}) => {
  const reasons = [
    { index: 1, value: 'Refus volontaire du client (défaut)' },
    { index: 2, value: 'Impayés' },
    { index: 3, value: "Demande d'avantage" },
    { index: 4, value: 'Résiliation par le magasin' }
  ];
  const { dispatchAPI, user } = useAuthContext();
  const history = useHistory();
  const [value_radio, setValue_radio] = useState(1);
  const [datas_activityContract, setDatas_activityContract] = useState([]);
  const [sum_contracts, setSum_contracts] = useState(0);
  const [warningLabel, setWarningLabel] = useState('No');
  const [is_form, setIs_form] = useState(false);
  const [reasonTermination, setReasonTermination] = useState();
  const [dis, setDis] = useState('');

  useEffect(() => {
    const getData = async () => {
      const { data } = await dispatchAPI('GET', {
        url: `home_advisor/customer/${ref_customer}`
      });
      if (data.data[1].percent > 90 && data.data[1].percent < 99.9) {
        setWarningLabel('Middle');
      }
      if (data.data[1].percent >= 99.9) {
        setWarningLabel('Yes');
      }
      setDatas_activityContract(data.data);
      setSum_contracts(data.totalContracts);
    };
    getData();
  }, []);

  useEffect(() => {
    let query = '?event=resiliation_failed&value=';
    if (value_radio === 3) {
      query += `${value_radio}`;
      history.push(query);
    } else if (value_radio === 4) {
    }
  }, [value_radio]);

  let reason_terminate;

  useEffect(() => {
    reasons.map(reason => {
      if (reason.index === value_radio) {
        reason_terminate = reason.value;
      }
    });
    console.log('in modal', reason_terminate);
    setReasonTermination(reason_terminate);
  }, [value_radio]);

  const terminateContract = async () => {
    setDis('none');
    try {
      console.log('in post', reasonTermination);
      const res = await dispatchAPI('POST', {
        url: `/contracts/terminateContract/`,
        body: {
          ref_contract: id_contract,
          ref_advisor: user._id,
          reason: reasonTermination,
          advisorName: `${user.first_name} ${user.last_name}`
        }
      });
      console.log(res)
      message.success('La demande de résilisation a été bien transmise');
      window.location.reload();
    } catch (e) {
      message.error(`${e}`);
    }
  };

  return (
    <>
      {!is_form ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <div>
            <p style={{ marginTop: '-0.5rem', marginBottom: '0' }}>
              Précisez le motif de la résiliation
            </p>
            <p style={{ fontSize: '0.7rem' }}>
              (Nombre de contrats totalisés: {sum_contracts})
            </p>
            <Radio.Group
              onChange={e => setValue_radio(e.target.value)}
              value={value_radio}
            >
              <Space direction="vertical">
                {reasons.map(reason => (
                  <Radio key={reason.value} value={reason.index}>
                    {reason.value}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
            <div>
              {warningLabel === 'No' || warningLabel === 'Middle' ? (
                <div>
                  <Button
                    type="danger"
                    style={{ marginTop: '0.5rem' }}
                    onClick={terminateContract}
                  >
                    Résilier
                    <CloseCircleOutlined />
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: '0.5rem' }}>
                  <Tag color="red">
                    Le client a atteint le quota de résilation de contrat !
                  </Tag>
                </div>
              )}
              {warningLabel === 'Middle' && (
                <div style={{ marginTop: '0.5rem' }}>
                  <Tag color="orange">
                    Attention, le client atteint le quota de résiliation de
                    contrat
                  </Tag>
                </div>
              )}
            </div>
          </div>
          <div>
            <Donut_chart
              data={datas_activityContract}
              xAxis="item"
              yAxis="percent"
              cols={cols_donuts}
              has_legend={false}
            />
          </div>
        </div>
      ) : (
        <div>
          <CreateUpdateContract
            purpose="edit"
            is_terminate
            id_contract={id_contract}
          />
        </div>
      )}
    </>
  );
};

ResiliateModal.propTypes = {
  id_contract: PropTypes.string.isRequired,
  ref_seller: PropTypes.string,
  ref_customer: PropTypes.string,
  setReasonTermination: PropTypes.func.isRequired
};

ResiliateModal.defaultProps = {
  ref_seller: null,
  ref_customer: null
};

export default ResiliateModal;
