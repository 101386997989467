import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Space, DatePicker } from 'antd';
import moment from 'moment';
import {
  Major_graph_container as MajorGraphContainer,
  LineGraph,
  Graph_containerAdvisor as GraphContainerAdvisor
} from '../../components_home/ExtraComponents';
import { styles } from '../../styles_home/home_seller';
import ChartBiz from '../../../../components/Charts/Chart';

const { Option } = Select;
const { RangePicker } = DatePicker;

const DashboardLineGraph = ({
  partners,
  filterData,
  handleChangeStore,
  dateRange,
  setDateRange
}) => {
  const { t } = useTranslation();
  const dateFormat = 'YYYY/MM/DD';
  return (
    <MajorGraphContainer style={{ width: '100%' }}>
      <LineGraph>
        <GraphContainerAdvisor>
          <div style={styles.graph_header}>
            <p style={styles.graph_header_first}>Contrats</p>
            <div>
              {`${t('partners.show.stores')} : `}
              <Select
                defaultValue="Tous"
                style={{ width: 200 }}
                onChange={handleChangeStore}
              >
                <Option value="all">{t('global.buttons.all')}</Option>
                {partners?.map((partner) => (
                  <Option value={partner.title}>{partner.title}</Option>
                ))}
              </Select>
            </div>
            <Space direction="vertical" size={12}>
              <RangePicker
                defaultValue={[
                  moment(dateRange[0], dateFormat),
                  moment(dateRange[1], dateFormat)
                ]}
                onChange={(val) => setDateRange(val)}
                format="DD/MM/YYYY"
                allowClear={false}
              />
            </Space>
          </div>
          <div style={styles.app_divider_container_line} />
          <div style={{ cursor: 'pointer', marginTop: '30px' }}>
            <ChartBiz contractStats={filterData} />
          </div>
        </GraphContainerAdvisor>
      </LineGraph>
    </MajorGraphContainer>
  );
};

DashboardLineGraph.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeStore: PropTypes.func.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDateRange: PropTypes.func.isRequired
};

export default DashboardLineGraph;
