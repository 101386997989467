import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const boxShadowContainer = 'rgba(0, 0, 0, 0.04) 0px 3px 5px';
const lightBoxShadow =
  'rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px';
const maxWidthContainers = '405px';
const shine = keyframes`
  0% {
    transform:translateX(-100%) translateY(-100%) rotate(30deg);
  }
  80% {
  	transform:translateX(-100%) translateY(-100%) rotate(30deg);
  }
	100% {
		transform:translateX(100%) translateY(100%) rotate(30deg);
	}
`;

const commonStylesBenefits = `
	width: 39.5%;
	height: 225px;
	padding: 1.2rem;
	background: #fff;
	min-width: 400px;
	box-shadow: ${lightBoxShadow};
	margin: auto;
	margin-top: 1rem;
	border-radius: 3px;
	position: relative;
	z-index: 0;
	overflow: hidden;
	color: #fff;
	cursor: pointer;
	@media screen and (max-width: 900px){
		min-width: 360px;
	};
	@media screen and (max-width: 1780px) {
		width: 100%;
		min-width: unset;
	};
	@media screen and (min-width: 1780px) {
		min-width: 540px;
	}
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  margin-left: 0rem;
  @media screen and (max-width: 1370px) {
    justify-content: center;
    margin-left: 0rem;
  }
  flex: 1.99;
  .ant-select-selection-search-input .ant-input-wrapper .ant-input {
    padding-left: 48px;
  }
`;

const goldScroll = '#e6c272';
const platScroll = '#dbdbdb';
const commonContentListBenefits = `
	display: flex;
	justify-content: flex-end;
	max-height: 88px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 4px;
	};
`;
const ContentBenefitGold = styled.div`
  ${commonContentListBenefits};
  &::-webkit-scrollbar-thumb {
    background: ${goldScroll};
  }
`;

const ContentBenefitPlat = styled.div`
  ${commonContentListBenefits};
  &::-webkit-scrollbar-thumb {
    background: ${platScroll};
  }
`;

const GoldCard = styled.div`
  ${commonStylesBenefits};
  background: linear-gradient(to bottom, #c39738, #deb761, #c39738);
  &::after {
    content: '';
    top: 0;
    transform: translateX(100%) rotate(30deg);
    width: 300%;
    height: 300%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(128, 186, 232, 0) 100%
    );
    animation: ${shine} 3s ease-in;
  }
`;

const PlatCard = styled.div`
  ${commonStylesBenefits};
  background: linear-gradient(to bottom, #d9d8d6, #e5e4e2, #f1f0ed, #faf9f6);
  &::after {
    content: '';
    top: 0;
    transform: translateX(100%) rotate(30deg);
    width: 300%;
    height: 300%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(128, 186, 232, 0) 100%
    );
    animation: ${shine} 3s ease-in;
    animation-delay: 1s;
  }
`;
const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
  font-weight: 500;
`;

const LineGraph = styled.div`
  flex: 2;
  width: 65%;
  box-shadow: ${boxShadowContainer};
  @media screen and (max-width: 900px) {
    flex: none;
    width: 100%;
  }
`;
const ContainerCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  @media screen and (min-width: 1780px) {
    flex-flow: column wrap;
  }
`;

const FirstContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  justify-content: space-between;
  transform: translateY(20px);
`;

const DonutChart = styled.div`
  flex: 1;
  width: 32%;
  margin-left: 1rem;
  margin-right: 0rem;
  @media screen and (max-width: 1000px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0rem;
    margin-right: 0rem;
    width: 100%;
    flex: none;
    margin-top: 1rem;
  }
  box-shadow: ${boxShadowContainer};
`;

const TabContract = styled.div`
  width: 100%;
  margin-top: 0rem;
  background: #fff;
  padding: 1rem;
  box-shadow: ${boxShadowContainer};
  max-width: 435px;
  overflow: auto;
  @media screen and (max-width: 1300px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 1180px) {
    max-width: unset;
    flex: none;
  }
  border-radius: 5px;
  @media screen and (min-width: 1780px) {
    order: 3;
    max-width: 515px;
  }
  @media screen and (min-width: 1180px) {
    height: ${props => props.defaultHeight};
  }
  @media screen and (max-width: 1780px) {
    max-width: unset;
    flex: 0.1;
    margin-left: 1rem;
    min-width: 480px;
  }
  @media screen and (max-width: 1380px) {
    flex: none;
    margin-left: 0rem;
    margin-top: 1rem;
  }
`;

const BenefitContent = styled.div`
  width: 100%;
  max-width: 445px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  background: #fff;
  padding: 1.5rem;
  box-shadow: ${boxShadowContainer};
  margin-top: 0rem;
  border-radius: 5px;
  @media screen and (max-width: 1300px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 1780px) {
    order: ${props => props.order};
    max-width: 610px;
  }
  @media screen and (max-width: 1180px) {
    max-width: unset;
    flex: none;
  }
  @media screen and (max-width: 1780px) {
    max-width: unset;
    flex: 0.95;
  }
  @media screen and (max-width: 1380px) {
    flex: none;
  }
`;

const TerminateContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  background: #fff;
  padding: 1.5rem;
  box-shadow: ${boxShadowContainer};
  margin-top: 0rem;
  border-radius: 5px;
  width: 100%;
  @media screen and (min-width: 1400px) {
    width: 66%;
  }
`;
const AdvisorContainer = styled.div`
  background: #fff;
  padding: 1.5rem;
  box-shadow: ${boxShadowContainer};
  margin-top: 0rem;
  border-radius: 5px;
  width: 100%;
  margin-top: 1rem;
  @media screen and (min-width: 1400px) {
    width: 33%;
    margin-top: 0rem;
  }
`;

const RowCard = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-around;
  background: #fff;
  padding: 1.5rem;
  box-shadow: ${boxShadowContainer};
  @media screen and (max-width: 1800px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 1780px) {
    max-width: ${maxWidthContainers};
    margin-top: 0rem;
    order: ${props => props.order};
  }
  border-radius: 5px;
  position: relative;
`;

const MajorGraphContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  transform: translateY(20px);
  margin-bottom: ${props => (props.offset ? props.offset : '1rem')};
  margin-top: ${props => (props.offsetTop ? props.offsetTop : '0rem')};
`;

const Graph_container = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  margin-top: 1rem;
  height: 100%;
`;

const GraphContainerAdvisor = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  height: 100%;
`;

const Graph_containerAdvisorB = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  @media screen and (max-width: 1000px) {
    margin-top: 1rem;
  }
`;

const Divider_header = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const ComparisonTable = styled.table`
  margin: 10px auto;
  width: 100%;
`;

const ThTable = styled.th`
  padding: 1rem;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
`;

const TdTable = styled.td`
  padding: 1rem;
  width: 10%;
  border-bottom: 1px solid #efefef;
  border-right: 1px solid #efefef;
`;

const commonStylesButtonModal = `
	line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 5px 11px -10px #777;
`;
const ButtonComparisonPlat = styled.button`
  background: #cecdcd;
  color: #fff;
  ${commonStylesButtonModal};
  &:hover {
    background: #b9b7b7;
  }
`;

const ButtonComparisonGold = styled.button`
  background: #ffbe00;
  color: #fff;
  ${commonStylesButtonModal};
  &:hover {
    background: #fbbd0a;
  }
`;

const FirstContainerAdvisor = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  width: 100%;
  margin-top: 1rem;
`;

const AdvantagesTable = styled.div`
  background: #fff;
  box-shadow: ${boxShadowContainer};
  padding: 1.5rem;
  width: 100%;
  border-radius: 5px;
  position: relative;
  max-width: 100%;
  overflow: auto;
  @media screen and (min-width: 1500px) {
    flex: 2.11;
    width: 65%;
  }
`;

const ContractTable = styled.div`
  background: #fff;
  box-shadow: ${boxShadowContainer};
  padding: 1.5rem;
  max-width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
  position: relative;
  flex: 1;
  overflow: auto;
  max-height: 700px;
  @media screen and (min-width: 1500px) {
    flex: 1;
    width: 32%;
    max-width: 32%;
    height: 437px;
    margin-left: 1rem;
    margin-right: 0rem;
    margin-top: 0rem;
  }
`;

const SecondContainerAdvisor = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row wrap;
  align-items: center;
`;

const ProfitContainer = styled.div`
  background: #fff;
  box-shadow: ${boxShadowContainer};
  padding: 1.5rem;
  width: 100%;
  border-radius: 5px;
`;

const ResiliateContainer = styled.div`
  background: #fff;
  box-shadow: ${boxShadowContainer};
  padding: 1.5rem;
  width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
`;

const CarouselContainer = styled.div`
  width: 100%;
  margin-top: 0rem;
  margin-top: ${props => (props.offsetTop ? props.offsetTop : '-1rem')};
  margin-bottom: 1rem;
`;

const HeaderCarousel = styled.h1`
  position: relative;
  color: #ccc;
  line-height: 2.3rem;
  margin-top: 5px;
  margin-bottom: 0rem;
  font-size: 2.3rem;
  font-weight: bold;
  @media screen and (max-width: 1300px) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 18px;
    line-height: 18px;
  }
  &::before {
    content: '';
    position: absolute;
    background: var(--primaryColor);
    // background-image: linear-gradient(90deg, #871d81, #e2001a 50%);
    width: 150px;
    height: 6px;
    border-radius: 50px;
    top: 110%;
    left: ${props => props.offsetLeft};
  }
`;

const DescriptionCarousel = styled.h1`
  position: relative;
  color: rgba(0, 0, 0, 0.5);
  line-height: auto;
  margin-bottom: 0rem;
  margin-top: 20px;
  font-size: 1.1rem;
  font-weight: bold;
  @media screen and (max-width: 1300px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-top: 12px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const HeaderCarouselFirst = styled.div`
  position: relative;
  line-height: 0.9;
  margin-bottom: 0rem;
  font-size: 2rem;
  font-weight: bold;
  @media screen and (max-width: 1300px) {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
`;
const DescriptionCarouselFirst = styled.div`
  position: relative;
  line-height: 2.9;
  margin-bottom: 0rem;
  font-size: 1.4rem;
  @media screen and (max-width: 1300px) {
    font-size: 1rem;
    line-height: 1rem;
    margin: 8px 0 8px 0;
  }
`;

export {
  Divider_header,
  Graph_container,
  MajorGraphContainer as Major_graph_container,
  RowCard,
  BenefitContent,
  TabContract,
  DonutChart,
  FirstContainer,
  ContainerCard,
  LineGraph,
  TabHeader,
  PlatCard,
  GoldCard,
  SearchContainer,
  ContentBenefitGold,
  ContentBenefitPlat,
  ComparisonTable,
  ThTable,
  TdTable,
  ButtonComparisonGold,
  ButtonComparisonPlat,
  FirstContainerAdvisor,
  AdvantagesTable,
  ContractTable,
  SecondContainerAdvisor,
  ProfitContainer,
  ResiliateContainer,
  GraphContainerAdvisor as Graph_containerAdvisor,
  Graph_containerAdvisorB,
  CarouselContainer,
  TerminateContainer,
  AdvisorContainer,
  HeaderCarousel,
  DescriptionCarousel,
  HeaderCarouselFirst,
  DescriptionCarouselFirst
};
