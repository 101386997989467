/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

import { styles, ActionsContainer } from '../../assets/styles/styles';
import '../../assets/styles/SCSS/comment.scss';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const Eligiblebenefits = ({ data: customerId }) => {
  const { dispatchAPI, user } = useAuthContext();
  const [eligibilityBenefits, setEligibilityBenefits] = useState();
  const { message } = useErrorMessage();

  const columnsEligibilityBenefits = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {"Statut d'activation"}
          </div>
        );
      },
      dataIndex: 'eligibility',
      key: 'eligibility',
      render: text =>
        Number(text) < 0
          ? "Le client n'est plus éligible à cette offre"
          : Number(text) == 0
          ? 'Le client est éligible à cette offre'
          : `${text} Jours restants`
    }
  ];

  const fetchData = async () => {
    try {
      const tmpEligibilityBenefits = await dispatchAPI('GET', {
        url: `/requests/getEligibilityBenefitList/${customerId}`
      });
      setEligibilityBenefits(tmpEligibilityBenefits.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <>
      <ActionsContainer>
        <div style={styles.graph_header}>
          <p style={styles.graph_header_first}>{'Les avantages éligibles'}</p>
          <p style={styles.graph_header_second} />
        </div>
        <div style={styles.app_divider_container_comment} />
        {eligibilityBenefits && (
          <Table
            columns={columnsEligibilityBenefits}
            expandable={{
              expandedRowRender: record => (
                <p style={{ margin: 0 }}>{record.description}</p>
              )
            }}
            dataSource={eligibilityBenefits}
          />
        )}
      </ActionsContainer>
    </>
  );
};

export default Eligiblebenefits;
