import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, DatePicker, Divider } from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';

import Payments from './Payments';

const { RangePicker } = DatePicker;

const ListRequests = () => {
  const { dispatchAPI, checkShouldDisplayActionItem } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/requests/enumsBenefits'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => getSelectOptions())();
  }, []);

  const { t } = useTranslation();
  const columns = [
    {
      title: t('benefits.form.type'),
      key: 'ref_benefitType',
      dataIndex: 'ref_benefitType',
      render: type => type && type.attr,
      sorter: true
    },
    {
      title: t('benefits.form.offer_name'),
      key: 'ref_benefitType',
      dataIndex: 'ref_benefitType',
      render: type => type && type.title,
      sorter: true
    },
    {
      title: t('benefits.form.client'),
      key: 'customer',
      dataIndex: 'customer',
      render: customer =>
        customer && `${customer.last_name} ${customer.first_name}`,
      sorter: true
    },
    {
      title: t('benefits.form.contrat'),
      key: 'ref_contract',
      dataIndex: 'ref_contract',
      render: contract => contract && contract.ref,
      sorter: true
    },
    {
      title: t('benefits.form.date'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: created_at =>
        created_at && moment(created_at).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('benefits.form.status'),
      key: 'status_activation',
      dataIndex: 'status_activation',
      sorter: true,
      render: status => status || 'Statut indéfini',
      filters:
        enums &&
        enums.status_validation &&
        enums.status_validation.map(status => ({
          text: status,
          value: status
        }))
    }
  ];

  return (
    (isLoading && <Spin spinning={isLoading} />) || (
      <>
        <SimpleResourceLandingLayout
          resourceName="requests"
          columns={columns}
          resourceModelName="Benefit"
          worth_styling
          withUploadButton={false}
          withCreateButton={false}
          editAction={false}
          deleteAction={false}
        />
        {checkShouldDisplayActionItem('contracts', 'download') && (
          <>
            <Divider orientation="left" style={{ marginTop: 48 }}>
              Liste de virements
            </Divider>
            <Payments />
          </>
        )}
      </>
    )
  );
};

export default ListRequests;
