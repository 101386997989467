import { Radio, message, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';

const SellerTransfert = ({ id, title, email }) => {
  const [sellers, setSellers] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [sellerEmail, setSellerEmail] = useState(email);
  const { t } = useTranslation();
  const location = useLocation();
  const onChange = (e) => {
    setSellerEmail(e.target.value);
  };
  console.log('seller', sellers);
  console.log('title', title);
  const getSellers = async () => {
    let sellersAll ;
    if (title === 'FNAC CONNECT NARBONNE') {
      sellersAll = await dispatchAPI('GET', {
        url: `/stores/get/stores?title=Fnac Connect Narbonne&populate=sellers`
      });
    } else {
      sellersAll = await dispatchAPI('GET', {
        url: `/stores/get/stores?title=${title}&populate=sellers`
      });
    }

    setSellers(sellersAll.data[0].sellers);
  };
  const updateContractSeller = async () => {
    try {
      const seller = await dispatchAPI('GET', {
        url: `/users?email=${sellerEmail}`
      });
      await dispatchAPI('PATCH', {
        url: `/contracts/${id}`,
        body: {
          ref_seller: seller.data[0]._id
        }
      });
      window.location.reload();
      return message.success('Transfert du contrat réussi');
    } catch (error) {
      return message.error(error);
    }
  };
  useEffect(() => {
    (async () => {
      await getSellers();
    })();
  }, [location]);
  return (
    <>
      <Radio.Group onChange={onChange} value={sellerEmail}>
        {sellers.map((seller) => (
          <Radio value={seller.email}>
            {seller.first_name} {seller.last_name}
          </Radio>
        ))}
      </Radio.Group>
      <Button
        type="primary"
        style={{ marginTop: '0.5rem' }}
        onClick={updateContractSeller}
      >
        {t(`call.select-seller`)}
        <CheckOutlined />
      </Button>
    </>
  );
};
export default SellerTransfert;
