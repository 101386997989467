import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
const useColumns = () => {
  const { t } = useTranslation();
  const { user, checkShouldDisplayListItem } = useAuthContext();

  useEffect(() => {
    (async () => {})();
  }, []);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  let columns = [
    {
      title: t('partners.form.partner.columns.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: name => capitalizeFirstLetter(name.toLowerCase())
    },
    {
      title: t('stores.form.full_address'),
      dataIndex: 'adress',
      key: 'adress',
      sorter: true,
      render: d => d.toLowerCase()
    },
    {
      title: t('partners.form.partner.display.capital'),
      dataIndex: 'capital',
      key: 'capital',
      sorter: true,
      render: d =>
        new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(d)
    },
    {
      title: t('partners.form.partner.columns.siren'),
      dataIndex: 'siren_number',
      key: 'siren_number',
      sorter: true
    },
    {
      title: t('stores.form.full_name'),
      dataIndex: 'manager',
      key: 'manager',
      sorter: true
    },
    {
      title: t('stores.form.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: t('stores.form.number'),
      dataIndex: 'stores',
      key: 'totalNumber',
      render: stores =>
        stores.length > 0 ? stores.length : 'Aucun magasin créé'
    }
  ];
  columns = columns.filter(column =>
    checkShouldDisplayListItem('partners', column.key)
  );
  return columns;
};

export default useColumns;
