import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { contractTypesShow } from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  ref,
  name,
  price,
  show,
  benefits,
  template_file,
  commission,
  duration_engagement
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'contract-types.form.ref',
      content: ref || '-',
      span: 3
    },
    {
      label: 'contract-types.form.name',
      content: name || '-',
      span: 3
    },
    {
      label: 'contract-types.form.duration_engagement',
      content: duration_engagement || '-',
      span: 3
    },
    {
      label: 'contract-types.form.price',
      content: `${price} €` || '-',
      span: 3
    },
    /* {
      label: 'contract-types.form.show',
      content: show && (
        <Tag color={contractTypesShow[show]}>
          {t(`contract-types.tags.${show}`)}
        </Tag>
      ),
      span: 3
    }, */
    /* {
      label: 'contract-types.form.benefits',
      content: benefits && benefits.length !== 0 ? benefits : '-',
      span: 3
    }, */
    {
      label: 'contract-types.form.commission',
      content: `${commission} €` || '-',
      span: 3
    }
  ];
};

export default useListContent;
