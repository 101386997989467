import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateStore = ({ purpose }) => {
  const { fields, suggestion, partner_name } = useFields(purpose);
  const { user } = useAuthContext();
  const { id } = useParams();

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ref_partner: id || user._id
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="stores"
      resource="stores"
      config={config}
      updatedValues={suggestion} /* For autocomplete purpose */
      partner_name={partner_name}
    />
  );
};

CreateUpdateStore.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateStore;
