import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Divider,
  Popconfirm,
  Button,
  Row,
  Table,
  Modal,
  Upload,
  Col
} from 'antd';
import Columns from '../Documents/Templates/Columns';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';

const iconSize = 18;
const { Dragger } = Upload;
let files = [];

const Documents = props => {
  const { dataSource, benefitId } = props;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState(files);

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      for (var i = 0; i < base64.length; i++) {
        base64[i].name === file.name && base64.splice(i, 1);
      }
      setFileList(newFileList);
      setBase64(base64);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension === 'pdf') {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        files.push({ name: file.name, content: base });
        setBase64(files);
        return false;
      } else {
        message('Not a PDF file.');
        return true;
      }
    },
    fileList
  };

  const fileToBase64 = async file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const handleCancel = () => {
    setBase64([]);
    setFileList([]);
    setIsModalVisible(false);
  };

  const deleteFile = async record => {
    try {
      await dispatchAPI('DELETE', {
        url: `/requests/files/${benefitId}/${record._id}`
      });
      window.location.reload();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async record => {
    try {
      const a = document.createElement('a');
      a.href = record.content;
      a.download = `${record.name}`;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const uploadFile = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/requests/${benefitId}`,
        body: { files: base64 }
      });
      setIsModalVisible(false);
      window.location.reload();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const actionColumn = [
    {
      key: 'action',
      render: record => {
        return (
          <span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={async () => {
                await downloadFile(record);
              }}
            >
              <DownloadOutlined />
            </Button>

            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
              onConfirm={async () => {
                await deleteFile(record);
              }}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  return (
    <>
      <Row justify="space-between" gutter={[8, 8]}>
        <Col></Col>
        <Col>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            {`${t('buttons.create')}`}
            &nbsp;
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={[...Columns(t), ...actionColumn]}
      />
      <Modal
        title="Charger un fichier"
        visible={isModalVisible}
        onOk={uploadFile}
        onCancel={handleCancel}
      >
        <Dragger {...draggerProps}>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      </Modal>
    </>
  );
};

export default Documents;
