import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import useEmailPattern from '../../utils/emailPattern';
import phoneField from '../../utils/formFields/phoneField';
import emailField from '../../utils/formFields/emailField';

const useFields = purpose => {
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [userEmails, setMails] = useState([]);
  const [currentManagerAdvisor, setCurrentManagerAdvisor] = useState();

  const getManagerAdvisor = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/manager-advisors/${id}`
      });
      setCurrentManagerAdvisor(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getManagerAdvisor();
      })();
    }
  }, []);

  const getMails = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users/mails/' });
    setMails(data);
  };

  useEffect(() => {
    (async () => {
      await getMails();
    })();
  }, []);

  const fields = [
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'last_name',
      name: ['last_name'],
      rules: [{ required: true }]
    },
    emailField(userEmails, currentManagerAdvisor?.email, purpose, true),
    phoneField(true)
  ];

  return {
    fields
  };
};

export default useFields;
