import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import showCustomerContent from './listContent';
import { PageHeaderCustom, DescriptionList } from '../../../components';
import { routes } from '../../../utils/constants/routes';
import useOverdues from './Overdues';
import ImportButton from '../../../components/ImportButton/ImportButton';
import { MajorContainer } from './styles';

const ShowCustomer = () => {
  const { functionHandleFile } = useOverdues();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const authContext = useAuthContext();

  const getCustomer = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}?populate=contract`
      });
      setCustomer(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCustomer();
    })();
  }, [getCustomer]);

  const deleteCustomer = async () => {
    try {
      await dispatchAPI('DELETE', { url: `${routes.CUSTOMERS}/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('customers.show.title')}
        extra={
          <>
            {authContext.checkShouldDisplayActionItem('customers', 'edit') && (
              <Link
                to={{
                  pathname: `${routes.CUSTOMERS}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {authContext.checkShouldDisplayActionItem(
              'customers',
              'delete'
            ) && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteCustomer}
                icon={<WarningOutlined />}
              >
                <Button type="primary">
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
            {/* <ImportButton
              resourceName="overdues"
              customImportAction={functionHandleFile}
            /> */}
          </>
        }
      />
      <MajorContainer>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={showCustomerContent(customer || {})}
            translate
          />
        </Skeleton>
      </MajorContainer>
    </>
  );
};

export default ShowCustomer;
