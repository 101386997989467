import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Avatar, Comment, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';

const { TextArea } = Input;
const CommentList = ({ comments }) => {
  const { t } = useTranslation();
  const getReasonTranslation = story => {
    const translationPrefix = 'stories.list.title.';
    const expr = new RegExp(`^${translationPrefix}`);
    const reason = !story.reason
      ? story.reason
      : t(`${translationPrefix}${story.reason}`);
    if (expr.test(reason)) {
      return story.reason;
    }
    return reason;
  };

  const getStoryDisplay = stories => {
    return stories.map(item => (
      <div
        key={item.content}
        style={{
          padding: '1rem',
          display: 'flex',
          alignItems: 'start',
          width: '95%',
          boxShadow:
            'rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px 8px',
          margin: 'auto',
          marginTop: '1rem',
          marginBottom: '1rem'
        }}
      >
        <div>
          {(!item.reason && (
            <div
              style={{
                width: '10px',
                height: '11px',
                borderRadius: '3px',
                background: '#ff7a45',
                transform: 'translateY(5px)'
              }}
            />
          )) ||
            (item.reason === 'resiliate' && (
              <div
                style={{
                  width: '10px',
                  height: '11px',
                  borderRadius: '3px',
                  background: '#f759ab',
                  transform: 'translateY(5px)'
                }}
              />
            )) ||
            (item.reason === 'benefit' && (
              <div
                style={{
                  width: '10px',
                  height: '11px',
                  borderRadius: '3px',
                  background: '#85a5ff',
                  transform: 'translateY(5px)'
                }}
              />
            )) ||
            (item.reason === 'info' && (
              <div
                style={{
                  width: '10px',
                  height: '11px',
                  borderRadius: '3px',
                  background: '#69c0ff',
                  transform: 'translateY(5px)'
                }}
              />
            )) ||
            (item.reason === 'resiliate_cancel' && (
              <div
                style={{
                  width: '10px',
                  height: '11px',
                  borderRadius: '3px',
                  background: '#13c2c2',
                  transform: 'translateY(5px)'
                }}
              />
            )) ||
            (item.reason === 'resiliate_success' && (
              <div
                style={{
                  width: '10px',
                  height: '11px',
                  borderRadius: '3px',
                  background: '#73d13d',
                  transform: 'translateY(5px)'
                }}
              />
            )) || (
              <div
                style={{
                  width: '10px',
                  height: '11px',
                  borderRadius: '3px',
                  background: '#ff7a45',
                  transform: 'translateY(5px)'
                }}
              />
            )}
        </div>
        <div style={{ marginLeft: '0.5rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                marginLeft: '0.5rem',
                marginBottom: '0rem',
                marginRight: '0.5rem',
                fontStyle: 'italic'
              }}
            >
              {` ${item.author}: `}
            </p>
            <p style={{ marginBottom: '0rem' }}>{getReasonTranslation(item)}</p>
            <p
              style={{
                marginBottom: '0rem',
                marginLeft: '0.5rem',
                fontSize: '12px',
                lineHeight: '18px',
                color: '#ccc'
              }}
            >
              Le {item.datetime}
            </p>
          </div>
          {item.reason !== 'benefit' ||
            (item.reason === 'resiliate' && (
              <p style={{ marginBottom: '0rem', display: 'flex' }}>
                Motif:{' '}
                <span style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>
                  {item.content}
                </span>
              </p>
            ))}
          <p style={{ marginBottom: '0rem', display: 'flex' }}>
            {item.content && item.content !== '-' && (
              <>
                <span>Commentaire: </span>
                <span style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>
                  {item.content}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="listContainer">
        <div>
          <div
            style={{
              borderBottom: '1px solid #f0f0f0',
              paddingTop: '12px',
              paddingBottom: '12px'
            }}
          >
            {`${
              comments.length > 1
                ? `${comments.length} activités récentes`
                : comments.length === 1
                ? `${comments.length} activité récente`
                : 'Aucune activité récente'
            }`}
          </div>
          <div className="ant-list-items">{getStoryDisplay(comments)}</div>
        </div>
      </div>
    </>
  );
};

const ActionCommentContainer = () => {
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [reasonComment, setReasonComment] = useState();
  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const { data } = await dispatchAPI('GET', {
        url: `/story?ref_contract=${id}`
      });
      const output = [];
      data.forEach(story => {
        const outputStream = {};
        outputStream.author = story.author;
        outputStream.content = story.content;
        if (story.reason) {
          outputStream.reason = story.reason;
        }
        outputStream.datetime = moment(story.datetime).format('Do MMMM YYYY');
        output.push(outputStream);
      });
      setComments(output);
    };
    getData();
  }, []);

  const handleSubmit = async () => {
    if (!value) {
      return;
    }
    setSubmitting(true);
    let body;
    if (reasonComment) {
      body = {
        ref_user: user._id,
        ref_contract: id,
        message: value,
        reason: reasonComment
      };
    } else {
      body = { ref_user: user._id, ref_contract: id, message: value };
    }
    await dispatchAPI('POST', { url: `/story/`, body });
    setTimeout(() => {
      setValue('');
      setReasonComment('');
      setSubmitting(false);
      setComments([
        ...comments,
        {
          author: `${user.first_name} ${user.last_name}`,
          reason: reasonComment,
          content: <p>{value}</p>,
          datetime: moment()
            .subtract(50, 'seconds')
            .fromNow()
        }
      ]);
    }, 1000);
  };

  const handleChange = e => {
    setValue(e.target.value);
    if (e.target.value.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  const handleReason = e => {
    setReasonComment(e.target.value);
  };
  return (
    <>
      <div className="actionCommentContainer">
        {comments.length > 0 && <CommentList comments={comments} />}
        <Comment
          avatar={
            <Avatar
              size="medium"
              icon={user && user.photo ? '' : <UserOutlined />}
              src={user && user.photo ? user.photo : ''}
            >
              {user && `${user.first_name[0]}${user.last_name[0]}`}
            </Avatar>
          }
          content={
            <div className="formHistory">
              <Form.Item>
                <Input
                  placeholder="Renseignez l'objet du commentaire"
                  value={reasonComment}
                  onChange={handleReason}
                />
                <TextArea
                  rows={4}
                  onChange={handleChange}
                  value={value}
                  placeholder="Ecrivez..."
                  style={{ marginTop: '1rem' }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={submitting}
                  onClick={handleSubmit}
                  type="primary"
                  disabled={isDisabled}
                >
                  Ajouter
                  <PlusOutlined />
                </Button>
              </Form.Item>
            </div>
          }
        />
      </div>
    </>
  );
};

export default ActionCommentContainer;
