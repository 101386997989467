import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListStores from './ListStores';
import CreateUpdateStore from './CreateUpdateStore';
import ShowStore from './ShowStore';

const StoreRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create/:id`}
        render={() => <CreateUpdateStore purpose="create" />}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateStore purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateStore purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowStore />} />
      <Route path={`${path}`} render={() => <ListStores />} />
    </Switch>
  );
};

export default StoreRouter;
