import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Tag, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../utils/ErrorMessage';

const useFields = profile => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  let fields = [];
  profile &&
    Object.keys(profile).map(key => {
      fields.push({
        name: [key],
        rules: [{ required: false }]
      });
    });

  return {
    fields
  };
};

export default useFields;
