import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';

const ListAdvisors = () => {
  const { t } = useTranslation();
  const {
    checkShouldDisplayListItem,
    checkShouldDisplayActionItem
  } = useAuthContext();

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.phone_number_full'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="advisors"
      columns={columns.filter((column) =>
        checkShouldDisplayListItem('advisors', column.key)
      )}
      withCreateButton={checkShouldDisplayActionItem('advisors', 'create')}
      headers={headers}
      resourceModelName="ADVISORS"
      withUploadButton={false}
      worth_styling
    />
  );
};

export default ListAdvisors;
