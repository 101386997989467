import React from 'react';
import { Form, Input, Button, Space, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const BenefitsFormList = () => {
  return (
    <Form.List name="benefits">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'block',
                marginBottom: 8,
                backgroundColor: 'var(--componentBackground)'
              }}
            >
              <Row>
                <Col span={22} push={2}>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    rules={[{ required: true, message: 'Missing title' }]}
                  >
                    <Input placeholder="title" />
                  </Form.Item>
                </Col>
                <Col span={2} pull={22}>
                  <MinusCircleOutlined
                    style={{ color: 'var(--secondaryColor)', marginTop: 9 }}
                    onClick={() => remove(name)}
                  />
                </Col>
              </Row>
              <Form.Item
                {...restField}
                name={[name, 'content']}
                fieldkey={[fieldKey, 'content']}
                rules={[{ required: true, message: 'Missing title' }]}
              >
                <TextArea placeholder="content" rows={5} />
              </Form.Item>
            </Space>
          ))}
          <Form.Item>
            <Button
              block
              type="dashed"
              onClick={() => add()}
              color="var(--addColor)"
              icon={<PlusOutlined />}
            >
              Ajouter un bénéfice
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default BenefitsFormList;
