import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import { useStateWithLocalStorage } from '../../../utils';

const ListCustomers = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const { user, checkShouldDisplayListItem } = useAuthContext();

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'Address street number',
      key: 'address.number'
    },
    {
      label: 'Address street name',
      key: 'address.street'
    },
    {
      label: 'Address additional information',
      key: 'address.additional'
    },
    {
      label: 'Address postal code',
      key: 'address.postal_code'
    },
    {
      label: 'Address city',
      key: 'address.city'
    },
    {
      label: 'Address country',
      key: 'address.country'
    }
  ];
  const truncate = (str, n) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  };
  const columns = [
    {
      title: t('customers.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('customers.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('customers.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('customers.form.address_full'),
      key: 'address',
      dataIndex: 'address',
      sorter: true,
      render: text => truncate(text.street, 35)
    },
    {
      title: t('customers.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      sorter: true
    }
  ];
  return (
    <>
      {user.role === 'admins:DIRECTION' ? (
        <SimpleResourceLandingLayout
          resourceName="customers"
          columns={columns.filter(column =>
            checkShouldDisplayListItem('customers', column.key)
          )}
          resourceModelName="Customer"
          headers={headers}
          withUploadButton={false}
          withCreateButton={authContext.checkShouldDisplayActionItem(
            'customers',
            'create'
          )}
          editAction={authContext.checkShouldDisplayActionItem(
            'customers',
            'edit'
          )}
          deleteAction={authContext.checkShouldDisplayActionItem(
            'customers',
            'delete'
          )}
          worth_styling
        />
      ) : (
        <SimpleResourceLandingLayout
          resourceName="customers"
          columns={columns}
          resourceModelName="Customer"
          withUploadButton={false}
          headers={headers}
          withCreateButton={authContext.checkShouldDisplayActionItem(
            'customers',
            'create'
          )}
          editAction={authContext.checkShouldDisplayActionItem(
            'customers',
            'edit'
          )}
          deleteAction={authContext.checkShouldDisplayActionItem(
            'customers',
            'delete'
          )}
          worth_styling
        />
      )}
    </>
  );
};

export default ListCustomers;
