import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  message,
  Popconfirm,
  Skeleton,
  Table,
  Row,
  Drawer,
  Modal
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  EyeOutlined,
  PlusOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { routes } from '../../utils/constants/routes';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import ListContent from './ListContent';
import useColumns from './Stores/columns';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import CommissionSetter from '../home/components_home/CommissionSetter';

const iconSize = 18;

const ShowPartner = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [partner, setPartner] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);
  const columns = useColumns(id);
  const [visible, setVisible] = useState(false);
  const [id_partner, setId_partner] = useState();

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/partners/${id}?populate=stores.sellers.contracts&fields=-stores.sellers.contracts.contentContract`
      });
      setPartner(data);
      const getUserPartner = await dispatchAPI('GET', {
        url: `/partners/getUserParter/${id}`
      });
      setId_partner(getUserPartner.data._id);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsloading(false);
  }, [id, visible]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const deleteResource = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/stores/${id}` });
      await fetchData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response) message(e.response.status);
    }
  };

  const deletePartner = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/partners/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };
  const actionColumn = {
    key: 'action',
    fixed: 'right',
    width: '9%',
    // eslint-disable-next-line react/prop-types
    render: ({ key }) => (
      <div style={{ float: 'right' }}>
        <Link to={`/stores/show/${key}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Link to={`/stores/edit/${key}`} replace>
          <EditOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Divider type="vertical" />
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => deleteResource(key)}
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'red', fontSize: iconSize }}
            type="delete"
          />
        </Popconfirm>
      </div>
    )
  };

  const getDatasource = () => {
    const result = (partner.stores || []).map((store) => ({
      ...store,
      key: store._id
    }));
    return result;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Montants Commissions"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={350}
      >
        <CommissionSetter resource="partners" layout="horizontal" />
      </Modal>
      <Drawer width={320} visible={visible} onClose={() => setVisible(false)} />

      <PageHeaderCustom
        title={t(`companies.show.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.PARTNER}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deletePartner}
              icon={<WarningOutlined />}
            >
              <Button type="primary" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Button type="primary" onClick={showModal}>
              Montants Commissions
            </Button>
          </>
        }
      />

      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={ListContent(partner)} translate />
        </Skeleton>
        <Divider orientation="left" style={{ marginTop: 48 }}>
          {t('companies.show.contactListTitle')}
        </Divider>

        <Row gutter={[0, 16]} justify="end">
          <Button
            onClick={() => history.push(`/stores/create/${id_partner}`)}
            type="primary"
          >
            {`${t('buttons.create')} `}
            <PlusOutlined />
          </Button>
        </Row>
        <Table
          dataSource={getDatasource()}
          columns={[...columns, actionColumn]}
          onRow={({ key }) => ({
            onDoubleClick: () => history.push(`/stores/show/${key}`)
          })}
          scroll={{ x: 1350 }}
        />
      </ContentCustom>
    </>
  );
};

export default ShowPartner;
