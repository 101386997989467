import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, AutoComplete, Input, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';

const renderTitle = (title, route) => (
  <span>
    <span style={{ color: 'var(--primaryColor)' }}>{title}</span>
    <Link
      style={{
        float: 'right',
        color: 'var(--primaryColor)'
      }}
      to={route}
    >
      more
    </Link>
  </span>
);
const renderItem = (title, route) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between'
    }}
  >
    <p style={{ color: '#000', marginBottom: '0rem' }}>{title}</p>
  </div>
);

const SearchDatas = ({ searchResults, placeholder }) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [width_search, setWidth_search] = useState('100%');
  const { dispatchAPI, user } = useAuthContext();
  const handleSearch = value => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value, option) => {
    if (option) {
      if (option.datas.type === 'Client') {
        history.replace(`/customers/show/${option.datas.id}`);
      } else if (option.datas.type === 'Contract') {
        history.replace(`/contracts/show/${option.datas.id}`);
      } else if (option.datas.type === 'Seller') {
        history.replace(`/sellers/show/${option.datas.id}`);
      } else if (option.datas.type === 'Advisor') {
        history.replace(`/advisors/show/${option.datas.id}`);
      } else if (
        option.datas.type === 'Store' ||
        option.datas.type === 'StoreManager'
      ) {
        history.replace(`/stores/show/${option.datas.id}`);
      } else if (option.datas.type === 'Partner') {
        history.replace(`/partners/show/${option.datas.id}`);
      } else {
        history.replace(`/contracts-settings/partners/show/${option.datas.id}`);
      }
    }
  };
  const [datas_global, setDatas_global] = useState();
  const get_datas_global = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/`
    });
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const searchResult = query => {
    const newArray = searchResults.filter(el => {
      return el.value.toLowerCase().includes(query.toLowerCase());
    });

    const arr = newArray.map(array => {
      const name = `${capitalizeFirstLetter(array.value)}`;
      const type = `${capitalizeFirstLetter(array.type)}`;
      const id = `${array.id}`;
      return {
        value: name,
        datas: { type, id },
        label:
          type === 'Client'
            ? renderItem(name, `/customers/show/${id}`)
            : type === 'Contract'
            ? renderItem(name, `/contracts/show/${id}`)
            : type === 'Seller'
            ? renderItem(name, `/sellers/show/${id}`)
            : type === 'Advisor'
            ? renderItem(name, `/advisors/show/${id}`)
            : type === 'Store' || type === 'StoreManager'
            ? renderItem(name, `/stores/show/${id}`)
            : type === 'Partner'
            ? renderItem(name, `/partners/show/${id}`)
            : renderItem(name, `/contracts-settings/partners/show/${id}`)
      };
    });

    const output = [];
    const optionCustomer = arr.filter(result => result.datas.type === 'Client');
    const optionContract = arr.filter(
      result => result.datas.type === 'Contract'
    );
    const optionSeller = arr.filter(result => result.datas.type === 'Seller');
    const optionAdvisor = arr.filter(result => result.datas.type === 'Advisor');
    const optionPartner = arr.filter(result => result.datas.type === 'Partner');
    const optionStore = arr.filter(result => result.datas.type === 'Store');
    const optionStoreManager = arr.filter(
      result => result.datas.type === 'StoreManager'
    );

    if (optionCustomer.length) {
      output.push({
        label: renderTitle('Clients', '/customers/'),
        options: optionCustomer
      });
    }
    if (optionContract.length) {
      output.push({
        label: renderTitle('Contrats', '/contracts/'),
        options: optionContract
      });
    }
    if (optionSeller.length) {
      output.push({
        label: renderTitle('Vendeurs', '/sellers/'),
        options: optionSeller
      });
    }
    if (optionAdvisor.length) {
      output.push({
        label: renderTitle('téléconseillers', '/advisors/'),
        options: optionAdvisor
      });
    }
    if (optionStore.length) {
      output.push({
        label: renderTitle('Magasins', '/stores/'),
        options: optionStore
      });
    }
    if (optionStoreManager.length) {
      output.push({
        label: renderTitle('Responsables magasins', '/stores/'),
        options: optionStoreManager
      });
    }
    if (optionPartner.length) {
      output.push({
        label: renderTitle('Partenaires', '/partners/'),
        options: optionPartner
      });
    }
    return output;
  };
  return (
    <AutoComplete
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth
      style={{ width: width_search, marginLeft: '0px' }}
      options={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      notFoundContent="Aucun élément trouvé"
      defaultOpen={false}
    >
      <Input.Search size="default" placeholder={placeholder} />
    </AutoComplete>
  );
};

SearchDatas.propTypes = {
  searchResults: PropTypes.array,
  placeholder: PropTypes.string
};

SearchDatas.defaultProps = {
  searchResults: null,
  placeholder: 'Rechercher mes clients, mes contrats...'
};

export default SearchDatas;
