import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { message, Row, Col, Skeleton } from 'antd';
import { motion } from 'framer-motion';
import moment from 'moment';
import '../../../assets/styles/SCSS/tableHome.scss';
import useAuthContext from '../../../contexts/AuthContext';
import SearchDatas from '../components_home/SearchDatas';
import { SearchContainer } from '../components_home/ExtraComponents';
import ListStores from '../components_home/ListStores';
import ListTerminate from '../components_home/ListTerminate';
import DashboardLineGraph from './components/DashboardLineGraph';
import DashboardPieGraph from './components/DashboardPieGraph';

const Home_advisor = () => {
  const [searchResults, setSearchResults] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [datas_resiliated, setDatas_resiliated] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterDataStats] = useState([]);
  const [filterDataTab, setFilterDataTab] = useState([]);
  const [store, setStore] = useState('all');
  const [partners, setPartners] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().format('YYYY-MM'),
    moment().format('YYYY-MM-DD')
  ]);

  const routesNames = [`/getSearchResults/`];

  const getDatas = useCallback(async () => {
    try {
      for (let index = 0; index < routesNames.length; index += 1) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_direction${routesNames[index]}`
        });
        if (index === 0) setSearchResults(data);
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const SampleContainerSearch = styled.div`
    flex: 1.02;
    @media screen and (max-width: 1500px) {
      flex: none;
    }
  `;

  const getStats = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/stats/stat?ref_partner=${
          user.email
        }&title=${store}&start_date=${moment(dateRange[0]).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`
      });
      setFilterDataStats(data);
      setLoading(false);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  const rationMonth = (lastMonth, previousMonth) => {
    let perct = 0;
    if (previousMonth > 0) {
      perct = ((lastMonth / previousMonth) * 100).toFixed(2);
    }
    return Number(perct);
  };

  const getStatsTab = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/stats/tab?ref_partner=${
          user._id
        }&title=${store}&start_date=${moment(dateRange[0]).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`
      });
      setFilterDataTab(data);
      const resil = [];
      data.map((item) => {
        resil.push({
          name: item.name,
          value: rationMonth(item.terminated, item.lastMonth)
        });
        return null;
      });
      setDatas_resiliated(resil);
      setLoading(false);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  const getStores = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/stores/?ref_partner=${user._id}`
      });
      setPartners(data);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  const applyFilter = useCallback(async () => {
    await getStats();
    await getStatsTab();
  }, [store, dateRange]);

  useEffect(() => {
    (async () => {
      await getStores();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await applyFilter();
    })();
  }, [applyFilter]);

  const handleChangeStore = (selectedStore) => {
    setStore(selectedStore);
  };

  return (
    <>
      {
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto'
          }}
        >
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, delay: 0.05 }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchContainer className="Search_container">
                <SearchDatas
                  searchResults={searchResults}
                  placeholder="Rechercher des clients, des contrats, des vendeurs, des partenaires, etc..."
                />
              </SearchContainer>
              <SampleContainerSearch />
            </div>
          </motion.div>

          <Row justify="space-evenly" style={{ width: '100%' }}>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <Col span={24}>
                  <DashboardLineGraph
                    partners={partners}
                    filterData={filterData}
                    dateRange={dateRange}
                    handleChangeStore={handleChangeStore}
                    setDateRange={setDateRange}
                  />
                </Col>
                <Col span={24}>
                  <div style={{ width: '100%' }}>
                    {filterDataTab && <ListStores data={filterDataTab} />}
                  </div>
                </Col>
                <Col span={12}>
                  {datas_resiliated && (
                    <DashboardPieGraph filterData={datas_resiliated} />
                  )}
                </Col>
                <Col span={12} style={{ padding: 16 }}>
                  {filterDataTab && <ListTerminate data={filterDataTab} />}
                </Col>
              </>
            )}
          </Row>
        </div>
      }
    </>
  );
};

export default Home_advisor;
