import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { message, Row, Col, Select, Skeleton, DatePicker, Space } from 'antd';
import { motion } from 'framer-motion';
import moment from 'moment';
import { styles } from '../styles_home/home_seller';
import '../../../assets/styles/SCSS/tableHome.scss';
import useAuthContext from '../../../contexts/AuthContext';
import SearchDatas from '../components_home/SearchDatas';
import {
  Major_graph_container as MajorGraphContainer,
  LineGraph,
  SearchContainer,
  DonutChart as DonutChartStyled,
  Graph_containerAdvisor as GraphContainerAdvisor,
  Graph_containerAdvisorB as GraphContainerAdvisorB
} from '../components_home/ExtraComponents';
import ListStores from '../components_home/ListStores';
import ListTerminate from '../components_home/ListTerminate';
import ChartBiz from '../../../components/Charts/Chart';
import TerminatedChart from '../charts_folder/TerminatedChart';
import { rationMonth } from '../../../utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';

const SampleContainerSearch = styled.div`
  flex: 1.02;
  @media screen and (max-width: 1500px) {
    flex: none;
  }
`;

const DashboardLineGraph = ({
  partners,
  filterData,
  dateRange,
  handleChangePartner,
  partner,
  setDateRange
}) => {
  const handleChangeCalendar = (val, info) => {
    if (info.range === 'end') {
      setDateRange(val);
    }
  };

  return (
    <MajorGraphContainer style={{ width: '100%' }}>
      <LineGraph>
        <GraphContainerAdvisor>
          <div style={styles.graph_header}>
            <p style={styles.graph_header_first}>Contrats</p>
            <div>
              {'Partenaire : '}
              <Select
                value={partner}
                style={{ width: 200 }}
                onChange={handleChangePartner}
              >
                <Option value="all">Tous</Option>
                {partners?.map((p) => (
                  <Option value={p._id} key={p._id}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            </div>
            <Space direction="vertical" size={12}>
              <RangePicker
                value={[
                  moment(dateRange[0], dateFormat),
                  moment(dateRange[1], dateFormat)
                ]}
                onCalendarChange={(val, _, info) =>
                  handleChangeCalendar(val, info)
                }
                format="DD/MM/YYYY"
                allowClear={false}
              />
            </Space>
          </div>
          <div style={styles.app_divider_container_line} />
          <div style={{ cursor: 'pointer', marginTop: '30px' }}>
            <ChartBiz contractStats={filterData} />
          </div>
        </GraphContainerAdvisor>
      </LineGraph>
    </MajorGraphContainer>
  );
};

DashboardLineGraph.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChangePartner: PropTypes.func.isRequired,
  partner: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired
};

const DashboardPieGraph = ({ filterData }) => {
  return (
    <MajorGraphContainer style={{ width: '100%', height: '100%' }}>
      <DonutChartStyled style={{ width: '100%', height: '100%' }}>
        <GraphContainerAdvisorB>
          <div style={styles.graph_header}>
            <p style={styles.graph_header_first}>Taux de résiliation</p>
            <p style={styles.graph_header_second} />
          </div>
          <div style={styles.app_divider_container_donut} />
          <div style={{ cursor: 'pointer', marginTop: '20px' }}>
            <TerminatedChart terminated={filterData} />
          </div>
        </GraphContainerAdvisorB>
      </DonutChartStyled>
    </MajorGraphContainer>
  );
};

DashboardPieGraph.propTypes = {
  filterData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const Home_advisor = () => {
  const [searchResults, setSearchResults] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [datas_resiliated, setDatas_resiliated] = useState([]);
  const [filterData, setFilterDataStats] = useState([]);
  const [filterDataTab, setFilterDataTab] = useState([]);
  const [partner, setPartner] = useState('all');
  const [partners, setPartners] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().format('YYYY-MM'),
    moment().format('YYYY-MM-DD')
  ]);

  const getDatas = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/home_direction/getSearchResults`
      });
      setSearchResults(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  }, []);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const getStats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/stats/stat?ref_partner=${partner}&start_date=${moment(
          dateRange[0]
        ).format('YYYY-MM-DD')}&end_date=${moment(dateRange[1]).format(
          'YYYY-MM-DD'
        )}`
      });
      setFilterDataStats(data);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  const getStatsTab = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/stats/tab?ref_partner=${partner}&start_date=${moment(
          dateRange[0]
        ).format('YYYY-MM-DD')}&end_date=${moment(dateRange[1]).format(
          'YYYY-MM-DD'
        )}`
      });
      setFilterDataTab(data);
      setDatas_resiliated(
        data.map((item) => ({
          name: item.name,
          value: rationMonth(item.terminated, item.lastMonth)
        }))
      );
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getPartners = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/partners`
      });
      setPartners(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const applyFilter = useCallback(async () => {
    setIsLoading(true);
    await getStats();
    await getStatsTab();
    setIsLoading(false);
  }, [partner, dateRange]);

  useEffect(() => {
    (async () => {
      await getPartners();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await applyFilter();
    })();
  }, [applyFilter]);

  const handleChangePartner = (value) => {
    setPartner(value);
  };

  return (
    <div
      style={{
        padding: '20px',
        marginTop: '0rem',
        paddingTop: '2rem',
        maxWidth: '1600px',
        margin: 'auto'
      }}
    >
      <motion.div
        animate={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delay: 0.05 }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchContainer className="Search_container">
            <SearchDatas
              searchResults={searchResults}
              placeholder="Rechercher des clients, des contrats, des vendeurs, des partenaires, etc..."
            />
          </SearchContainer>
          <SampleContainerSearch />
        </div>
      </motion.div>
      <Row justify="space-evenly" style={{ width: '100%' }}>
        <Skeleton active loading={isLoading}>
          <Col span={24}>
            <DashboardLineGraph
              partners={partners}
              filterData={filterData}
              dateRange={dateRange}
              handleChangePartner={handleChangePartner}
              partner={partner}
              setDateRange={setDateRange}
            />
          </Col>
          <Col span={24}>
            <div style={{ width: '100%' }}>
              {filterDataTab && <ListStores data={filterDataTab} />}
            </div>
          </Col>
          <Col span={12}>
            {datas_resiliated && (
              <DashboardPieGraph filterData={datas_resiliated} />
            )}
          </Col>
          <Col span={12} style={{ padding: 16 }}>
            {filterDataTab && <ListTerminate data={filterDataTab} />}
          </Col>
        </Skeleton>
      </Row>
    </div>
  );
};

export default Home_advisor;
