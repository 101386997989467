import React from 'react';
import PropTypes from 'prop-types';
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip as Tooltip_chart,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from 'bizcharts';
import EmptyChart from '../components_home/EmptyChart';

const Donut_chart = ({
  data,
  xAxis,
  yAxis,
  cols,
  has_legend,
  from_manager,
  sectorType
}) => {
  const axis = `${xAxis}*${yAxis}`;
  let colors = ['#ff8b3d', '#efb055'];
  let max = 0;
  if (sectorType !== 'default') {
    data.forEach(function(obj) {
      if (obj.value > max) {
        max = obj.value;
      }
    });
    G2.Shape.registerShape('interval', 'sliceShape', {
      draw(cfg, container) {
        const { points } = cfg;
        const origin = cfg.origin._origin;
        const percent = origin.value / max;
        const xWidth = points[2].x - points[1].x;
        const width = xWidth * percent;
        let path = [];
        path.push(['M', points[0].x, points[0].y]);
        path.push(['L', points[1].x, points[1].y]);
        path.push(['L', points[0].x + width, points[2].y]);
        path.push(['L', points[0].x + width, points[3].y]);
        path.push('Z');
        path = this.parsePath(path);
        return container.addShape('path', {
          attrs: {
            fill: cfg.color,
            path
          }
        });
      }
    });
    colors = ['#ff8b3d', '#ffc069', '#efb055', '#ce6c2b'];
  }
  return (
    <>
      {!data.length || data[0].count === null ? (
        <EmptyChart height={307} />
      ) : (
        <div>
          <Chart
            data={data}
            scale={cols}
            height={300}
            width={400}
            padding={!has_legend ? ['auto', 'auto', 'auto', 100] : 'auto'}
            forceFit
          >
            <Coord type="theta" radius={0.65} />
            <Axis name="percent" />
            {has_legend && (
              <Legend
                position="bottom"
                offsetY={!from_manager ? -20 : -30}
                offsetX={0}
              />
            )}
            <Tooltip_chart
              showTitle={false}
              itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
            />
            <Geom
              type="intervalStack"
              position={yAxis}
              color={[xAxis, colors]}
              tooltip={
                sectorType === 'default'
                  ? [
                      axis,
                      (item, percent) => {
                        percent = `${percent * 100}%`;
                        return {
                          name: item,
                          value: percent
                        };
                      }
                    ]
                  : [
                      axis,
                      (item, percent) => {
                        percent = `${percent} €`;
                        return {
                          name: item,
                          value: percent
                        };
                      }
                    ]
              }
              style={{
                lineWidth: 1,
                stroke: '#fff'
              }}
              shape={sectorType}
            >
              <Label
                content={yAxis}
                formatter={(val, item) => {
                  const finalVal = sectorType === 'default' ? val : `${val} €`;
                  return `${item.point[xAxis]}: ${finalVal}`;
                }}
              />
            </Geom>
          </Chart>
        </div>
      )}
    </>
  );
};

Donut_chart.propTypes = {
  data: PropTypes.array,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  cols: PropTypes.object,
  has_legend: PropTypes.bool,
  from_manager: PropTypes.bool,
  sectorType: PropTypes.string
};

Donut_chart.defaultProps = {
  data: null,
  xAxis: null,
  yAxis: null,
  cols: null,
  has_legend: true,
  from_manager: false,
  sectorType: 'default'
};

export { Donut_chart };
