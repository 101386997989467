import React from 'react';
import {
  ContactsOutlined,
  EuroOutlined,
  FileTextOutlined,
  StockOutlined,
  TagsOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  UserIcon,
  ContractSettingsIcon,
  CustomerIcon,
  ContractIcon,
  BenefitsIcon,
  DemoIcon,
  DocumentIcon,
  DashboardIcon,
  RequestIcon,
  AdvisorIcon,
  StoreIcon,
  PartnerIcon,
  SellerIcon,
  SupervisorIcon
} from './customIcon';

const navMenuLogos = {
  HOME: <DashboardIcon />,
  USERS: <UserIcon />,
  'CONTRACTS-SETTINGS': <ContractSettingsIcon />,
  CONTRACTS: <ContractIcon />,
  INVOICES: <EuroOutlined style={{ fontSize: 23 }} />,
  ACCOUNTING: <EuroOutlined />,
  COMMERCIAL: <ContactsOutlined />,
  QUOTES: <FileTextOutlined />,
  CUSTOMERS: <CustomerIcon />,
  HUMANRESOURCE: <UserOutlined />,
  PRODUCTION: <StockOutlined />,
  TICKETS: <TagsOutlined />,
  BENEFITS: <BenefitsIcon />,
  DOCUMENTS: <DocumentIcon />,
  SELLERS: <SellerIcon />,
  REQUESTS: <RequestIcon />,
  ADVISORS: <AdvisorIcon />,
  STORES: <StoreIcon />,
  PARTNER: <PartnerIcon />,
  'MANAGER-ADVISORS': <SupervisorIcon />,
  DIRECTIONS: <DemoIcon />
};

export default navMenuLogos;
