import { createGlobalStyle } from 'styled-components';

const SFRTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #d0021b;
    --addColor: #47d267;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover: #fffafb;
    --secondaryColor: #99007f;
    --disabledColor: #bfbfbf;
    --bodyBackground: #f0f2f5;
    --textColor: #444;
    --textColorSecondary: rgba(0, 0, 0, 0.45);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #eee;
    --borderColor: #d9d9d9;
    --contrastBackground: #dfe1e6;
    --boxShadowColor: rgba(68, 68, 68, 0.3);
    --opacitySVG: 1;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --errorColor
    --error100
    --errorA08
  }
`;

export default SFRTheme;
