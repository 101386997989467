import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState();
  const [contacts, setContacts] = useState();

  const fields = [
    {
      name: ['file'],
      rules: [
        {
          required: true
        }
      ],
      input: (
        <Select loading={isLoading}>
          {files &&
            files.map(f => (
              <Option key={f._id} value={f._id}>
                {f.metadata.originalName}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['contact'],
      rules: [
        {
          required: true
        }
      ],
      input: (
        <Select loading={isLoading}>
          {contacts &&
            contacts.map(c => (
              <Option
                key={c._id}
                value={c._id}
              >{`${c.first_name} ${c.last_name}`}</Option>
            ))}
        </Select>
      )
    }
  ];

  const getFiles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/files' });
      setFiles(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts' });
      setContacts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getFiles();
    await getContacts();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    fields
  };
};

export default useFields;
