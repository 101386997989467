import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const MajorContainer = styled.div`
  padding: 2rem;
  background: white;
`;

const styles = {
  graph_header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  graph_header_first: {
    fontSize: '1rem',
    fontWeight: 500
  },
  graph_header_second: {
    color: 'gray'
  },
  app_divider_container_benefit: {
    width: '50px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_comment: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_timeline: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  }
};

export { styles, MajorContainer };
