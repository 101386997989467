const listContent = profile => {
  const fields = [];
  Object.keys(profile).forEach(key => {
    fields.push({
      label: `profiles.personalInfo.${key}`,
      content: profile[key],
      span: 3
    });
  });

  return fields;
};

export default listContent;
