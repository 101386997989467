import React, { useEffect, useState } from 'react';
import { Select, Input, Form } from 'antd';
import { useParams } from 'react-router';
import countryListMap from 'country-flags-dial-code';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import phoneField from '../../utils/formFields/phoneField';
import emailField from '../../utils/formFields/emailField';

const { Option } = Select;

const useFields = (purpose) => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [sellers, setSellers] = useState([]);
  const [defaultCode, setDefaultCode] = useState('+33');
  const [partners, setPartners] = useState([]);
  const [stores, setStores] = useState([]);
  const [mails, setMails] = useState([]);
  const [partner, setPartner] = useState();
  const { id } = useParams();
  const [currentSeller, setCurrentSeller] = useState();
  const countries_code = countryListMap.getCountryListMap();
  let code_array = [];
  Object.keys(countries_code).forEach((key) => {
    code_array.push(countries_code[key].dialCode);
  });
  const compare_code = (a, b) => {
    const a_num = parseInt(a.substring(1), 10);
    const b_num = parseInt(b.substring(1), 10);
    return a_num - b_num;
  };
  code_array.sort(compare_code);
  code_array = [...new Set(code_array)];

  const getSeller = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sellers/${id}`
      });
      setCurrentSeller(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPartners = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/partners`
      });
      setPartners(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getStores = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/stores?populate=ref_partner
        `
      });
      setStores(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getSeller();
      })();
    }
  }, []);

  const getSellers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sellers`
      });
      setSellers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getMails = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users/mails/' });
    setMails(data);
  };

  useEffect(() => {
    (async () => {
      await getSellers();
      await getMails();
      await getPartners();
      await getStores();
    })();
  }, []);

  const fields =
    user.role === 'admins:DIRECTION'
      ? [
          {
            name: ['ref_partner'],
            input: (
              <Select
                onChange={(e) => setPartner(e)}
                options={partners?.map((partner) => ({
                  value: `${partner._id}`,
                  label: `${partner.name}`
                }))}
              />
            ),
            rules: [{ required: true }]
          },
          {
            name: ['ref_manager'],
            input: (
              <Select
                options={stores.map((store) => ({
                  value: `${store._id}-${store.title}`,
                  label: `${store.title}`
                }))}
              />
            ),
            rules: [{ required: true }]
          },
          {
            name: ['first_name'],
            rules: [{ required: true }]
          },
          {
            label: 'last_name',
            name: ['last_name'],
            rules: [{ required: true }]
          },
          emailField(
            [...sellers, ...mails],
            currentSeller?.email,
            purpose,
            true
          ),
          phoneField(true)
        ]
      : [
          {
            name: ['first_name'],
            rules: [{ required: true }]
          },
          {
            label: 'last_name',
            name: ['last_name'],
            rules: [{ required: true }]
          },
          emailField(
            [...sellers, ...mails],
            currentSeller?.email,
            purpose,
            true
          ),
          phoneField(true)
        ];

  return {
    fields
  };
};

export default useFields;
