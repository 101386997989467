import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = () => {
  const { t } = useTranslation();
  const { checkShouldDisplayListItem } = useAuthContext();
  const columns = [
    {
      key: 'ref',
      title: t('contract-types.form.ref'),
      dataIndex: 'ref',
      sorter: true
    },
    {
      key: 'name',
      title: t('contract-types.form.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      key: 'price',
      title: t('contract-types.form.price'),
      dataIndex: 'price',
      sorter: true
    },
    {
      key: 'commission',
      title: t('contracts.form.commission'),
      dataIndex: 'commission',
      sorter: true
    }
  ];
  return columns.filter(column =>
    checkShouldDisplayListItem('contract-types', column.key)
  );
};

export default useColumns;
