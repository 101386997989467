import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { PageHeaderCustom, DescriptionList } from '../../components';
import { routes } from '../../utils/constants/routes';
import ImportButton from '../../components/ImportButton/ImportButton';
import showDirectionContent from './listContent';
import { MajorContainer } from './styles';

const ShowDirection = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [direction, setDirection] = useState(null);
  const authContext = useAuthContext();

  const getDirection = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/directions/${id}`
      });
      setDirection(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getDirection();
    })();
  }, [getDirection]);

  const deleteDirection = async () => {
    try {
      await dispatchAPI('DELETE', { url: `${routes.DIRECTIONS}/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('directions.show.title')}
        extra={
          <>
            {authContext.checkShouldDisplayActionItem(
              'directions',
              'delete'
            ) && (
              <Link
                to={{
                  pathname: `${routes.DIRECTIONS}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {authContext.checkShouldDisplayActionItem(
              'directions',
              'delete'
            ) && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteDirection}
                icon={<WarningOutlined />}
              >
                <Button type="primary">
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <MajorContainer>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={showDirectionContent(direction || {})}
            translate
          />
        </Skeleton>
      </MajorContainer>
    </>
  );
};

export default ShowDirection;
