import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Radio, Space } from 'antd';

const ModalChangeStatus = ({
  open,
  setOpen,
  disable,
  setStatus,
  status,
  changeContractStatus
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => {
        /* !disable && */ setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Changer le statut du contrat</DialogTitle>
      <DialogContent>
        <Radio.Group
          defaultValue={status}
          onChange={e => setStatus(e.target.value)}
        >
          <Space direction="vertical">
            <Radio value={'SIGNED'}>{`${t('contracts.form.SIGNED')}`}</Radio>
            <Radio value={'PENDING'}>{`${t('contracts.form.PENDING')}`}</Radio>
            <Radio value={'TERMINATE'}>{`${t(
              'contracts.form.RESILIATED'
            )}`}</Radio>
          </Space>
        </Radio.Group>
      </DialogContent>
      <DialogActions>
        <Button
          className="sendButton"
          disabled={disable}
          onClick={() => {
            setOpen(false);
          }}
        >
          {`${t('buttons.cancel')} `}
        </Button>
        <Button
          type="primary"
          disabled={disable}
          onClick={changeContractStatus}
        >
          {t('buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalChangeStatus;
