import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tag } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user, checkShouldDisplayListItem } = useAuthContext();
  const [benefits, setBenefits] = useState([]);

  const statusPaiment = ['multiple_refused', 'one_refused', 'no_refused'];
  const TagColor = [
    'magenta',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'yellow',
    '#d9d9d9',
    'purple'
  ];

  useEffect(() => {
    const getData = async () => {
      const { data } = await dispatchAPI('GET', {
        url: `/home_advisor/getBenefitList/`
      });
      setBenefits(
        data
          .map((benefit) => ({
            title: benefit.title,
            date:
              benefit.eligibility && benefit.eligibility !== '-'
                ? parseInt(benefit.eligibility.split('///')[0], 10)
                : 0,
            key: benefit.key.split('///')[0]
          }))
          .sort((a, b) => a.date - b.date)
      );
    };
    getData();
  }, [user]);

  moment.locale('fr', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: 'seconde',
      ss: '%ss',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'une année',
      yy: '%d ans'
    }
  });

  let columns = [
    {
      key: 'type',
      title: t('contracts.form.type'),
      dataIndex: 'type'
    },
    {
      key: 'ref',
      title: t('contracts.form.ref'),
      dataIndex: 'ref',
      sorter: true
    },
    {
      key: 'customer',
      title: t('contracts.form.customer'),
      dataIndex: 'customer',
      render: (customer) =>
        customer && `${customer.first_name} ${customer.last_name}`,
      sorter: true
    },
    {
      key: 'ref_seller',
      title: t('contracts.form.ref_seller'),
      dataIndex: 'ref_seller',
      render: (ref_seller) =>
        ref_seller && `${ref_seller.first_name} ${ref_seller.last_name}`,
      sorter: true
    },
    {
      key: 'payment_status',
      title: t('contracts.form.payment_status'),
      dataIndex: 'nb_rejected_payments',
      render: (nb_rejected_payments) =>
        (nb_rejected_payments === 1 && (
          <Tag style={{ marginBottom: '5px' }} color="orange">
            {t('contracts.list.payment_status.one_refused')}
          </Tag>
        )) ||
        (nb_rejected_payments > 1 && (
          <Tag style={{ marginBottom: '5px' }} color="red">
            {t('contracts.list.payment_status.multiple_refused')}
          </Tag>
        )) || (
          <Tag style={{ marginBottom: '5px' }} color="green">
            {t('contracts.list.payment_status.no_refused')}
          </Tag>
        ),
      filters:
        statusPaiment &&
        statusPaiment.length &&
        statusPaiment.map((status) => ({
          text: (
            <Tag style={{ marginBottom: '5px' }}>
              {t(`contracts.list.payment_status.${status}`)}
            </Tag>
          ),
          value: status
        })),
      sorter: true
    },
    {
      key: 'begin_date',
      title: t('contracts.form.begin_date'),
      dataIndex: 'begin_date',
      sorter: true,
      render: (d) => d && moment(d).format('DD/MM/YYYY HH:mm')
    },
    {
      key: 'terminated_date'
    },
    {
      key: 'contract_status',
      title: 'Status',
      dataIndex: ['contract_status'],
      sorter: true,
      filters: [
        {
          text: t('contracts.form.SIGNED'),
          value: 'SIGNED'
        },
        {
          text: t('contracts.form.PENDING'),
          value: 'INPROGRESS'
        },
        {
          text: t('contracts.form.RESILIATED'),
          value: 'TERMINATE'
        }
      ],
      render: (_, row) => {
        return (
          (row.contract_status === 'TERMINATE' &&
            t('contracts.form.RESILIATED')) ||
          (row.contract_status === 'INPROGRESS' &&
            row.signed === 'PENDING' &&
            t('contracts.form.PENDING')) ||
          (row.contract_status === 'INPROGRESS' &&
            row.signed === 'SIGNED' &&
            t('contracts.form.SIGNED'))
        );
      }
    },
    {
      title: 'Archivé',
      key: 'isActive',
      dataIndex: 'isActive',
      render: (tag) => (
        <span>
          {
            <Tag color={tag === false ? 'volcano' : 'green'} key={tag}>
              {tag === false ? 'Archivé' : 'Non archivé'}
            </Tag>
          }
        </span>
      )
    },
    {
      key: 'eligibility',
      title: t('contracts.form.eligibility'),
      dataIndex: ['eligibility'],
      sorter: true,
      filters:
        benefits &&
        benefits.length > 0 &&
        benefits.map((benefit, index) => ({
          text: (
            <Tag
              key={benefit.key}
              color={TagColor[index]}
              style={{ marginBottom: '5px' }}
            >
              {benefit.title}
            </Tag>
          ),
          value: benefit.key
        })),
      render: (eligibileContracts) => {
        return (
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              maxWidth: '300px'
            }}
          >
            {(eligibileContracts &&
              eligibileContracts.length > 0 &&
              eligibileContracts.map((benefit, index) => (
                <Tag color={TagColor[index]} style={{ marginBottom: '5px' }}>
                  {benefit.title}
                </Tag>
              ))) || (
              <Tag style={{ marginBottom: '5px' }}>
                {t('contracts.tags.NO-BENEFITS')}
              </Tag>
            )}
          </div>
        );
      }
    },
    {
      key: 'customer.benefits.title_benefit',
      title: t('contracts.form.request_benefit'),
      dataIndex: ['customer', 'benefits'],
      sorter: true,
      filters:
        benefits &&
        benefits.length > 0 &&
        benefits.map((benefit, index) => ({
          text: (
            <Tag
              key={benefit.key}
              color={TagColor[index]}
              style={{ marginBottom: '5px' }}
            >
              {benefit.title}
            </Tag>
          ),
          value: benefit.title
        })),
      render: (benefitList) => {
        return (
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              maxWidth: '300px'
            }}
          >
            {benefitList && benefitList.length > 0 ? (
              benefitList.map((benefit, index) => {
                return (
                  <>
                    {benefit.status_activation === 'WAITING' && (
                      <Tag
                        color={TagColor[index]}
                        style={{ marginBottom: '5px' }}
                      >
                        {benefit.title_benefit}
                      </Tag>
                    )}
                  </>
                );
              })
            ) : (
              <Tag color="green" style={{ marginBottom: '5px' }}>
                Aucune requête en cours
              </Tag>
            )}
          </div>
        );
      }
    }
  ];
  columns = columns.filter((column) =>
    checkShouldDisplayListItem('contracts', column.key)
  );

  if (
    user.role === 'users:TELECONSEILLER' ||
    user.role === 'admins:DIRECTION' ||
    user.role === 'users:users:MANAGER-ADVISOR'
  ) {
    for (let i = 0; i < columns.length; i += 1)
      if (columns[i].key === 'terminated_date')
        columns[i] = {
          key: 'terminated_date',
          title: t('contracts.form.terminated_date'),
          dataIndex: 'terminated_date',
          sorter: true,
          render: (d) => d && moment(d).format('DD/MM/YYYY HH:mm')
        };
  }

  return columns;
};

export default useColumns;
