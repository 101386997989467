import React from 'react';
import { Column } from '@ant-design/plots';

const Chart = ({ contractStats }) => {
  const config = {
    data: contractStats ||[],
    isStack: true,
    xField: 'name',
    yField: 'nbContracts',
    seriesField: 'type',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    },
    scrollbar: {
      type: 'horizontal',
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: 'rgba(0,0,0,0.25)',
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
  };

  return !!contractStats.length && <Column {...config} />;
};

export default Chart;
