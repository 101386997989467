import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/plots';
import { Empty, message, Spin } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import { codeToReason } from '../../utils';

export const CallsChart = ({ range }) => {
  const { dispatchAPI } = useAuthContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: d } = await dispatchAPI('GET', {
        url: `/manager-advisors/calls-stats-manager?start=${range[0]}&end=${range[1]}`
      });
      setData(d.map((elem) => ({ ...elem, type: codeToReason[elem.type] })));
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
    setIsLoading(false);
  }, [range]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const config = {
    data,
    isStack: true,
    xField: 'name',
    yField: 'calls',
    seriesField: 'type',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    },
    scrollbar: {
      type: 'horizontal'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false
      }
    ]
  };

  return (
    <Spin spinning={isLoading}>
      {!isLoading && data.length ? <Column {...config} /> : <Empty />}
    </Spin>
  );
};

CallsChart.propTypes = {
  range: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
