import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { companiesTypes } from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  username,
  first_name,
  last_name,
  emails,
  phone_numbers
}) => {
  const { t } = useTranslation();

  return [
    {
      label: 'contacts.form.username',
      content: `${username}`,
      span: 3
    },
    {
      label: 'contacts.form.full_name',
      content: `${first_name} ${last_name}`,
      span: 3
    },
    {
      label: 'contacts.form.emails',
      content: emails,
      span: 3
    },
    {
      label: 'contacts.form.phone_numbers',
      content: phone_numbers,
      span: 3
    }
  ];
};

export default useListContent;
