import React, { useCallback, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  commissionStatuses,
  commissionTypes
} from '../../utils/constants/tagColors';

const useCustomerList = ({ type, status, created_at, contract, partner }) => {
  const { t } = useTranslation();
  const [partnerName, setPartner] = useState('-');
  const [contractName, setContract] = useState('-');
  const [typePrice, setType] = useState();
  const [date, setDate] = useState();

  useEffect(() => {
    if (partner) {
      setPartner(partner.name);
    }
    if (contract) {
      setContract(contract.ref);
    }
    if (type) {
      setType(type.commission);
    }
    if (created_at) {
      setDate(created_at);
    }
  }, [contract, partner, type]);

  return [
    {
      label: 'commissions.form.price',
      span: 3,
      content: type ? `${typePrice} €` : '-'
    },
    {
      label: 'commissions.form.partnerName',
      span: 3,
      content: partnerName
    },
    {
      label: 'commissions.form.status',
      span: 3,
      content: status ? (
        <Tag color={commissionStatuses[status]}>
          {t(`commissions.tags.${status}`)}
        </Tag>
      ) : (
        '-'
      )
    },
    {
      label: 'commissions.form.contract',
      span: 3,
      content: contractName
    },
    {
      label: 'commissions.form.contractCreationDate',
      span: 3,
      content: date ? moment(date).format('DD/MM/YYYY') : '-'
    }
  ];
};

export default useCustomerList;
