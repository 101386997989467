import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  outOfNavRoutes,
  routes,
  subRoutes
} from '../../utils/constants/routes';
import CreateUpdateContractTypes from './ContractTypes/CreateUpdateContractType';
import DetailContractType from './ContractTypes/ShowContractType';
import ListContractTypes from './ContractTypes/ListContractTypes';
import ShowPartner from '../Partner/ShowPartner';
import CreateUpdatePartner from '../Partner/CreateUpdatePartner';
import ListPartners from '../Partner/ListPartners';
import ShowContact from '../Partner/Contacts/ShowContact';
import ShowCommission from '../Commissions/ShowCommission';
import ListCommission from '../Commissions/ListCommissions';
import CreateUpdateCommission from '../Commissions/CreateUpdateCommission';

const ContractSettingsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].CONTRACT_TYPES}/create`}
        render={() => <CreateUpdateContractTypes purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].CONTRACT_TYPES}/edit/:id`}
        render={() => <CreateUpdateContractTypes purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].CONTRACT_TYPES}/show/:id`}
        component={DetailContractType}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].CONTRACT_TYPES}`}
        render={() => <ListContractTypes />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].PARTNER}/create`}
        render={() => <CreateUpdatePartner purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].PARTNER}/edit/:id`}
        render={() => <CreateUpdatePartner purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].PARTNER}/show/:id`}
        component={ShowPartner}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].PARTNER}${outOfNavRoutes.CONTACTS}/show/:id`}
        component={ShowContact}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].PARTNER}`}
        component={ListPartners}
      />

      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].COMMISSIONS}/create`}
        render={() => <CreateUpdateCommission purpose="create" />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].COMMISSIONS}/edit/:id`}
        render={() => <CreateUpdateCommission purpose="edit" />}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].COMMISSIONS}/show/:id`}
        component={ShowCommission}
      />
      <Route
        path={`${path}${subRoutes['CONTRACTS-SETTINGS'].COMMISSIONS}`}
        component={ListCommission}
      />
    </Switch>
  );
};

export default ContractSettingsRouter;
