import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { Button, message } from 'antd';
import {
  DownloadOutlined,
  FileDoneOutlined,
  CloseCircleOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';
import '../../assets/styles/SCSS/comment.scss';
import { useStateWithLocalStorage } from '../../utils';
import { useTranslation } from 'react-i18next';
import SignModal from './SignModal';

const Yousign_snippet = ({
  id,
  route_doc,
  label_button_initial_state,
  label_button_loading_state,
  contract_name,
  yousignClicked
}) => {
  const { dispatchAPI, dispatchCODE, dispatchDOC } = useAuthContext();
  const history = useHistory();
  const [id_user, setId_user] = useStateWithLocalStorage('id_user', false);
  const [loading_contract, setLoading_contract] = useState(false);
  const [loading_text, setLoading_text] = useState(label_button_initial_state);
  const [signManuel, setSignManuel] = useState(false);
  const [disable, setDisable] = useState(false);
  const sigPad = useRef({});

  const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;

  const sendContract = async (contract) => {
    const resp = await dispatchAPI('POST', {
      url: '/email_sender/manual',
      body: {
        ref: contract.data
      }
    });
  };

  const save = async () => {
    setDisable(true);
    const generate = await dispatchAPI('POST', {
      url: '/yousign/generate-manualy',
      body: {
        contract_name,
        sign: sigPad.current.toDataURL()
      }
    });
    setDisable(false);
    setSignManuel(false);
    await sendContract(generate);
    window.location.reload(true);
  };
  const make_user_sign = async (id) => {
    if (yousignClicked) {
      setSignManuel(true);
    } else {
      setLoading_contract(true);
      setLoading_text(label_button_loading_state);
      await dispatchAPI('PATCH', {
        url: `/contracts/${id}`,
        body: {
          yousignClicked: true
        }
      });
      const newTab = window.open();
      newTab.document.write(
        '<html><head></head><body>Please wait while we redirect you</body></html>'
      );
      newTab.document.close();
      const { data } = await dispatchAPI('POST', {
        url: '/yousign',
        body: {
          contract_name
        }
      });
      setLoading_contract(false);
      setLoading_text(label_button_initial_state);
      newTab.location = data.redirectUrl;
      newTab.focus();
      window.location.reload(true);
    }
  };

  /* HTML PART */
  // To sum up, whenever there's an id_user in the localStorage i.e the user is still on is way to sign it's contract:
  // Show the appropriate icon and button label
  return (
    <>
      {signManuel && yousignClicked && (
        <SignModal
          sigPad={sigPad}
          save={save}
          signManuel={signManuel}
          setSignManuel={setSignManuel}
          disable={disable}
        />
      )}

      {id_user ? (
        <Button
          type="primary"
          loading={loading_contract}
          className="sendButton"
        >
          {`${loading_text} `}
          <FileDoneOutlined />
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => make_user_sign(id)}
          loading={loading_contract}
          className="sendButton"
        >
          {`${loading_text} `}
          <FileDoneOutlined />
        </Button>
      )}
    </>
  );
};

Yousign_snippet.propTypes = {
  id: PropTypes.string,
  label_button_initial_state: PropTypes.string,
  label_button_loading_state: PropTypes.string,
  contract_name: PropTypes.string
};

Yousign_snippet.defaultProps = {
  id: null,
  label_button_initial_state: null,
  label_button_loading_state: null,
  contract_name: null
};

export default Yousign_snippet;
