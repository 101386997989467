import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Modal, Button, Select, Card, Divider, Tooltip, message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

export const SearchCustomerModal = ({ visible, onCancel, onOk }) => {
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers' });
      setCustomers(data);
    } catch (error) {
      message.error(t('contracts.message.error.get_customers'));
    }
  };

  const navigateToContractCreation = () => {
    history.push(`/contracts/create?from=dashboard&id=${selectedCustomer._id}`);
    onOk();
  };

  const navigateToCustomerCreation = () => {
    history.push('/customers/create?from=dashboard');
    onOk();
  };

  useEffect(() => {
    (async () => {
      await getCustomers();
    })();
  }, []);

  const onChange = (value) => {
    const customer = customers.find((c) => c._id === value);
    setSelectedCustomer(customer);
  };

  const onSearch = () => {
    setDisabled(false);
  };

  return (
    <Modal
      title={t('contracts.form.search_customer')}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>,
        <Tooltip title={t('contracts.tooltip.search_customer')}>
          <Button
            disabled={disabled}
            key="submit"
            type="primary"
            onClick={navigateToCustomerCreation}
          >
            {t('buttons.create_customer_&_contract')}
          </Button>
        </Tooltip>,
        <Tooltip title={t('contracts.tooltip.create_contract')}>
          <Button
            disabled={!selectedCustomer}
            key="submit"
            type="primary"
            onClick={navigateToContractCreation}
          >
            {t('buttons.create_contract')}
          </Button>
        </Tooltip>
      ]}
    >
      <Select
        showSearch
        allowClear
        style={{ width: '100%' }}
        placeholder={t('contracts.placeholder.write_customer_name')}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={customers.map((customer) => ({
          value: customer._id,
          label: `${customer.last_name} ${customer.first_name} - ${customer.email}`
        }))}
      />
      <Divider />
      {selectedCustomer && (
        <Card
          title={`${selectedCustomer.last_name} ${selectedCustomer.first_name}`}
          bordered={false}
          style={{ width: 300 }}
        >
          <p>
            {`${t('contracts.form.email')} : ${selectedCustomer?.email || ''}`}
          </p>
          <p>
            {`${t('contracts.form.phone')} : ${selectedCustomer?.phone_number ||
              ''}`}
          </p>
          <p>
            {`${t('contracts.form.address')} : ${
              selectedCustomer?.address?.number &&
              selectedCustomer?.address?.number !== '0'
                ? selectedCustomer?.address?.number
                : ''
            }
            ${selectedCustomer?.address?.street || ''}`}
          </p>
          <p>
            {`${t('contracts.form.postal_code')} : ${selectedCustomer?.address
              ?.postal_code || ''}`}
          </p>
          <p>
            {`${t('contracts.form.city')} : ${selectedCustomer?.address?.city ||
              ''}`}
          </p>
        </Card>
      )}
    </Modal>
  );
};

SearchCustomerModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
};

SearchCustomerModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  onOk: () => {}
};
