import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import {
  Tooltip,
  message,
  Tag,
  Avatar,
  Spin,
  Button,
  Popconfirm,
  Col,
  Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  SendOutlined,
  SolutionOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import { ProfileHeader } from './styles';
import './styles.css';
import CreateUpdateRequest from '../../Requests/CreateUpdateRequest';
import ResiliateModal from './ResiliateModal';
import ReceiveCall from './ReceiveCall';
import ModalChangeStatus from './ModalChangeStatus';
import useErrorMessage from '../../../utils/ErrorMessage';

const ContractDetails = ({ eligibilityBenefits }) => {
  const { dispatchAPI, user, checkShouldDisplayActionItem } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [contract, setContract] = useState({});
  const [partner, setPartner] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const output = [];
  const [outputStream, setOutputStream] = useState([]);
  const [name, setName] = useState();
  const [customer, setCustomer] = useState();
  const [callOptionState, setCallOptionState] = useState();
  const [seller, setSeller] = useState();
  const [status, setStatus] = useState('PENDING');
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);

  const [contractData, setContractData] = useState();
  const [receiveCall, setReceiveCall] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    const contractData = await dispatchAPI('GET', {
      url: `/contracts/${id}`
    });
    setContractData(contractData);
    const currentContract = await dispatchAPI('GET', {
      url: `/contracts/${id}?populate=ref_type_contract, partner, customer, customer.benefits`
    });

    const dataCustomer = await dispatchAPI('GET', {
      url: `/users/${currentContract.data.ref_seller}`
    });
    setCustomer(dataCustomer.data);
    const data_seller = await dispatchAPI('GET', {
      url: `sellers/mail/${dataCustomer.data.email}`
    });
    const res_Store = await dispatchAPI('GET', {
      url: `stores/mail/${dataCustomer.data.email}`
    });
    const availableBenefits = currentContract.data.customer.benefits;

    eligibilityBenefits.map((benefit) => {
      const outputStream = {};
      outputStream.title = benefit.title;
      outputStream.eligibility = benefit.eligibility;
      outputStream.begin_date = currentContract.data.begin_date;
      outputStream.description = benefit.description;
      outputStream.attr = currentContract.data.type.toUpperCase();
      outputStream.key = benefit.key;

      let getter = false;
      let date_posted;
      availableBenefits.map((grantee) => {
        if (
          grantee.title_benefit.toLowerCase() === benefit.title.toLowerCase()
        ) {
          if (grantee.status_activation === 'ACTIVATED') {
            getter = true;
            date_posted = grantee.updated_at;
          }
        }
      });
      if (getter === true) {
        outputStream.validation = 'ACCEPTED';
        outputStream.date_posted = date_posted;
      } else {
        outputStream.validation = 'REFUSED';
      }
      output.push(outputStream);
    });
    output.sort((a, b) => {
      return a.date - b.date;
    });
    setOutputStream(output);
    setPartner(
      data_seller?.data?.title || res_Store?.data[0]?.title || 'La direction'
    );
    setSeller(data_seller?.data);
    setContract(currentContract.data);
    setIsLoading(false);
  };
  const [reload, setReload] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [reload]);

  useEffect(() => {
    setName(`${customer?.first_name} ${customer?.last_name}`);
  }, [contract]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value_radio, setValue_radio] = useState(1);
  const [isSelected, setIsSelected] = useState(false);
  const [callValue, setCallValue] = useState();
  const [loaderElement, setLoaderElement] = useState();
  const [reasonTermination, setReasonTermination] = useState();
  let callOption;

  const showModal = () => {
    setReceiveCall(true);
    setIsModalVisible(true);
  };

  const terminateContract = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/contracts/terminateContract/`,
        body: {
          ref_contract: id,
          ref_advisor: user._id,
          reason: reasonTermination,
          advisorName: `${user.first_name} ${user.last_name}`
        }
      });
      message.success('La demande de résilisation a été bien transmise');
      setReload(!reload);
    } catch (e) {
      message.error(`${e}`);
    }
  };

  const handleOk = () => {
    terminateContract();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // Check le isSelected and save the action in the db
    setIsSelected(false);
    // Open the voting modal
    setIsModalVisible(false);
    setLoaderElement();
  };

  const downloadFile = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/${id}`
      });
      if (data.contentContract) {
        const a = document.createElement('a');
        a.href = data.contentContract;
        a.download = 'contract.pdf';
        a.click();
      } else {
        const response = await dispatchAPI('GET', {
          url: `/yousign/${id}`,
          responseType: 'blob'
        });
        const blob = new Blob([response.data], {
          type: 'application/pdf'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
      }
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  /* const reasonsCall = [
    { index: 1, value: "Attribution d'avantage" },
    { index: 2, value: 'Demande de résiliation' },
    { index: 3, value: "Demande d'informations" }
  ]; */
  /*   const validateCallChoice = async () => {
    let body;
    setIsSelected(true);
    reasonsCall.forEach((reason) => {
      if (reason.index === value_radio) {
        callOption = reason.index;
        setCallValue(reason.value);
        setCallOptionState(callOption);
      }
    });
    switch (callOption) {
      case 1:
        setLoaderElement(
          <CreateUpdateRequest
            purpose="create"
            setReload={setReload}
            reload={reload}
          />
        );
        break;
      case 2:
        setLoaderElement(
          <ResiliateModal
            validation_func={handleOk}
            setReasonTermination={setReasonTermination}
            id_contract={contract._id}
            ref_seller={contract.ref_seller}
            ref_customer={contract.customer._id}
          />
        );
        break;
      case 3:
  
        body = {
          ref_user: user._id,
          ref_contract: id,
          reason: 'info',
          message: '-'
        };
        await dispatchAPI('POST', { url: `/story/`, body });
        handleCancel();
        break;
      case 4:

      default:
  
        break;
    }
  }; */

  useEffect(() => {
    (async () => {
      const params = location.search.split('?')[1];
      const objectParams = new URLSearchParams(params);
      const outputKeys = [...objectParams.keys()];
      const outputValue = [...objectParams.values()];
      if (outputValue.length !== 0) {
        if (
          outputValue[0] === 'allocation_benefit' ||
          outputValue[0] === 'resiliation_completed'
        ) {
          let reasonStory;
          let body;
          if (outputValue[0] === 'allocation_benefit') {
            reasonStory = 'benefit';
          } else if (outputValue[0] === 'resiliation_completed') {
            reasonStory = 'resiliate';
          }
          if (outputValue[1]) {
            body = {
              ref_user: user._id,
              ref_contract: id,
              message: outputValue[1],
              reason: reasonStory
            };
          } else {
            body = {
              ref_user: user._id,
              ref_contract: id,
              reason: reasonStory,
              message: '-'
            };
          }
          await dispatchAPI('POST', { url: `/story/`, body });
          /* setLoaderElement(<RatingForm />); */
          if (callOptionState === 1) {
            message.success(
              "La demande d'avantage a été initialisée avec succès !"
            );
          } else if (callOptionState === 2) {
            message.success(
              'La demande de résiliation a été établie avec succès !'
            );
            await dispatchAPI('POST', {
              url: '/advisors/postCall/',
              body: {
                ref_contract: id,
                ref_advisor: user._id,
                reason: 1,
                type: 'TERMINATE'
              }
            });
          }
          handleCancel();
        }
      }
      if (outputValue[0] === 'resiliation_failed') {
        if (outputValue[1] === '3') {
          // The advisor convinced the user to not resiliate it's contract, rather to allocate one of it's benefits
          setCallValue("Attribution d'avantage");
          setCallOptionState(1);
          setValue_radio(1);
          setLoaderElement(<CreateUpdateRequest purpose="create" />);
        } else if (outputValue[1] === '4') {
          message.success("Le changement d'offre a été établi avec succès !");
          handleCancel();
        }
        await dispatchAPI('POST', {
          url: '/advisors/postCall/',
          body: {
            ref_contract: id,
            ref_advisor: user._id,
            reason: parseInt(outputValue[1], 10),
            type: 'TERMINATE'
          }
        });
      }
      if (outputKeys.length !== 0) {
        if (outputKeys[0] === 'rating_completed') {
          message.success('Le vote a bien été pris en compte !');
        }
        if (
          outputKeys[0] === 'rating_completed' ||
          outputKeys[0] === 'rating_not_completed'
        ) {
          if (callOptionState === 1) {
            message.success(
              "La demande d'avantage a été initialisée avec succès !"
            );
          } else if (callOptionState === 2) {
            message.success(
              'La demande de résiliation a été établie avec succès !'
            );
          }
          handleCancel();
        }
      }
      if (outputValue.length || outputKeys.length) {
        history.replace(`${id}`);
      }
    })();
  }, [location]);

  const sendContract = async () => {
    try {
      await dispatchAPI('GET', {
        url: `/email_sender/${id}`
      });
      message.success('Le contrat a bien été envoyé au client.');
    } catch (error) {
      message.error("Une erreur est survenue au moment de l'envoi du mail.");
    }
  };

  const changeContractStatus = async () => {
    switch (status) {
      case 'SIGNED':
        await dispatchAPI('PATCH', {
          url: `/contracts/${id}`,
          body: {
            signed: status,
            contract_status: 'INPROGRESS'
          }
        });
        setOpen(false);
        break;
      case 'PENDING':
        await dispatchAPI('PATCH', {
          url: `/contracts/${id}`,
          body: {
            signed: status,
            contract_status: 'INPROGRESS'
          }
        });
        setOpen(false);
        break;
      case 'TERMINATE':
        await dispatchAPI('PATCH', {
          url: `/contracts/${id}`,
          body: {
            contract_status: 'TERMINATE'
          }
        });
        setOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Spin tip="Veuillez patienter..." size="large" />
        </div>
      ) : (
        <div style={{ maxWidth: '1600px', margin: 'auto' }}>
          <ProfileHeader
            className={
              contract.type?.toUpperCase() === 'GOLD'
                ? 'gold-banner'
                : contract.type?.toUpperCase() === 'PLATINIUM' && 'plat-banner'
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row wrap',
                alignItems: 'center'
              }}
            >
              <Popconfirm
                title={
                  contract?.signed === 'SIGNED'
                    ? t('datatable.column.action.download.title')
                    : 'Contrat non signé'
                }
                okText={
                  contract?.signed === 'SIGNED'
                    ? t('datatable.column.action.download.ok')
                    : null
                }
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.download.cancel')}
                onConfirm={contract?.signed === 'SIGNED' ? downloadFile : null}
                icon={<WarningOutlined />}
              >
                <Avatar
                  size={100}
                  style={{
                    minWidth: 100,
                    cursor: 'pointer',
                    backgroundColor:
                      contract.type?.toUpperCase() === 'GOLD'
                        ? 'rgb(255 247 213)'
                        : contract.type?.toUpperCase() === 'PLATINIUM' &&
                          '#d9d9d9'
                  }}
                  icon={
                    <FilePdfOutlined
                      style={{
                        color:
                          contract.type?.toUpperCase() === 'GOLD'
                            ? 'rgb(250 166 63)'
                            : contract.type?.toUpperCase() === 'PLAT' &&
                              '#595959'
                      }}
                    />
                  }
                />
              </Popconfirm>

              <div style={{ marginLeft: '2.5rem', marginRight: '1.25rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: '1.25rem', marginBottom: '0rem' }}>
                    {contract && contract.ref}
                  </p>
                  <Tag
                    color={
                      contract.type?.toUpperCase() === 'GOLD' ? 'gold' : 'gray'
                    }
                    style={{ marginLeft: '0.5rem' }}
                  >
                    {contract && contract.type?.toUpperCase()}
                  </Tag>
                </div>
                <p style={{ marginBottom: '0rem', fontSize: '0.8rem' }}>
                  Date de début:
{' '}
                  <strong>
                    {contract &&
                      moment(contract.begin_date).format('Do MMMM YYYY')}
                  </strong>
                </p>
              </div>
              <div
                style={{
                  borderLeft: '1px solid #cacfd9',
                  paddingLeft: '1.25rem',
                  marginRight: '1.25rem'
                }}
              >
                <p style={{ marginBottom: '0rem' }}>
                  Etabli par: 
{' '}
<strong>{name}</strong>
                </p>
                <p style={{ marginBottom: '0rem' }}>
                  Chez: 
{' '}
<strong>{partner}</strong>
                </p>
                <p style={{ marginBottom: '0rem' }}>
                  Date de naissance:
{' '}
                  <strong>
                    {moment(contract.customer.dob).format('DD-MM-YYYY')}
                  </strong>
{' '}
                </p>
                <p style={{ marginBottom: '0rem' }}>
                  Adresse:
{' '}
                  <strong>
                    {`${
                      !contract.customer.address.street.match(/^\d/)
                        ? contract.customer.address.number
                        : ''
                    }  ${contract.customer.address.street} ${
                      contract.customer.address.postal_code
                    } ${contract.customer.address.city}`}
                  </strong>
                </p>
              </div>
              <div
                style={{
                  borderLeft: '1px solid #cacfd9',
                  paddingLeft: '1.25rem'
                }}
              >
                <p style={{ marginBottom: '0rem' }}>
                  Adresse email: 
{' '}
<strong>{contract.customer.email}</strong>
                </p>
                <p style={{ marginBottom: '0rem' }}>
                  Téléphone: 
{' '}
<strong>{contract.customer.phone_number}</strong>
                </p>
                {contract.customer.comment && (
                  <p style={{ marginBottom: '0rem' }}>
                    Commentaire : 
{' '}
<strong>{contract.customer.comment}</strong>
                  </p>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Tooltip title="Recevoir un appel" color="var(--primaryColor)">
                  <div className="Phone is-animating" onClick={showModal} />
                </Tooltip>
                {(user.role === 'admins:DIRECTION' ||
                  user.role === 'users:MANAGER-ADVISOR' ||
                  user.role === 'users:TELECONSEILLER') && (
                  <>
                    <Button style={{ background: 'var(--primaryColor)' }}>
                      <Link
                        to={{
                          pathname: `/customers/edit/${contractData.data.customer}`
                        }}
                      >
                        <EditOutlined style={{ fontSize: 18 }} />
                      </Link>
                    </Button>
                    <Button
                      style={{ background: 'var(--primaryColor)' }}
                      onClick={sendContract}
                    >
                      <SendOutlined style={{ fontSize: 18 }} />
                    </Button>
                  </>
                )}
              </div>
              {user.role === 'admins:DIRECTION' && (
                <Button
                  style={{ marginTop: '10px' }}
                  onClick={() => setOpen(true)}
                >
                  Changer Status
                </Button>
              )}
            </div>
          </ProfileHeader>
          <ReceiveCall
            eligibilityBenefits={eligibilityBenefits}
            receiveCall={receiveCall}
            setReceiveCall={setReceiveCall}
            store={seller}
          />
          <ModalChangeStatus
            open={open}
            disable={disable}
            setOpen={setOpen}
            status={status}
            setStatus={setStatus}
            changeContractStatus={changeContractStatus}
          />
        </div>
      )}
    </>
  );
};

ContractDetails.propTypes = {
  extra: PropTypes.element
};

ContractDetails.defaultProps = {
  datas_benefits: null
};

export default ContractDetails;
