import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useFields from '../Customers/fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const AddCustomer = ({ visible, close, setNewCustomer, newCustomer }) => {
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { fields, isFieldsLoading } = useFields();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const onFinish = async values => {
    setIsLoading(true);

    values.ref_seller = user._id;
    try {
      await dispatchAPI('POST', {
        url: `/customers`,
        body: values
      });
      close();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
    setNewCustomer(!newCustomer);
  };

  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible]);

  return (
    <Spin spinning={isLoading}>
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        onFinish={onFinish}
        form={form}
      >
        {fields
          .filter(field => !['project'].includes(field.name[0]))
          .map(field => generateFields('customers', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={close}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

AddCustomer.propTypes = {
  contactId: PropTypes.string,
  project: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

AddCustomer.defaultProps = {
  contactId: null
};

export default AddCustomer;
