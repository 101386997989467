import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ModalSendMails from './modalSendMails';
import '../../assets/styles/SCSS/comment.scss';

const Emails = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ModalSendMails
        templateId="25"
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Button
        type="primary"
        Primary
        onClick={() => setIsModalVisible(!isModalVisible)}
        className="sendButton"
      >
        {`${t('buttons.send')} `}
        <SendOutlined />
      </Button>
    </>
  );
};

export default Emails;
