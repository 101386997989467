import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';

const userList = (data, t) => {
  return [
    {
      label: 'benefits.form.type',
      span: 3,
      content: (data.ref_benefitType && data.ref_benefitType.attr) || '-'
    },
    {
      label: 'benefits.form.contrat',
      span: 3,
      content: (data.ref_contract && data.ref_contract.ref) || '-'
    },
    {
      label: 'benefits.form.offer_name',
      span: 3,
      content: (data.ref_benefitType && data.ref_benefitType.title) || '-'
    },
    {
      label: 'benefits.form.description_offer',
      span: 3,
      content: (data.ref_benefitType && data.ref_benefitType.description) || '-'
    },
    {
      label: 'benefits.form.client',
      span: 3,
      content: (data.customer && data.customer.last_name + ' ') || '-'
    },
    {
      label: 'benefits.form.comment',
      span: 3,
      content: data.comment || '-'
    }
  ];
};

export default userList;
