import React, { useEffect, useState } from 'react';
import { Col, Button, DatePicker, Divider, Modal, Switch } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayoutDirection';
import useAuthContext from '../../../contexts/AuthContext';
import CustomUploader from '../../../components/CustomUploader/CustomUploader';
import DebitsContracts from './DebitsContracts';

const { RangePicker } = DatePicker;

const ListContracts = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let dateRange = [];
  if (params.get('start') && params.get('start') !== 'Invalid date') {
    dateRange = [moment(params.get('start')), moment(params.get('end'))];
  }
  const columns = useColumns();
  const { dispatchAPI, checkShouldDisplayActionItem } = useAuthContext();
  const { t } = useTranslation();
  const [extraQuery, setExtraQuery] = useState(null);
  const [all, setAll] = useState(false);
  const [range, setRange] = useState(dateRange);

  // What data to export to csv file
  // header
  const headers = [
    {
      label: 'ref',
      key: 'ref'
    },
    {
      label: 'type',
      key: 'type'
    },
    {
      label: 'begin_date',
      key: 'begin_date'
    },
    {
      label: 'terminated_date',
      key: 'terminated_date'
    },
    {
      label: 'contract_status',
      key: 'contract_status'
    },
    {
      label: 'isActive',
      key: 'isActive'
    },
    {
      label: 'last_name',
      key: 'customer.last_name'
    },
    {
      label: 'first_name',
      key: 'customer.first_name'
    }
  ];

  useEffect(() => {
    const dateName = 'begin_date';
    if (range && range.length !== 0) {
      setExtraQuery(
        `&${dateName}>=${range[0]
          .startOf('day')
          .toISOString()}&${dateName}<=${range[1]
          .endOf('day')
          .toISOString()}&all=${all}`
      );
    } else {
      setExtraQuery(`&all=${all}`);
    }
  }, [range, all]);

  const generateXMLFile = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/debit-contracts/generate-global-xml`
      });
      if (response.data) {
        const blob = new Blob([response.data], {
          type: 'text/xml;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `SEPA_file.xml`;
        a.click();
        window.location.reload();
      }
    } catch (error) {
      Modal.warning({
        title: 'Aucune facture à prelevé',
        content: 'Le fichier de prélevements est déja généré'
      });
    }
  };

  const addExtraButtons = () => {
    const extraButtonArray = [];
    if (checkShouldDisplayActionItem('contracts', 'upload')) {
      extraButtonArray.push(
        <CustomUploader
          afterUploadRoute="/contracts/files/"
          expectedFileTypes=".xml"
          uploadText={t('contracts.list.upload')}
          hasFileList={false}
          margin={5}
        />
      );
    }
    if (checkShouldDisplayActionItem('contracts', 'download')) {
      extraButtonArray.push(
        <Button
          onClick={generateXMLFile}
          name={t('contracts.list.download')}
          icon={<DownloadOutlined />}
          style={{ 'margin-right': 5 }}
        >
          {t('contracts.list.download')}
        </Button>
      );
    }
    return extraButtonArray;
  };

  return (
    <>
      <SimpleResourceLandingLayout
        extraQuery={`fields=-contentContract${extraQuery}`}
        withSubRoutes
        resourceName="contracts"
        columns={columns}
        headers={headers}
        resourceModelName="Contract"
        extraButtons={addExtraButtons()}
        withUploadButton={false}
        withCreateButton={checkShouldDisplayActionItem('contracts', 'create')}
        editAction={checkShouldDisplayActionItem('contracts', 'edit')}
        deleteAction={checkShouldDisplayActionItem('contracts', 'delete')}
        worth_styling
      >
        <Col span={8}>
          <RangePicker
            value={range}
            onChange={(dates) => setRange(dates)}
            style={{ marginTop: 8 }}
          />
        </Col>
        <Col span={4} offset={12} style={{ right: 0 }}>
          {`Afficher les contrats archivés: `}
          <Switch
            style={{ right: 0 }}
            onChange={(checked) => setAll(checked)}
          />
        </Col>
      </SimpleResourceLandingLayout>
      {checkShouldDisplayActionItem('contracts', 'download') && (
        <>
          <Divider orientation="left" style={{ marginTop: 48 }}>
            Liste des prélevements
          </Divider>
          <div style={{ minHeight: 780, padding: 24 }}>
            <DebitsContracts />
          </div>
        </>
      )}
    </>
  );
};

export default ListContracts;
