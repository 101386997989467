import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import useHandleResize from '../../utils/HandleResize';
import NavMenu from './NavMenu';
import SiderBackground from '../../assets/images/Hand-and-phone-in-front-of-eiffel-tower_Ilias-Chebbi.jpg';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  margin-top: 70px;
  z-index: 10;
  box-shadow: 4px 0 6px var(--boxShadowColor);
  overflow: hidden;
  position: fixed;
  left: 0;
  background: $clientColor;

  // TODO: condition pour sfr
  @media (min-width: 993px) {
    background: url(${SiderBackground}) no-repeat;
    background-position: center;
    background-size: cover;
  }

  ${'' /* &::before {
    content: '';
    height: 150px;
    width: 150px;
    background: url(${SFRlogo}) no-repeat;
    background-size: cover;
    position: fixed;
    bottom: 37px;
    left: 20px;
  } */}
`;

const StyledContent = styled.div`
  height: calc(100vh - 70px - 38px);
  margin: 70px 0 0 256px;
  overflow-y: auto;
  overflow-x: hidden;
  // :hover {
  //   overflow-y: overlay;
  // }
  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: var(--bodyBackground);
  color: var(--textColor);
  box-shadow: 0 -2px 4px var(--boxShadowColor);

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }

  a {
    color: var(--textColor);
    font-weight: bold;
  }
`;

const BasicLayout = ({ children, path }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <StyledLayout as={Layout}>
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <NavMenu path={path} setCollapsed={setCollapsed} />
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content}>{children}</StyledContent>
        <StyledFooter as={Footer}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,

  path: PropTypes.string.isRequired
};

export default BasicLayout;
