import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import ShowPartner from './ShowPartner';
import CreateUpdatePartner from './CreateUpdatePartner';
import ListPartners from './ListPartners';
import { outOfNavRoutes, routes } from '../../utils/constants/routes';
import ShowContact from './Contacts/ShowContact';
import ShowPartner from './ShowPartner';

const PartnerRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${routes.PARTNER}/create`}
        render={() => <CreateUpdatePartner purpose="create" />}
      />
      <Route
        path={`${routes.PARTNER}/edit/:id`}
        render={() => <CreateUpdatePartner purpose="edit" />}
      />
      <Route
        path={`${routes.PARTNER}/show/:id`}
        render={() => <ShowPartner />}
      />
      {/* <Route path={`${routes.PARTNER}/show/:id`} component={ShowPartner} /> */}
      {/* <Route path={`${path}${outOfNavRoutes.CONTACTS}/show/:id`} component={ShowContact} /> */}
      <Route path={routes.PARTNER} component={ListPartners} />
    </Switch>
  );
};

export default PartnerRouter;
