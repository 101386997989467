import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const boxShadow = 'rgba(0, 0, 0, 0.04) 0px 3px 5px';
const ProfileSession = styled.div`
  background: white;
  padding: 1.5rem;
  box-shadow: ${boxShadow};
  border-radius: 5px;
  width: 100%;
  flex: 0.5;
  height: fit-content;
  max-width: 400px;
  width: 400px;
`;

const ContainerBody = styled.div`
  width: 100%;
  flex: 1.9;
  margin-left: 1rem;
`;

const ObjectiveSession = styled.div`
  background: white;
  padding: 1.5rem;
  position: relative;
  box-shadow: ${boxShadow};
`;

const TerminatedSession = styled.div`
  background: white;
  padding: 1.5rem;
  box-shadow: ${boxShadow};
  margin-top: 1rem;
  position: relative;
`;

const styles = {
  graph_header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  graph_header_first: {
    fontSize: '1rem',
    fontWeight: 500
  },
  graph_header_second: {
    color: 'gray'
  },
  app_divider_container_benefit: {
    width: '50px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_small: {
    width: '40px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_comment: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_timeline: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_terminate: {
    width: '75px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_extra_large: {
    width: '230px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  }
};

export {
  styles,
  ProfileSession,
  ObjectiveSession,
  TerminatedSession,
  ContainerBody
};
