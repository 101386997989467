import React, { useState, useRef, useEffect } from 'react';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const CustomUploader = ({
  customHandleFileUpload,
  hasFileList,
  expectedFileTypes,
  customOnButtonClick,
  customDeleteFile,
  uploadText,
  customUploadFunction,
  afterUploadRoute,
  isBlock,
  margin
}) => {
  const [fileData, setFileData] = useState(null);
  const [fileName, setFileName] = useState('');

  const { dispatchAPI } = useAuthContext();
  const inputFile = useRef(null);
  const { t } = useTranslation();

  // TODO: find a way to reset the inputFile ref (null doesn't work)
  const defaultDeleteFile = () => {
    setFileData(null);
    setFileName('');
  };
  const defaultOnButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const defaultUploadFunction = async (route, file) => {
    try {
      const response = await dispatchAPI('POST', {
        url: route,
        body: { data: file }
      });
      message.success(
        `${t('contracts.message.xml.success')} ${
          response.data.nbContractsUpdated
        }`
      );
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === 'Invalid XML file') {
          message.error(t(`contracts.message.xml.error`));
        } else {
          message.error(t(`contracts.message.xml.resil`));
        }
      }
    }
  };

  const defaultHandleFileUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const reader = new FileReader();
      reader.readAsBinaryString(files[0]);
      reader.onload = async () => {
        try {
          setFileData(reader.result);
          setFileName(files[0].name);
        } catch (error) {
          message.error(error);
        }
      };
    }
  };

  useEffect(() => {
    if (!fileData) return;
    if (customUploadFunction) {
      customUploadFunction(afterUploadRoute, fileData);
    } else {
      defaultUploadFunction(afterUploadRoute, fileData);
    }
  }, [fileData]);

  return (
    <>
      <input
        style={{ display: 'none' }}
        accept={expectedFileTypes}
        ref={inputFile}
        onChange={customHandleFileUpload || defaultHandleFileUpload}
        type="file"
      />
      <Button
        icon={<UploadOutlined />}
        block={isBlock}
        onClick={customOnButtonClick || defaultOnButtonClick}
        style={{ margin }}
      >
        {` ${uploadText} `}
      </Button>
      {hasFileList && fileName && (
        <Button
          type="link"
          block
          icon={<DeleteOutlined />}
          style={{ float: 'right', margin: '0 4px' }}
          onClick={customDeleteFile || defaultDeleteFile}
        >
          {fileName}
        </Button>
      )}
    </>
  );
};

CustomUploader.propTypes = {
  customHandleFileUpload: PropTypes.func,
  hasFileList: PropTypes.bool,
  expectedFileTypes: PropTypes.string,
  customDeleteFile: PropTypes.func,
  customOnButtonClick: PropTypes.func,
  uploadText: PropTypes.string,
  customUploadFunction: PropTypes.func,
  afterUploadRoute: PropTypes.string.isRequired,
  isBlock: PropTypes.bool,
  margin: PropTypes.number
};

CustomUploader.defaultProps = {
  customHandleFileUpload: null,
  hasFileList: true,
  expectedFileTypes: '',
  customDeleteFile: null,
  customOnButtonClick: null,
  uploadText: 'Importer un fichier',
  customUploadFunction: null,
  isBlock: false,
  margin: 0
};
export default CustomUploader;
