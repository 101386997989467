/* eslint-disable no-console */
const swUrl = `./service-worker.js`;

export const register = () => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        const registration = await navigator.serviceWorker.register(swUrl);

        console.log(`Service Worker registered! Scope: ${registration.scope}`);
      } catch (error) {
        console.error(`Service Worker registration failed: ${error}`);
      }
    });
  }
};

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
};
