import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Select, Tag, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { contractStatus } from '../../../utils/constants/tagColors';

const { Option } = Select;

const useListContent = ({
  type,
  ref,
  ref_seller,
  customer,
  rum,
  begin_date,
  signed,
  signature,
  file,
  ref_type_contract,
  contract_status
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [partnerName, setPartnerName] = useState();
  const [customerName, setCustomerName] = useState();
  const [refType, setRefType] = useState();

  const getPartner = async () => {
    if (ref_seller) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/users/${ref_seller}`
        });
        if (data.email) {
          const data_seller = await dispatchAPI('GET', {
            url: `sellers/mail/${data.email}`
          });
          setPartnerName(data_seller.data.title);
        }
      } catch (e) {
        if (e.response) message.error(e.response.status);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getPartner();
    })();
  }, [ref_seller, customer]);

  useEffect(() => {
    if (ref_type_contract) {
      setRefType(ref_type_contract.ref);
    }
  }, [ref_type_contract]);

  let column = [];
  if (
    user.role === 'users:VENDEUR' ||
    user.role === 'users:PARTENAIRE' ||
    user.role === 'users:MANAGER'
  ) {
    column = [
      {
        label: 'contract-types.form.name',
        content: refType,
        span: 3
      },
      {
        label: 'contracts.form.ref',
        content: ref,
        span: 3
      },
      {
        label: 'contracts.form.partner',
        content: partnerName,
        span: 3
      },
      {
        label: 'contracts.form.customer',
        content: customer && `${customer.first_name} ${customer.last_name}`,
        span: 3
      },
      {
        label: 'contracts.form.email',
        content: customer && customer.email,
        span: 3
      },
      {
        label: 'contracts.form.begin_date',
        content: begin_date ? moment(begin_date).format('ll') : '-',
        span: 3
      },
      {
        label: 'contracts.form.signed',
        content: (
          <Tag color={contractStatus[signed]}>
            {t(`contracts.form.${signed}`)}
          </Tag>
        ),
        span: 3
      },
      {
        label: 'contracts.form.contract_status',
        content: (
          <Tag color={contractStatus[contract_status]}>
            {t(`contracts.tags.${contract_status}`)}
          </Tag>
        ),
        span: 3
      }
    ];
  } else {
    column = [
      {
        label: 'contract-types.form.name',
        content: refType,
        span: 3
      },
      {
        label: 'contracts.form.ref',
        content: ref,
        span: 3
      },
      {
        label: 'contracts.form.partner',
        content: partnerName,
        span: 3
      },
      {
        label: 'contracts.form.customer',
        content: customerName,
        span: 3
      },
      {
        label: 'contracts.form.begin_date',
        content: begin_date ? moment(begin_date).format('ll') : '-',
        span: 3
      }
    ];
  }
  return column;
};

export default useListContent;
