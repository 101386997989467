import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import {
  commissionStatuses,
  commissionTypes
} from '../../utils/constants/tagColors';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const ListCommissions = () => {
  const { t } = useTranslation();
  const { user, checkShouldDisplayListItem } = useAuthContext();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [partners, setPartners] = useState([]);
  const [sellers, setSellers] = useState([]);

  const getPartners = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/partners' });
      setPartners(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getSellers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/sellers' });
      setSellers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = async () => {
    await getPartners();
    await getSellers();
  };

  useEffect(() => {
    getSelectOptions();
  }, []);
  const columns = [
    {
      title: t('commissions.form.contract'),
      dataIndex: 'ref',
      key: 'ref',
      sorter: true
    },
    {
      title: t('commissions.form.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true
    },
    {
      title: t('commissions.form.price'),
      dataIndex: 'amount_commission',
      key: 'amount_commission',
      render: amount => amount && `${amount} €`,
      sorter: true
    },
    {
      title: t('commissions.form.contractCreationDate'),
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: true,
      render: created_at =>
        created_at && moment(created_at).format('Do MMMM YYYY')
    },
    {
      title: t('commissions.form.seller'),
      key: 'seller',
      dataIndex: 'seller',
      render: seller => (!seller ? 'Mes commissions' : seller),
      sorter: true,
      filters: sellers.map(seller => ({
        text: `${seller.first_name} ${seller.last_name}`,
        value: seller._id
      }))
    },
    {
      title: t('commissions.form.partner'),
      key: 'partner',
      dataIndex: 'partner',
      sorter: true,
      render: partner => (partner ? partner.name : 'Contrat interne'),
      filters: partners.map(partner => ({
        text: partner.name,
        value: partner._id
      }))
    },
    {
      title: t('commissions.form.status_short'),
      key: 'commission',
      sorter: true,
      render: ({ status }) => (
        <Tag color={commissionStatuses[status]}>
          {t(`commissions.tags.${status}`)}
        </Tag>
      )
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="commissions"
      columns={columns.filter(column =>
        checkShouldDisplayListItem('commissions', column.key)
      )}
      resourceModelName="Commission"
      withSubRoutes
      withCreateButton={false}
      withUploadButton={false}
      editAction={false}
      deleteAction={false}
      populate="type contract partner seller"
      worth_styling
    />
  );
};

export default ListCommissions;
