import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  ShopOutlined,
  ArrowRightOutlined,
  UsergroupAddOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import {
  Row,
  Col,
  Tooltip,
  Tag,
  Button,
  Switch,
  Table,
  Radio,
  Modal,
  message
} from 'antd';
import { styles } from '../styles_home/home_seller.js';
import { Line_chart } from '../charts_folder/Line_chart';
import { BarChart } from '../charts_folder/BarChart';
import useAuthContext from '../../../contexts/AuthContext';

const boxShadowContainer = 'rgb(0 0 0 / 10%) 3px 3px 20px';
const bounceAnimation = keyframes`
	0% {
		left: 0px;
	}
	50% {
		left: 5px;
	}
	100% {
		left: 0px;
	}
`;
const AnimateIconMetric = styled.div`
  position: relative;
  margin-bottom: 0rem;
  margin-left: 0.25rem;
  animation: 0.75s ${bounceAnimation} linear infinite;
`;

const cols = {
  month: {
    range: [0, 1]
  }
};

const fields = [
  'Jan',
  'févr',
  'Mars',
  'Avr',
  'Mai',
  'Juin',
  'Jul',
  'Août',
  'Sept',
  'Oct',
  'Nov',
  'Déc'
];

const Card = ({
  id_card,
  is_middle,
  number,
  tagColor,
  iconComponent,
  is_present_action,
  name_action,
  extra_name,
  link_action,
  is_modal_actionable
}) => {
  const boxShadowCard =
    '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d';
  const CardMetric = styled.div`
    background: white;
    padding: 1rem;
    border-radius: 5px;
    margin: auto;
    margin-top: 1rem;
    width: 285px;
    box-shadow: ${boxShadowContainer};
    @media screen and (min-width: 1080px) {
      ${is_middle && `margin-left: 1rem; margin-right: 1rem;`}
    }
    transition: 0.1s transform linear;
    &:hover {
      transform: translateY(-10px);
    }
    cursor: pointer;
  `;
  const CountSpan = styled.span`
    font-size: 3rem;
  `;

  const { dispatchAPI, user } = useAuthContext();
  const [title, setTitle] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name_card, setName_card] = useState(false);
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [datas_contracts, setDatas_contracts] = useState([]);
  const [datas_goal, setDatas_goal] = useState([]);
  const [datas_resiliated, setDatas_resiliated] = useState([]);

  const routesNames = [
    `/getDetailedContracts/`,
    `/getDetailedTerminatedContracts/`,
    `/getDetailedGoal/`
  ];

  const getDatas = useCallback(async () => {
    setIsLoading(true);
    try {
      for (let index = 0; index < routesNames.length; index++) {
        const { data } = await dispatchAPI('GET', {
          url: `/home_seller${routesNames[index]}${user._id}`
        });
        if (index === 0) {
          setDatas_contracts(data);
        } else if (index === 1) {
          setDatas_resiliated(data);
        } else if (index === 2) {
          setDatas_goal(data);
        }
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  }, [user._id]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showModal = name_card => {
    if (is_modal_actionable) {
      setName_card(name_card);
      if (name_card == 'Objectif') {
        setTitle('Progression des objectifs');
      } else if (name_card == 'Contrats') {
        setTitle('Contrats signés cumulés');
      } else {
        setTitle('Progression du taux de résiliation');
      }
      setIsModalVisible(true);
    }
  };

  return (
    <>
      <CardMetric /*onClick={() => showModal(name_action)}*/>
        <Row style={styles.bottom_card}>
          <div style={styles.label_infos}>
            <Tag color={tagColor}>{name_action}</Tag>
          </div>
        </Row>
        <Row style={styles.top_card}>
          <p style={styles.metric_number}>
            <CountSpan>{number}</CountSpan>
            <span style={{ fontSize: '1.1rem', color: 'gray' }}>
              {extra_name}
            </span>
          </p>
          <Icon component={iconComponent} style={styles.icon_metric} />
        </Row>
        {is_present_action && (
          <Link to={link_action} style={styles.call_to_action_metric}>
            <div style={styles.p_header}>
              Nouveau {name_action.slice(0, -1)}
            </div>
            <AnimateIconMetric>
              <ArrowRightOutlined />
            </AnimateIconMetric>
          </Link>
        )}
      </CardMetric>
      <Modal
        title={title}
        visible={isModalVisible}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {name_card === 'Objectif' ? (
          <BarChart
            data={datas_goal}
            xAxis="month"
            yAxis="goal"
            fields={fields}
            type="interval"
          />
        ) : name_card === 'Contrats' ? (
          <Line_chart
            data={datas_contracts}
            xAxis="month"
            yAxis="results"
            cols={cols}
          />
        ) : (
          <BarChart
            data={datas_resiliated}
            xAxis="month"
            yAxis="terminate"
            fields={fields}
            type="intervalStack"
          />
        )}
      </Modal>
    </>
  );
};

Card.propTypes = {
  id_card: PropTypes.string,
  is_middle: PropTypes.bool,
  id_card: PropTypes.string,
  is_middle: PropTypes.bool,
  number: PropTypes.string,
  tagColor: PropTypes.string,
  iconComponent: PropTypes.object,
  is_present_action: PropTypes.bool,
  name_action: PropTypes.string,
  extra_name: PropTypes.string,
  link_action: PropTypes.string,
  is_modal_actionable: PropTypes.bool
};

Card.defaultProps = {
  id_card: null,
  is_middle: false,
  id_card: null,
  is_middle: false,
  number: null,
  tagColor: null,
  iconComponent: null,
  is_present_action: false,
  name_action: null,
  extra_name: null,
  link_action: null,
  is_modal_actionable: true
};

export default Card;
