import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainerBenefits';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateRequest = ({ eligibilityBenefits }) => {
  const { fields, steps, selectedBenefit, base64 } = useFields(
    eligibilityBenefits
  );
  const { id } = useParams();
  const { user } = useAuthContext();
  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        dob: data.dob && moment(data.dob)
      })
    },
    onCreateResource: {
      setBody: data => {
        console.log(base64);
        return {
          ...data,
          ref_contract: id,
          ref_benefitType: selectedBenefit.ref_benefitType,
          customer: selectedBenefit.customer,
          title_benefit: selectedBenefit.title,
          refund: selectedBenefit.amount,
          periodic: selectedBenefit.periodic,
          files: base64
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      baseUrl="requests"
      resource="requests"
      config={config}
      // steps={steps}
      isTrackable={false}
      isVotable={true}
      purpose="create"
      urlReturn={id ? `/contracts/show/${id}` : null}
    />
  );
};

export default CreateUpdateRequest;
