export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const invoiceTypes = {
  PAY: 'blue',
  DEPOSIT: 'green',
  CREDIT: 'magenta'
};

export const contractTypesShow = {
  FALSE: 'grey',
  TRUE: 'green'
};

export const contractStatus = {
  PENDING: 'orange',
  SIGNED: 'green',
  // ARCHIVED: 'grey',
  INPROGRESS: 'green',
  TERMINATE: 'red'
};

export const ticketStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const benefitStatuses = {
  WAITING: 'orange',
  REFUSED: 'red',
  ACCEPTED: 'green'
};

export const commissionStatuses = {
  UNPAID: 'red',
  PAID: 'green'
};

export const ticketsPriorities = ['NORMAL', 'HIGH', 'URGENT'];

export const ticketsPrioritiesColor = {
  LOW: 'blue',
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const ticketsTypes = ['PICKUP', 'THEFT', 'LOSS', 'DAMAGED'];

export const commissionTypes = {
  FIRST: 'green',
  SECOND: 'orange',
  THIRD: 'red'
};

export const ticketsTypesColor = {
  PICKUP: 'green',
  THEFT: 'volcano',
  LOSS: 'cyan',
  DAMAGED: 'red'
};

export const tasksStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  CLOSED: 'red'
};

export const tasksPriorities = {
  URGENT: 'red',
  HIGH: 'yellow',
  NORMAL: 'cyan',
  LOW: 'grey'
};

export const projectsStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const companiesTypes = {
  CUSTOMERS: 'blue',
  SUPPLIERS: 'green'
};

export const userPermissions = {
  devTeam: 'yellow'
};

export const invoiceStatuses = {
  PAID: 'green',
  NOT_PAID: 'red'
};
