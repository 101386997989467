import React, { useState } from 'react';
import { Input, InputNumber, Select, Upload } from 'antd';
import useErrorMessage from '../../utils/ErrorMessage';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;
const { Option } = Select;
let files = [];
const useFields = eligibilityBenefits => {
  const { TextArea } = Input;
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [selectedBenefit, setSelectedBenefit] = useState();
  const [invoiceAmount, setInvoiceAmount] = useState();
  const [fileList, setFileList] = useState([]);

  const [base64, setBase64] = useState(files);

  // This function convert the PDF to base64 format
  const fileToBase64 = async file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      for (var i = 0; i < base64.length; i++) {
        base64[i].name === file.name && base64.splice(i, 1);
      }
      setFileList(newFileList);
      setBase64(base64);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension === 'pdf') {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        files.push({ name: file.name, content: base });
        console.log(files);
        setBase64(files);
        return false;
      } else {
        message('Not a PDF file.');
        return true;
      }
    },
    fileList
  };

  const fields = [
    {
      name: ['benefit_type'],
      rules: [{ required: true }],
      width: '100%',
      input: (
        <Select
          onChange={value => {
            setSelectedBenefit(
              eligibilityBenefits.find(benefit => benefit.title === value)
            );
          }}
        >
          {eligibilityBenefits &&
            eligibilityBenefits.map(
              type =>
                type.eligibility === 0 && (
                  <Option key={type.tite} value={type.title}>
                    {type.title}
                  </Option>
                )
            )}
        </Select>
      ),
      help: selectedBenefit && (
        <>
          <p
            style={{
              marginBottom: '0rem',
              marginTop: '0.5rem',
              fontSize: '0.8rem'
            }}
          >
            {selectedBenefit.description}
          </p>
        </>
      )
    },
    {
      name: ['files'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },

    {
      name: ['comment'],
      input: <TextArea placeholder="Entrez des commentaires" rows={4} />
    }
  ];

  if (selectedBenefit && selectedBenefit.refund)
    fields.splice(1, 0, {
      name: ['invoice_amount'],
      rules: [{ required: true }],
      width: '100%',
      input: (
        <InputNumber
          min={0}
          onChange={value => {
            setInvoiceAmount(value);
          }}
        />
      ),
      // I know this is scuffed but we only need to display the help for
      // percentage refunds
      help: selectedBenefit && selectedBenefit.description.includes('%') && (
        <p
          style={{
            marginBottom: '0rem',
            marginTop: '0.5rem',
            fontSize: '0.8rem'
          }}
        >
          {`Total remboursé: ${(invoiceAmount &&
            (invoiceAmount * selectedBenefit.amount).toFixed(2)) ||
            0} €`}
        </p>
      )
    });
  // Configure the differents steps and associated fields indexes
  const steps = [
    {
      title: "Type d'avantage",
      prev: 0,
      next: 3
    }
  ];

  return {
    fields,
    steps,
    selectedBenefit,
    base64
  };
};

export default useFields;
