import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import { ScanContextProvider } from './Scan/ScanContext';
import DocumentTemplates from './Templates/DocumentTemplates';
import { TemplatesContextProvider } from './Templates/TemplatesContext';
import CreateTemplate from './Templates/CreateTemplate';
import SignDocument from './Templates/SignDocument';

const DocumentsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <TemplatesContextProvider>
      <ScanContextProvider>
        <Switch>
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}`}
            component={DocumentTemplates}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}/create`}
            render={() => (
              <CreateTemplate
                acceptedTypes=".docx"
                purpose={`${subRoutes.DOCUMENTS.TEMPLATES}`}
                id="templatesId"
              />
            )}
          />
          <Route
            exact
            path={`${path}${subRoutes.DOCUMENTS.TEMPLATES}/sign`}
            component={SignDocument}
          />
          <Redirect from="*" to={`${path}${subRoutes.DOCUMENTS.TEMPLATES}`} />
        </Switch>
      </ScanContextProvider>
    </TemplatesContextProvider>
  );
};

export default DocumentsRouter;
