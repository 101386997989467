import React, { useState, createContext, useContext } from 'react';
import parse from 'date-fns/parse';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useOverdues = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [forceRefresh, setForceRefresh] = useState(false);

  const postOverdues = async overdues => {
    try {
      await dispatchAPI('POST', {
        url: '/overdues/multiple',
        body: { overdues }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleFileImport = async data => {
    /* const overdues = [];

    for (let i = 0; i < data.length; i += 1) {
      const line = data[i][0].replace(/  +/g, ' ');
      const cells = line.split(' ');

      const rum = cells.find((c) => c.startsWith('RUM'));

      if (rum) {
        const code = cells[14].substr(cells[14].length - 4);
        const levy_date = parse(cells[10], 'y-M-d', new Date());
        overdues.push({
          rum,
          code,
          levy_date
        });
      }
    }

    await postOverdues(overdues); */
    console.log(data);
  };

  return {
    functionHandleFile: handleFileImport
  };
};

export default useOverdues;
