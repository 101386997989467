import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import ListRequests from './ListRequestsInprogress';
import CreateUpdateRequest from './CreateUpdateRequest';
import ShowRequest from './ShowRequest';
import CreateTemplate from '../Documents/Templates/AddFileBenefit';

const RequestRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/show/:benefitsId/create`}
        render={() => <CreateTemplate purpose="/requests" id="benefitsId" />}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateRequest purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateRequest purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowRequest />} />
      <Route path={`${path}`} render={() => <ListRequests />} />
    </Switch>
  );
};

export default RequestRouter;
