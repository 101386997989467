import React from 'react';
import PropTypes from 'prop-types';
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip as Tooltip_chart,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from 'bizcharts';
import EmptyChart from '../components_home/EmptyChart';

const Two_axis_chart = ({ data, xAxis, yAxis, zAxis, cols, is_default }) => {
  const axis = `${xAxis}*${yAxis}`;
  const DAxis = `${xAxis}*${zAxis}`;
  const padding = is_default
    ? [40, 30, 'auto', 'auto']
    : [120, 'auto', 'auto', 'auto'];
  return (
    <>
      {!data.length ? (
        <EmptyChart height={is_default ? 307 : 437} />
      ) : (
        <Chart
          scale={cols}
          data={data}
          height={is_default ? 300 : 430}
          width={400}
          padding={padding}
          forceFit
        >
          <Legend
            custom={true}
            allowAllCanceled={true}
            items={[
              {
                value: 'Gold réels',
                marker: {
                  symbol: 'square',
                  fill: '#dcb55e',
                  radius: 5
                }
              },
              {
                value: 'Platinium réels',
                marker: {
                  symbol: 'square',
                  fill: '#e5e4e2',
                  radius: 5
                }
              },
              {
                value: 'Prévisionel',
                marker: {
                  symbol: 'hyphen',
                  stroke: '#75cd99',
                  radius: 5,
                  lineWidth: 3
                }
              }
            ]}
          />
          <Axis
            name={zAxis}
            grid={null}
            label={{
              textStyle: {
                fill: '#75cd99'
              }
            }}
          />
          <Tooltip_chart />
          <Geom
            type="intervalStack"
            position={axis}
            color={[
              'name',
              value => {
                if (value === 'Gold') {
                  return '#dcb55e';
                }
                if (value === 'Platinium') {
                  return '#e5e4e2';
                }
              }
            ]}
          />
          <Geom
            type="line"
            position={DAxis}
            color="#75cd99"
            size={3}
            shape="smooth"
          />
          <Geom
            type="point"
            position={DAxis}
            color="#75cd99"
            size={3}
            shape="circle"
          />
        </Chart>
      )}
    </>
  );
};

Two_axis_chart.propTypes = {
  data: PropTypes.array,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  zAxis: PropTypes.string,
  cols: PropTypes.object,
  is_default: PropTypes.bool
};

Two_axis_chart.defaultProps = {
  data: null,
  xAxis: null,
  yAxis: null,
  zAxis: null,
  cols: null,
  is_default: true
};

export { Two_axis_chart };
