import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListManagerAdvisors from './ListManagerAdvisors';
import CreateUpdateManagerAdvisor from './CreateUpdateManagerAdvisor';
import ShowManagerAdvisor from './ShowManagerAdvisor';

const ManagerAdvisorRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateManagerAdvisor purpose="create" />}
      />
      <Route
        path={`${path}/create?from=direction&id=:id`}
        render={() => <CreateUpdateManagerAdvisor purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateManagerAdvisor purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowManagerAdvisor />} />
      <Route path={`${path}`} render={() => <ListManagerAdvisors />} />
    </Switch>
  );
};

export default ManagerAdvisorRouter;
