import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Input, Menu, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ExportButton from '../ExportButton/ExportButton';
import ImportButton from '../ImportButton/ImportButton';
import Datatable from '../DataTable/Datatable';
import PageHeaderCustom from '../PageHeader/PageHeader';
import ContentCustom from '../ContentCustom/ContentCustom';

const { Search } = Input;

const SimpleResourceLandingLayout = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  forceRefresh,
  withSubRoutes,
  resourceModelName,
  isShowEnabled,
  editAction,
  deleteAction,
  withHeaders,
  showAction,
  customImportAction,
  worth_styling,
  withImportAction
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const pageSize = params.get('pS');
  const [searchValue, setSearchValue] = useState(keyword);

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      history.push({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item>
          <ExportButton
            dataName={resourceName}
            headers={headers}
            url={`/${exportUrl || resourceName}`}
            fileName={`${resourceName}.csv`}
            populate={populate}
            extraQuery={extraQuery}
          />
        </Menu.Item>
      )}
      {withImportAction && (
        <Menu.Item>
          <ImportButton
            resourceName={resourceModelName}
            customImportAction={customImportAction}
          />
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {withHeaders && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          withSubRoutes={withSubRoutes}
          extra={extraHeader}
          is_home={worth_styling}
        />
      )}
      <ContentCustom
        background={worth_styling ? 'none' : 'var(--componentBackground)'}
      >
        <Row justify="space-between" gutter={[8, 8]}>
          <Col>
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
            />
          </Col>
          {withCreateButton && (
            <Col>
              <Row align="middle">
                {extraButtons}
                {headers && (
                  <Col>
                    <Dropdown overlay={menu}>
                      <Button type="link">
                        <MenuOutlined
                          style={{ fontSize: 16, color: 'var(--textColor)' }}
                        />
                      </Button>
                    </Dropdown>
                  </Col>
                )}
                {withUploadButton && (
                  <Dropdown overlay={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={[8, 8]}>{children}</Row>
        <Datatable
          isShowEnabled={isShowEnabled}
          style={{ marginTop: 8 }}
          resourceName={dataToFetch || resourceName}
          searchValue={searchValue}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          deleteAction={deleteAction}
          showAction={showAction}
        />
      </ContentCustom>
    </>
  );
};

SimpleResourceLandingLayout.propTypes = {
  isShowEnabled: PropTypes.bool,
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.bool,
  withUploadButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  withSubRoutes: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.bool,
  deleteAction: PropTypes.bool,
  withHeaders: PropTypes.bool,
  showAction: PropTypes.bool,
  customImportAction: PropTypes.func,
  worth_styling: PropTypes.bool,
  withImportAction: PropTypes.bool
};

SimpleResourceLandingLayout.defaultProps = {
  isShowEnabled: true,
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  children: [],
  withCreateButton: true,
  withUploadButton: false,
  dataToFetch: null,
  forceRefresh: null,
  withSubRoutes: false,
  resourceModelName: null,
  editAction: true,
  deleteAction: true,
  withHeaders: true,
  showAction: true,
  customImportAction: null,
  worth_styling: false,
  withImportAction: false
};

export default SimpleResourceLandingLayout;
