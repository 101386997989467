import React from 'react';

const boxShadowContainer =
  '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d';
const styles = {
  row_divider: {
    marginTop: '1rem'
  },
  app_divider_container_benefit: {
    width: '75px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_contract: {
    width: '15%',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_metric: {
    width: '60px',
    height: '3px',
    position: 'absolute',
    background: 'var(--primaryColor)',
    borderRadius: '10px',
    top: '3.5rem',
    left: '1.5rem'
  },
  app_divider_container_large: {
    width: '150px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_line: {
    width: '100px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_extra_large: {
    width: '230px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  app_divider_container_donut: {
    width: '90px',
    height: '3px',
    background: 'var(--primaryColor)',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    borderRadius: '10px'
  },
  divider_header: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.65)',
    marginLeft: 'auto'
  },
  p_header: {
    marginBottom: '0rem'
  },
  icon_header: {
    fontSize: '25px',
    marginRight: '5px'
  },
  primary_button: {
    color: 'white',
    width: '160px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: boxShadowContainer
  },
  carousel_button: {
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center'
  },
  metric_number: {
    fontWeight: '400',
    marginBottom: '0rem'
  },
  top_card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label_infos: {
    display: 'flex',
    alignItems: 'center'
  },
  bottom_card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem'
  },
  icon_metric: {
    fontSize: '3.8rem',
    color: 'rgb(117 205 153)',
    transform: 'translateY(-20px)'
  },
  complementary_tag: {
    color: 'gray',
    marginBottom: '0rem',
    marginLeft: '-0.25rem'
  },
  call_to_action_metric: {
    display: 'flex',
    alignItems: 'center',
    color: 'var(--primaryColor)',
    float: 'right',
    marginTop: '-2rem',
    transform: 'translateY(15px)'
  },
  graph_header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  graph_header_first: {
    fontSize: '1rem',
    fontWeight: 500
  },
  graph_header_second: {
    color: 'gray'
  },
  graph_footer: {
    borderTop: '1px solid #efe6e6'
  },
  title_benefit: {
    fontSize: '2.5rem',
    marginBottom: '0rem',
    marginTop: '-1rem'
  },
  sub_title_benefit: {
    fontSize: '1.2rem',
    marginBottom: '0rem',
    marginTop: '-0.5rem'
  },
  outer: {
    width: '155px'
  },
  outer_benefit: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.95rem',
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  outer_benefit_table: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  value_benefit: {
    marginBottom: '0rem',
    marginLeft: '0.25rem'
  },
  p_benefit: {
    marginBottom: '0rem',
    fontWeight: 100,
    fontSize: '13px'
  }
};

export { styles };
