import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Avatar,
  Divider,
  Form,
  InputNumber,
  message
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';

const CommissionSetter = ({ resource, layout }) => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [amounts, setAmounts] = useState({ gold: 0, plat: 0 });
  const [form] = Form.useForm();
  const { t } = useTranslation();
  let url;
  if (resource === 'sellers') {
    url = `/home_seller/commissions`;
  } else if (resource === 'stores') {
    url = `/home_store/commissions`;
  } else if (resource === 'partners') {
    url = `/home_partner/commissions`;
  }
  const onFinishCommission = async values => {
    const { data } = await dispatchAPI('POST', {
      url,
      body: {
        ...amounts,
        _id: id
      }
    });
    message.success(
      'Les nouveaux montants des commission ont été définies avec succès'
    );
  };

  const handleChange = (e, type) => {
    if (typeof e === 'number') {
      type === 'gold'
        ? setAmounts({ ...amounts, gold: e })
        : setAmounts({ ...amounts, plat: e });
    } else {
      type === 'gold'
        ? setAmounts({ ...amounts, gold: e.target.value })
        : setAmounts({ ...amounts, plat: e.target.value });
    }
  };

  const getData = async (key, id_user) => {
    const dataGoal = await dispatchAPI('GET', {
      url: `${url}/${id}`
    });
    if (dataGoal.data) {
      form.setFieldsValue({
        amount_gold: dataGoal.data.amount_commission_gold,
        amount_plat: dataGoal.data.amount_commission_plat
      });
      setAmounts({
        gold: dataGoal.data.amount_commission_gold,
        plat: dataGoal.data.amount_commission_plat
      });
    } else {
      form.setFieldsValue({
        amount_gold: 0,
        amount_plat: 0
      });
      setAmounts({
        gold: 0,
        plat: 0
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <p style={{ marginBottom: '0rem' }}>
        <Form
          name="commissions"
          onFinish={onFinishCommission}
          layout={layout}
          labelCol={{ span: 15 }}
          wrapperCol={{ span: 8 }}
          form={form}
        >
          <Form.Item
            label={t(`${resource}.show.commission_gold`)}
            name="amount_gold"
          >
            <InputNumber
              size="medium"
              value={amounts.gold}
              onChange={e => handleChange(e, 'gold')}
              min={0}
              formatter={gold =>
                `${gold} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
          <Form.Item
            label={t(`${resource}.show.commission_plat`)}
            name="amount_plat"
          >
            <InputNumber
              size="medium"
              value={amounts.plat}
              onChange={e => handleChange(e, 'plat')}
              min={0}
              formatter={plat =>
                `${plat} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
          <Form.Item
            wrapperCol={
              layout === 'default'
                ? {
                    offset: 0,
                    span: 16
                  }
                : {
                    offset: 0,
                    span: 16
                  }
            }
          >
            {amounts.gold || amounts.plat ? (
              <Button type="primary" htmlType="submit">
                Modifier
              </Button>
            ) : null}
          </Form.Item>
        </Form>
      </p>
    </>
  );
};

CommissionSetter.propTypes = {
  resource: PropTypes.string.isRequired,
  layout: PropTypes.string
};

CommissionSetter.defaultProps = {
  resource: null,
  layout: 'inline'
};

export default CommissionSetter;
