import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateContractType = ({ purpose }) => {
  const { fields, isFieldsLoading, base64 } = useFields();

  const config = {
    onUpdateResource: {
      setBody: data => {
        return {
          ...data,
          ...(base64 ? { template_file: base64 } : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="contract-types"
      resource="contract-types"
      config={config}
      withSubRoutes
    />
  );
};

CreateUpdateContractType.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateContractType;
