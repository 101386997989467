/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { message, Tag, Avatar, Button, Tooltip, Col, Row, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ProfileHeader, ContainerBody } from './styles';
import '../../../assets/styles/SCSS/comment.scss';

import { outOfNavRoutes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import Eligiblebenefits from '../../Requests/Eligiblebenefits';
import HistoriqueBenefits from '../../Requests/HistoriqueBenefits';
import CreateUpdateRequest from '../../Requests/CreateRequest';

const HomeCustomer = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { t } = useTranslation();
  const [details, setDetails] = useState();
  const [eligibilityBenefits, setEligibilityBenefits] = useState();
  const [createRequestModal, setCreateRequestModal] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/home-customer/${user.email}`
      });
      setDetails(data);
      const tmpEligibilityBenefits = await dispatchAPI('GET', {
        url: `/requests/getEligibilityBenefitList/${data._id}`
      });
      setEligibilityBenefits(tmpEligibilityBenefits.data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const showModal = () => {
    setCreateRequestModal(true);
  };

  const handleCancel = () => {
    setCreateRequestModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <>
        <PageHeaderCustom
          title={t('contracts.show.title')}
          withSubRoutes
          is_home
        />
        <>
          {details && (
            <div style={{ maxWidth: '1600px', margin: 'auto' }}>
              <ProfileHeader
                className={
                  details.contract?.type?.toUpperCase() === 'GOLD'
                    ? 'gold-banner'
                    : details.contract?.type?.toUpperCase() === 'PLATINIUM' &&
                      'plat-banner'
                }
              >
                <Avatar
                  size={100}
                  style={{
                    minWidth: 100,
                    backgroundColor:
                      details.contract?.type?.toUpperCase() === 'GOLD'
                        ? 'rgb(255 247 213)'
                        : details.contract?.type?.toUpperCase() ===
                            'PLATINIUM' && '#d9d9d9'
                  }}
                  icon={
                    <FilePdfOutlined
                      style={{
                        color:
                          details.contract?.type?.toUpperCase() === 'GOLD'
                            ? 'rgb(250 166 63)'
                            : details.contract?.type?.toUpperCase() ===
                                'PLAT' && '#595959'
                      }}
                    />
                  }
                />
                <Row
                  style={{
                    marginLeft: '2.5rem',
                    display: 'flex',
                    flexDirection: 'row wrap',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Col style={{ marginRight: '1.25rem' }}>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '1.25rem', marginBottom: '0rem' }}>
                        {details.contract && details.contract.ref}
                      </p>
                      <Tag
                        color={
                          details.contract?.type?.toUpperCase() === 'GOLD'
                            ? 'gold'
                            : 'gray'
                        }
                        style={{ marginLeft: '0.5rem' }}
                      >
                        {details.contract &&
                          details.contract.type?.toUpperCase()}
                      </Tag>
                    </Col>
                    <p style={{ marginBottom: '0rem', fontSize: '0.8rem' }}>
                      Date de début:{' '}
                      <strong>
                        {details.contract &&
                          moment(details.contract.begin_date).format(
                            'Do MMMM YYYY'
                          )}
                      </strong>
                    </p>
                  </Col>
                  <Col
                    style={{
                      borderLeft: '1px solid #cacfd9',
                      paddingLeft: '1.25rem',
                      marginRight: '1.25rem'
                    }}
                  >
                    <p style={{ marginBottom: '0rem' }}>
                      Date de naissance:{' '}
                      <strong>
                        {moment(details.dob).format('DD-MM-YYYY')}
                      </strong>{' '}
                    </p>
                    <p style={{ marginBottom: '0rem' }}>
                      Ville de naissance: <strong>{details.birth_city}</strong>
                    </p>
                  </Col>
                  <Col
                    style={{
                      borderLeft: '1px solid #cacfd9',
                      paddingLeft: '1.25rem'
                    }}
                  >
                    <p style={{ marginBottom: '0rem' }}>
                      Adresse email: <strong>{details.email}</strong>
                    </p>
                    <p style={{ marginBottom: '0rem' }}>
                      Téléphone: <strong>{details.phone_number}</strong>
                    </p>
                  </Col>
                  <Col
                    flex="auto"
                    align="end"
                    style={{
                      marginLeft: '0.5rem'
                    }}
                  >
                    <Button
                      icon={<PlusOutlined />}
                      color="var(--primaryColor)"
                      onClick={showModal}
                    >
                      {'Créer un avantage'}
                    </Button>
                    <Link
                      to={{
                        pathname: `${outOfNavRoutes.PROFILE}/edit/${user._id}`
                      }}
                    >
                      <Button
                        style={{ marginLeft: '0.5rem' }}
                        icon={<EditOutlined />}
                      >
                        {'Modifier les informations'}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </ProfileHeader>
              <Modal
                title={"Attribution d'avantage"}
                visible={createRequestModal}
                onCancel={handleCancel}
                footer={false}
                width={800}
              >
                <CreateUpdateRequest
                  eligibilityBenefits={eligibilityBenefits}
                />
              </Modal>
              <ContainerBody>
                {details && <Eligiblebenefits data={details._id} />}
                {details && <HistoriqueBenefits data={details._id} />}
              </ContainerBody>
            </div>
          )}
        </>
      </>
    </>
  );
};

export default HomeCustomer;
