import React from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined, FallOutlined, RiseOutlined } from '@ant-design/icons';
import { rationMonthVariation, roundUp } from '../../../utils';

const { Text } = Typography;

const ration = (lastMonth, previousMonth) => {
  const rate = rationMonthVariation(lastMonth, previousMonth);

  if (rate === 0) return 'N/A';
  return (
    <>
      {rate > 0 ? (
        <RiseOutlined style={{ color: 'green' }} />
      ) : (
        <FallOutlined style={{ color: 'red' }} />
      )}
      {` ${rate} %`}
    </>
  );
};

const percent = (signed, resil) => {
  return resil > 0 ? roundUp((resil / signed) * 100) : 0;
};

const columns = (purpose) => [
  {
    title: purpose === '/sellers' ? 'Vendeurs' : 'Magasins',
    dataIndex: 'name'
  },
  {
    title: 'Total année', // totalcontract
    dataIndex: 'totalContract',
    align: 'right'
  },
  {
    title: 'Période en cours',
    dataIndex: 'lastMonth',
    align: 'right'
  },
  {
    title: 'Période-1',
    dataIndex: ['lastMonth', 'previousMonth'],
    render: (_, row) => ration(row.lastMonth, row.previousMonth),
    align: 'right'
  },
  {
    title: 'Evo A-1',
    dataIndex: ['lastYear', 'previousYear'],
    render: (_, row) => ration(row.lastYear, row.previousYear),
    align: 'right'
  },
  {
    title: '% formules HV', // % formules platinium
    dataIndex: ['hv', 'lastMonth'],
    render: (_, row) =>
      row.lastMonth ? `${percent(row.lastMonth, row.hv)}%` : 'N/A',
    align: 'right'
  },
  {
    title: '% clôture < 31j', // Taux de résiliation
    dataIndex: ['lastMonth', 'clos31'],
    render: (_, row) => `${percent(row.lastMonth, row.clos31)} %`,
    align: 'right'
  },
  {
    title: '% clôture > 31j', // Taux de résiliation
    dataIndex: ['lastMonth', 'clos61'],
    render: (_, row) => `${percent(row.lastMonth, row.clos61)} %`,
    align: 'right'
  },
  {
    dataIndex: ['route', 'id'],
    render: (_, row) => (
      <Link to={{ pathname: `${row.route}/show/${row.id}` }}>
        <EyeOutlined style={{ fontSize: '18px' }} />
      </Link>
    )
  }
];

const ListStores = ({ data }) => {
  const purpose = data?.[0]?.route;

  return (
    <Table
      columns={columns(purpose)}
      dataSource={data}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      summary={(pageData) => {
        let totalTotalContract = 0;
        let totalLastMonth = 0;
        let totalHV = 0;
        let totalClos31 = 0;
        let totalClos61 = 0;
        let totalPrev = 0;
        let totalPreviousYear = 0;
        let totalLastYear = 0;

        pageData.forEach(
          ({
            totalContract,
            lastMonth,
            clos31,
            clos61,
            hv,
            previousMonth,
            previousYear,
            lastYear
          }) => {
            totalTotalContract += totalContract;
            totalLastMonth += lastMonth;
            totalHV += hv;
            totalClos31 += clos31;
            totalClos61 += clos61;
            totalPrev += previousMonth;
            totalPreviousYear += previousYear;
            totalLastYear += lastYear;
          }
        );
        const avgHV = percent(totalLastMonth, totalHV);
        const avgClos31 = percent(totalLastMonth, totalClos31);
        const avgClos61 = percent(totalLastMonth, totalClos61);
        const avgPrev = ration(totalLastMonth, totalPrev);
        const avgYear = ration(totalLastYear, totalPreviousYear);
        return (
          <>
            <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
              <Table.Summary.Cell>Total</Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{totalTotalContract}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{totalLastMonth}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{avgPrev}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{avgYear}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{`${avgHV} %`}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{`${avgClos31} %`}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <Text style={{ fontWeight: 'bold' }}>{`${avgClos61} %`}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

ListStores.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ route: PropTypes.string }))
};

ListStores.defaultProps = {
  data: []
};

export default ListStores;
