import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/routes';
import Login from './login';
import Home from './home/Home';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import ProfileRouter from './profile/ProfileRouter';
import Confirmation from './emailConfirmation/Confirmation';
import UserRouter from './users/UserRouter';
import ContractRouter from './Contracts/ContractRouter';
import SellerRouter from './Sellers/SellerRouter';
import AdvisorRouter from './Advisors/AdvisorRouter';
import RequestRouter from './Requests/RequestRouter';
import ContractSettingsRouter from './Contracts/ContractSettingsRouter';
import PartnerRouter from './Partner/PartnerRouter';
import DocumentsRouter from './Documents/DocumentsRouter';
import CustomerRouter from './Contracts/Customers/CustomerRouter';
import StoreRouter from './Stores/StoreRouter';
import ManagerAdvisorRouter from './ManagerAdvisors/ManagerAdvisorsRouter';
import DirectionsRouter from './Directions/DirectionsRouter';
import InvoicesRouter from './invoices/InvoicesRouter';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const authContext = useAuthContext();
  const splitPath = location.pathname.split('/');
  const splitPathGrant = location.pathname.split('/').filter((e) => e !== '');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;

  const grantFromPath =
    Symbol.iterator in Object(splitPathGrant)
      ? splitPathGrant.join('.').toLowerCase()
      : splitPathGrant.toLowerCase();

  const checkToRender = (props, resourceToCheck) => {
    if (!authContext.isValid) {
      return (
        <Redirect
          to={{
            pathname: outOfNavRoutes.LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    if (!authContext.checkIsGranted(resourceToCheck)) {
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: props.location }
          }}
        />
      );
    }

    return (
      <BasicLayout path={path}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </BasicLayout>
    );
  };

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={(props) => checkToRender(props, grantFromPath)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};

const Router = () => {
  const authContext = useAuthContext();
  authContext.checkShouldDisplayActionItem('contracts', 'edit');

  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <PrivateRoute path={outOfNavRoutes.PROFILE} component={ProfileRouter} />

        {/* <PrivateRoute exact path="/settings" component={Settings} /> */}
        <PrivateRoute exact path={routes.HOME} component={Home} />
        {authContext.checkShouldDisplayMenuItem('users') && (
          <PrivateRoute path={routes.USERS} component={UserRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('contracts') && (
          <PrivateRoute path={routes.CONTRACTS} component={ContractRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('contracts-settings') && (
          <PrivateRoute
            path={routes['CONTRACTS-SETTINGS']}
            component={ContractSettingsRouter}
          />
        )}
        {authContext.checkShouldDisplayMenuItem('partner') && (
          <PrivateRoute path={routes.PARTNER} component={PartnerRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('customers') && (
          <PrivateRoute path={routes.CUSTOMERS} component={CustomerRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('sellers') && (
          <PrivateRoute path={routes.SELLERS} component={SellerRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('requests') && (
          <PrivateRoute path={routes.REQUESTS} component={RequestRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('advisors') && (
          <PrivateRoute path={routes.ADVISORS} component={AdvisorRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('stores') && (
          <PrivateRoute path={routes.STORES} component={StoreRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('manager-advisors') && (
          <PrivateRoute
            path={routes['MANAGER-ADVISORS']}
            component={ManagerAdvisorRouter}
          />
        )}
        {authContext.checkShouldDisplayMenuItem('directions') && (
          <PrivateRoute path={routes.DIRECTIONS} component={DirectionsRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('documents') && (
          <PrivateRoute path={routes.DOCUMENTS} component={DocumentsRouter} />
        )}
        {authContext.checkShouldDisplayMenuItem('invoices') && (
          <PrivateRoute path={routes.INVOICES} component={InvoicesRouter} />
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
