import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { userRoles } from '../../utils/constants/tagColors';
import useAuthContext from '../../contexts/AuthContext';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const ListStores = () => {
  const { t } = useTranslation();
  const {
    checkShouldDisplayListItem,
    checkShouldDisplayActionItem
  } = useAuthContext();

  const truncate = (str, n) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  };

  const columns = [
    {
      title: t('stores.form.title'),
      key: 'title',
      dataIndex: 'title',
      render: null,
      sorter: true
    },
    {
      title: t('stores.form.date'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: d => moment(d).format('Do MMMM YYYY'),
      sorter: true
    },
    {
      title: t('stores.form.full_name'),
      dataIndex: ['first_name', 'last_name'],
      key: 'full_name',
      render: (text, row) => `${row.first_name} ${row.last_name}`
    },
    {
      title: t('stores.form.mail_address'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('stores.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => `${phone_number.country_code}${phone_number.number}`,
      sorter: true
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="stores"
      columns={columns.filter(column =>
        checkShouldDisplayListItem('stores', column.key)
      )}
      withCreateButton={checkShouldDisplayActionItem('stores', 'create')}
      withSubRoutes
      resourceModelName="STORES"
      withUploadButton={false}
      worth_styling
    />
  );
};

export default ListStores;
