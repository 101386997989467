import React from 'react';

const Columns = t => [
  {
    title: t('files.list.columns.name'),
    dataIndex: 'metadata.originalName',
    key: 'name',
    sorter: true
  }
];

export default Columns;
