import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const showDirectionContent = ({
  gender,
  first_name,
  last_name,
  email,
  company_name,
  ics,
  ribSequenceType,
  phone_number,
  dob,
  birth_city,
  nationality,
  address,
  rib,
  contract,
  comment
}) => {
  return [
    {
      label: 'directions.form.last_name',
      span: 3,
      content: last_name || '-'
    },
    {
      label: 'directions.form.first_name',
      span: 3,
      content: first_name || '-'
    },
    {
      label: 'directions.form.company_name',
      span: 3,
      content: company_name || '-'
    },
    {
      label: 'directions.form.email',
      span: 3,
      content: email || '-'
    },
    {
      label: 'directions.form.phone_number',
      span: 3,
      content: phone_number ? (
        <div>{formatPhoneNumberIntl(phone_number)}</div>
      ) : (
        '-'
      )
    },
    {
      label: 'directions.form.address_full',
      span: 3,
      content: address ? (
        <>
          {`${address.street}`}
          {/* Removed: ${address.number} */}
          <br />
          {address.additional}
          {address.additional && <br />}
          {`${address.postal_code} ${address.city}`}
        </>
      ) : (
        '-'
      )
    },
    {
      label: 'directions.form.rib.IBAN',
      span: 3,
      content: rib?.IBAN || '-'
    },
    {
      label: 'directions.form.rib.BIC',
      span: 3,
      content: rib?.BIC || '-'
    },
    {
      label: 'directions.form.ics',
      span: 3,
      content: ics || '-'
    },
    {
      label: 'directions.form.ribSequenceType',
      span: 3,
      content: ribSequenceType || '-'
    }
  ];
};

export default showDirectionContent;
