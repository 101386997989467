import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../../contexts/AuthContext';

const CreateUpdateContract = ({
  purpose,
  is_terminate,
  id_benefit,
  id_contract
}) => {
  const [type, setType] = useState('');
  const [form, setForm] = useState();
  const [contractTypes, setContractTypes] = useState();
  const [customerFlow, setCustomerFlow] = useState();
  const { user, dispatchAPI } = useAuthContext();
  const { fields, isFieldsLoading, datas_date } = useFields(
    id_contract,
    setType,
    type,
    form,
    is_terminate,
    purpose
  );
  const getContractTypes = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/contract-types/`
    });
    setContractTypes(data);
  };

  const getCustomer = async () => {
    if (window.location.search) {
      const output = await dispatchAPI('GET', {
        url: `/customers/${window.location.search.split('=')[2]}`
      });
      setCustomerFlow(output.data._id);
    }
  };

  useEffect(() => {
    getContractTypes();
    getCustomer();
  }, []);

  const config = {
    onGetResource: {
      setFields: async (data) => {
        setType(data.type);
        return {
          ...data,
          begin_date: data.begin_date && moment(data.begin_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ref_seller: user._id,
        signed: 'PENDING',
        // TODO: The client requested that we remove subtypes for contracts
        // Since a big part of the workflow depends on them we instead set a
        // default value for ref_type_contract
        // THIS IS A TEMPORARY & SHIT SOLUTION PLEASE REFACTOR
        ...(data.type === 'Platinium' && !data.ref_type_contract
          ? {
              ref_type_contract:
                contractTypes &&
                contractTypes.find((el) => el.ref === 'PLAT-001')
            }
          : {}),
        ...(data.type === 'Gold' && !data.ref_type_contract
          ? {
              ref_type_contract:
                contractTypes &&
                contractTypes.find((el) => el.ref === 'GOLD-001')
            }
          : {})
      })
    }
  };
  if (window.location.search) {
    config.onCreateResource.setBody = (data) => ({
      ...data,
      customer: customerFlow,
      ref_seller: user._id,
      signed: 'PENDING',
      // TODO: The client requested that we remove subtypes for contracts
      // Since a big part of the workflow depends on them we instead set a
      // default value for ref_type_contract
      // THIS IS A TEMPORARY & SHIT SOLUTION PLEASE REFACTOR
      ...(data.type === 'Platinium' && !data.ref_type_contract
        ? {
            ref_type_contract:
              contractTypes && contractTypes.find((el) => el.ref === 'PLAT-001')
          }
        : {}),
      ...(data.type === 'Gold' && !data.ref_type_contract
        ? {
            ref_type_contract:
              contractTypes && contractTypes.find((el) => el.ref === 'GOLD-001')
          }
        : {})
    });
  }

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="contracts"
      resource="contracts"
      withSubRoutes
      config={config}
      datasDate={datas_date}
      isTrackable={!is_terminate}
      isVotable={!is_terminate}
      setForm={setForm}
    />
  );
};

CreateUpdateContract.propTypes = {
  purpose: PropTypes.string.isRequired,
  is_terminate: PropTypes.bool,
  id_contract: PropTypes.string
};

CreateUpdateContract.defaultProps = {
  is_terminate: false,
  id_contract: null
};

export default CreateUpdateContract;
