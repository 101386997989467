import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const showCustomerContent = ({
  gender,
  first_name,
  last_name,
  email,
  phone_number,
  dob,
  birth_city,
  nationality,
  address,
  rib,
  contract,
  comment
}) => {
  return [
    {
      label: 'customers.form.gender',
      span: 3,
      content: gender || '-'
    },
    {
      label: 'customers.form.last_name',
      span: 3,
      content: last_name || '-'
    },
    {
      label: 'customers.form.first_name',
      span: 3,
      content: first_name || '-'
    },
    {
      label: 'customers.form.email',
      span: 3,
      content: email || '-'
    },
    {
      label: 'customers.form.phone_number',
      span: 3,
      content: phone_number ? (
        <div>{formatPhoneNumberIntl(phone_number)}</div>
      ) : (
        '-'
      )
    },
    {
      label: 'customers.form.address_full',
      span: 3,
      content: address ? (
        <>
          {`${`${address.number} ` || ''}${address.street}`}
          <br />
          {address.additional}
          {address.additional && <br />}
          {`${address.postal_code} ${address.city}`}
        </>
      ) : (
        '-'
      )
    },
    {
      label: 'customers.form.dob',
      span: 3,
      content: dob ? moment(dob).format('ll') : '-'
    },
    {
      label: 'customers.form.birth_city',
      span: 3,
      content: birth_city || '-'
    },
    {
      label: 'customers.form.nationality',
      span: 3,
      content: nationality || '-'
    },
    {
      label: 'customers.form.rib.IBAN',
      span: 3,
      content:
        rib?.IBAN?.replace(rib?.IBAN?.substr(4, 25), ' XXXX XXXX XXXX XXXX') ||
        '-'
    },
    {
      label: 'customers.form.rib.BIC',
      span: 3,
      content: rib?.BIC?.replace(rib?.BIC?.substr(3, 10), 'XXXXX') || '-'
    },
    {
      label: 'customers.form.contract',
      span: 3,
      content: contract || (
        <Tag color="red">Ce client n&apos;a pas de contrat</Tag>
      )
    },
    {
      label: 'customers.form.comment',
      span: 3,
      content: comment || '-'
    }
  ];
};

export default showCustomerContent;
