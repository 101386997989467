import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import { CallsChart } from './CallsChart';
import { RetentionTable } from './RetentionTable';
import { RetentionRatesChart } from './RetentionRatesChart';
import { RetentionDetailedTable } from './RetentionDetailedTable';

const { RangePicker } = DatePicker;

export const ManagerAdvisorsStats = ({ advisors, withDetails }) => {
  const [range, setRange] = useState([moment().startOf('month'), moment()]);

  return (
    <ContentCustom background="none">
      <Row style={{ marginBottom: 16 }}>
        <RangePicker
          value={range}
          onChange={(v) => setRange(v)}
          allowClear={false}
        />
      </Row>
      <Row gutter={[8, 8]}>
        <Col sm={{ span: 24 }} xxl={12}>
          <CallsChart range={range} advisors={advisors} />
        </Col>
        <Col sm={{ span: 24 }} xxl={12}>
          <RetentionTable range={range} advisors={advisors} />
        </Col>
      </Row>
      {withDetails && (
        <Row>
          <Col sm={{ span: 24 }} xxl={12}>
            <RetentionRatesChart range={range} advisors={advisors} />
          </Col>
          <Col sm={{ span: 24 }} xxl={12}>
            <RetentionDetailedTable range={range} advisors={advisors} />
          </Col>
        </Row>
      )}
    </ContentCustom>
  );
};

ManagerAdvisorsStats.propTypes = {
  advisors: PropTypes.arrayOf(PropTypes.string),
  withDetails: PropTypes.bool
};

ManagerAdvisorsStats.defaultProps = {
  advisors: undefined,
  withDetails: false
};
