import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { useParams } from 'react-router-dom';
import {
  DatePicker,
  Form,
  Input,
  Select,
  Tag,
  Upload,
  AutoComplete
} from 'antd';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { userRoles, userPermissions } from '../../utils/constants/tagColors';
import useEmailPattern from '../../utils/emailPattern';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [permissionEnums, setPermissionEnums] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');
  const { id } = useParams();

  // This function convert the PDF to base64 format
  const fileToBase64 = async file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = e => reject(e);
    });

  const draggerProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async file => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  // Part responsible for handling autocomplete address
  const [suggestion, setSuggestion] = useState(
    []
  ); /* For autocomplete purpose */
  const [address, setAddress] = useState('');

  // Getting the postal code and at the same time loading it to the next fields down below itself.
  const load_datas = async value => {
    setSuggestion(value); /* For autocomplete purpose */
  };

  /* Part handling the datepicker UX part (Only select dates after the today's date and always be to the current date on panel opened */
  moment.locale('fr', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: 'seconde',
      ss: '%ss',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'une année',
      yy: '%d ans'
    }
  });
  const [panel_date, setPanel_date] = useState(moment());
  const [value_date, setValue_date] = useState(moment());
  const [select_date, setSelect_date] = useState(moment());
  const [formated_value, setFormated_value] = useState(moment());
  const [datas_date, setDatas_date] = useState({
    state: false,
    date: select_date
  });

  // Function useful for disabling by default all dates before the actual date
  const [className_datepicker, setClassName_datepicker] = useState([]);

  // Disable all dates after the current date
  const disabledDate = current => {
    // Can not select days before today
    return current > moment().subtract(1, 'day');
  };

  const get_state_opened = (value, data) => {
    if (value == true) {
      // Here we must come back to the default settled value
      setFormated_value(select_date);
      setDatas_date({ state: true, date: select_date });
    } else {
      setDatas_date({ state: false, date: select_date });
    }
  };

  const get_panel_change = (value, mode) => {
    setFormated_value(moment(value._d).format());
  };

  useEffect(() => {
    const diff = moment() - moment(formated_value);
    if (diff <= 110612704) {
      // Freeze the prev button of the datepicker
      setClassName_datepicker(['disable-arrow3', 'disable-arrow4']);
    } else {
      setClassName_datepicker([]);
    }
  }, [formated_value]);

  const formatDate = 'DD/MM/YYYY';

  const fields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['_id'],
      noLabel: true,
      input: <Input style={{ display: 'none' }} />
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, ...useEmailPattern()]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['date_of_birth'],
      input: (
        <DatePicker
          allowClear={false}
          style={{ width: '100%' }}
          value={select_date}
          defaultValue={select_date}
          format={formatDate}
          defaultPickerValue={moment()}
          onChange={(date, dateString) => {
            setSelect_date(date);
          }}
          disabledDate={disabledDate}
          onOpenChange={get_state_opened}
          onPanelChange={get_panel_change}
          showToday={false}
          renderExtraFooter={() =>
            `Âge:  
            ${
              moment().diff(select_date, 'days') >= 0
                ? moment(select_date, 'YYYY-MM-DD').fromNow() === 'seconde'
                  ? 'Pas renseigné'
                  : moment(select_date, 'YYYY-MM-DD').fromNow()
                : 0
            }`
          }
          dropdownClassName={className_datepicker.join(' ')}
        />
      )
    },
    {
      label: 'address.street',
      name: ['address', 'street'],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Renseignez une adresse ...',
                  className: 'location-search-input'
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={{
                  boxShadow:
                    '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
                  position: 'absolute',
                  top: '100%',
                  zIndex: 10
                }}
              >
                {loading && <div>Veuillez patienter...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span onClick={value => load_datas(suggestion)}>
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPermissionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/permissions' });
      setPermissionEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getPermissionEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64,
    suggestion /* For autocomplete purpose */,
    datas_date
  };
};

export default useFields;
