import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Major_graph_container as MajorGraphContainer,
  DonutChart as DonutChartStyled,
  Graph_containerAdvisorB as GraphContainerAdvisorB
} from '../../components_home/ExtraComponents';
import TerminatedChart from '../../charts_folder/TerminatedChart';
import { styles } from '../../styles_home/home_seller';

const DashboardPieGraph = ({ filterData }) => {
  const { t } = useTranslation();
  return (
    <MajorGraphContainer style={{ width: '100%', height: '100%' }}>
      <DonutChartStyled style={{ width: '100%', height: '100%' }}>
        <GraphContainerAdvisorB>
          <div style={styles.graph_header}>
            <p style={styles.graph_header_first}>
              {t('partners.show.cancellation_rate')}
            </p>
            <p style={styles.graph_header_second} />
          </div>
          <div style={styles.app_divider_container_donut} />
          <div style={{ cursor: 'pointer', marginTop: '20px' }}>
            <TerminatedChart terminated={filterData} />
          </div>
        </GraphContainerAdvisorB>
      </DonutChartStyled>
    </MajorGraphContainer>
  );
};

DashboardPieGraph.propTypes = {
  filterData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DashboardPieGraph;
