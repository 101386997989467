import React from 'react';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { useColumns } from './columns';

const ListInvoices = () => {
  const columns = useColumns();

  return (
    <SimpleResourceLandingLayout
      resourceName="invoices"
      columns={columns}
      populate="customer"
      deleteAction={false}
      editAction={false}
      showAction={false}
      onDoubleClickAction={false}
      withCreateButton={false}
    />
  );
};

export default ListInvoices;
