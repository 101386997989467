import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateAdvisor = ({ purpose }) => {
  const { fields } = useFields(purpose);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="advisors"
      resource="advisors"
      config={config}
    />
  );
};

CreateUpdateAdvisor.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateAdvisor;
