import React from 'react';
import PropTypes from 'prop-types';
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip as Tooltip_chart,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from 'bizcharts';
import EmptyChart from '../components_home/EmptyChart';

const Line_chart = ({ data, xAxis, yAxis, cols }) => {
  const axis = `${xAxis}*${yAxis}`;
  return (
    <>
      {!data.length ? (
        <EmptyChart height={307} />
      ) : (
        <Chart
          data={data}
          scale={cols}
          height={300}
          width={400}
          padding={[40, 10, 'auto', 'auto']}
          forceFit
        >
          <Legend />
          <Axis name={xAxis} />
          <Axis name={yAxis} />
          <Tooltip_chart
            crosshairs={{
              type: 'y'
            }}
          />
          <Geom
            type="line"
            position={axis}
            size={2}
            color={['description', '#e06e44']}
            shape={'smooth'}
          />
        </Chart>
      )}
    </>
  );
};

Line_chart.propTypes = {
  data: PropTypes.array,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  cols: PropTypes.object
};

Line_chart.defaultProps = {
  data: null,
  xAxis: null,
  yAxis: null,
  cols: null
};

export { Line_chart };
