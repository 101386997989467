import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Avatar,
  Divider,
  message,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  ShopOutlined
} from '@ant-design/icons';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import { ProfileSession, ContainerBody } from './styles';
import {
  DashboardLineGraph,
  DashboardPieGraph
} from '../home/home_types/HomeManager';
import ListStores from '../home/components_home/ListStores';
import ListTerminate from '../home/components_home/ListTerminate';
import { rationMonth } from '../../utils';

const ShowSeller = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [seller, setSeller] = useState();
  const [store, setStore] = useState(null);
  const [filterData, setFilterDataStats] = useState([]);
  const [filterDataTab, setFilterDataTab] = useState([]);
  const [datas_resiliated, setDatas_resiliated] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().format('YYYY-MM'),
    moment().format('YYYY-MM-DD')
  ]);

  useEffect(() => {
    const getSeller = async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/sellers/${id}`
        });
        setSeller(data);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      setIsLoading(false);
    };
    getSeller();
  }, [id]);

  useEffect(() => {
    const getStore = async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/stores/get/stores?title=${
            seller?.title === 'FNAC CONNECT NARBONNE'
              ? 'Fnac Connect Narbonne'
              : seller?.title
          }`
        });
        setStore(data);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      setIsLoading(false);
    };
    seller && getStore();
  }, [seller]);

  const getStats = async () => {
    setIsLoading(true);
    try {
      let url = `/home_manager/stats/stat?email=${
        user.email
      }&seller=${id}&start_date=${moment(dateRange[0]).format(
        'YYYY-MM-DD'
      )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
      if (user.role !== 'users:MANAGER') {
        url = `/home_manager/stats/stat?email=${
          store[0].email
        }&seller=${id}&start_date=${moment(dateRange[0]).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
      }
      const { data } = await dispatchAPI('GET', {
        url
      });
      setFilterDataStats(data);

      setIsLoading(false);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  const getStatsTab = async () => {
    setIsLoading(true);
    try {
      let url = `/home_manager/stats/tab?email=${
        user.email
      }&seller=${id}&start_date=${moment(dateRange[0]).format(
        'YYYY-MM-DD'
      )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
      if (user.role !== 'users:MANAGER') {
        url = `/home_manager/stats/tab?email=${
          store[0].email
        }&seller=${id}&start_date=${moment(dateRange[0]).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}`;
      }
      const { data } = await dispatchAPI('GET', {
        url
      });
      setFilterDataTab(data);
      const resil = [];
      data.map((item) => {
        resil.push({
          name: item.name,
          value: rationMonth(item.terminated, item.lastMonth)
        });
      });
      setDatas_resiliated(resil);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilter = useCallback(async () => {
    await getStats();
    await getStatsTab();
  }, [dateRange, store]);

  useEffect(() => {
    applyFilter();
  }, [applyFilter]);

  const deleteSeller = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/sellers/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <PageHeaderCustom
        title={t('sellers.show.title')}
        is_home
        extra={
          <>
            <Link to={{ pathname: `${routes.SELLERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteSeller}
              icon={<WarningOutlined />}
            >
              <Button type="danger">
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom ContentCustom background="none !important">
        <div
          style={{
            maxWidth: '1400px',
            margin: 'auto',
            display: 'flex',
            width: '100%'
          }}
        >
          <ProfileSession>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                shape="square"
                size={150}
                style={{ backgroundColor: '#fde3cf' }}
                icon={<ShopOutlined style={{ color: '#f56a007d' }} />}
              />
            </div>
            <div style={{ width: '80%', margin: 'auto' }}>
              <Divider />
            </div>
            <Skeleton active loading={isLoading} paragraph={{ rows: 1 }}>
              <p style={{ textAlign: 'center', marginBottom: '0.25rem' }}>
                <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                  {seller &&
                    `${capitalizeFirstLetter(
                      seller.last_name
                    )}  ${capitalizeFirstLetter(seller.first_name)}`}
                </span>
              </p>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  color: 'gray',
                  alignItems: 'center'
                }}
              >
                <p style={{ marginBottom: '0rem' }}>
                  <span>
                    {seller && formatPhoneNumberIntl(seller.phone_number)}
                  </span>
                </p>
                <p style={{ marginBottom: '0rem' }}>
                  <span>
                    {seller &&
                      `En activité depuis ${moment(seller.begin_date).format(
                        'MMMM YYYY'
                      )} `}
                  </span>
                </p>
              </div>
            </Skeleton>
          </ProfileSession>
          <ContainerBody>
            <Row justify="space-evenly" style={{ width: '100%' }}>
              {isLoading ? (
                <Skeleton active />
              ) : (
                <>
                  <Col span={24}>
                    <DashboardLineGraph
                      sel={false}
                      filterData={filterData}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  </Col>
{' '}
                  <Col span={24}>
                    <div style={{ width: '100%' }}>
                      {filterDataTab && <ListStores data={filterDataTab} />}
                    </div>
                  </Col>
                  <Col span={12}>
                    {datas_resiliated && (
                      <DashboardPieGraph filterData={datas_resiliated} />
                    )}
                  </Col>
                  <Col span={12} style={{ padding: 16 }}>
                    {filterDataTab && <ListTerminate data={filterDataTab} />}
                  </Col>
                </>
              )}
            </Row>
          </ContainerBody>
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowSeller;
