/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Col,
  Row,
  Tooltip,
  message,
  Modal,
  Table,
  Tag,
  Dropdown
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  SolutionOutlined,
  PlusOutlined
} from '@ant-design/icons';
import moment from 'moment';
import '../../../assets/styles/SCSS/comment.scss';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import { routes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import Yousign_snippet from '../../../components/Yousign/Yousign';
import Emails from '../../Emails/Emails';
import ShowContractAlt from './ShowContractAlt';
import ResiliateModal from './ResiliateModal';
import CreateUpdateRequest from '../../Requests/CreateUpdateRequest';

const { Column } = Table;

const ShowContract = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [contract, setContract] = useState({});
  const [state_signed, setState_signed] = useState('');
  const [cont_name, setCont_name] = useState('');
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(1);
  const authContext = useAuthContext();
  const [terminated, setTerminated] = useState();
  const [customer, setCustomer] = useState();
  const [customerName, setCustomerName] = useState();
  const [refSeller, setRefSeller] = useState();
  const [loaderElement, setLoaderElement] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [canTerminate, setCanTerminate] = useState(true);
  const [id_contract, setId_contract] = useState();
  const [startingDate, setStartingDate] = useState();
  const [invoicesList, setInvoicesList] = useState([]);
  const [reasonTermination, setReasonTermination] = useState();
  const [refresh, setRefresh] = useState(false);
  const fetchLinkedInvocies = useCallback(async (data) => {
    setIsLoading(true);
    try {
      const linkedInvoces = await dispatchAPI('GET', {
        url: `/invoices?customer=${data.customer._id}&contract=${data._id}&populate=customer`
      });
      setInvoicesList(linkedInvoces.data);
      setIsLoading(false);
    } catch (error) {
      message.error(`${error}`);
    }
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await dispatchAPI('GET', {
        url: `/contracts/${id}?populate=ref_type_contract customer`
      });
      setContract(response.data);
      setState_signed(response.data.signed);
      setCont_name(response.data.ref);
      setStartingDate(moment(response.data.begin_date));
      const contract_status = response.data.contract_status !== 'INPROGRESS';
      setTerminated(contract_status);
      setRefSeller(response.data.ref_seller);
      setCustomer(response.data.customer._id);
      setCustomerName(
        `${response.data.customer.first_name} ${response.data.customer.last_name}`
      );
      setId_contract(response.data._id);
      fetchLinkedInvocies(response.data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [refresh]);

  const deleteContract = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/contracts/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const [labelTerminate, setLabelTerminate] = useState(
    'Demande de résiliation'
  );
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [worth_resiliate, setWorth_resiliate] = useState(true);
  // const [id_contract, setId_contract] = useState();
  // const [startingDate, setStartingDate] = useState();

  const updateContractStatus = async () => {
    await dispatchAPI('PATCH', {
      url: `/contracts/${id}`,
      body: { signed: 'SIGNED', date_signed: Date.now() }
    });
  };
  // When the uploading process of the document from the api finishes, clear out the id and the code
  useEffect(() => {
    (async () => {
      const even_sign = window.location.search.split('=')[1];
      if (even_sign === 'signing_complete') {
        // A document has been successfully signed: Normally we should be able to retrieve it
        await updateContractStatus();
        window.location.replace(window.location.pathname);
        // We must display a success popup to notify that the document bas been completely signed
        message.success(
          `Le contrat ${contract &&
            contract.ref} a été bien signé et enregistré`
        );
      } else if (even_sign === 'signing_refused') {
        message.error('La signature du contract a été refusée');
        window.location.replace(window.location.pathname);
      } else if (even_sign === 'signing_refused') {
        message.error('Erreur lors de la signature du contrat');
        window.location.replace(window.location.pathname);
      }
    })();
  }, [window.location.search]);

  useEffect(() => {
    if (state_signed) {
      if (state_signed === 'SIGNED' && !terminated) {
        if (moment().subtract(startingDate, 'month') > 1) {
          if (user.role === 'users:VENDEUR') {
            setCanTerminate(false);
          } else if (user.role === 'users:TELECONSEILLER') {
            setCanTerminate(true);
          }
        } else {
          setLabelTerminate('Résilier');
        }
      } else if (terminated) {
        setLabelTerminate('Rétablir le contrat');
      }
    }
  }, [startingDate]);

  const terminateContract = async () => {
    await dispatchAPI('POST', {
      url: `/contracts/terminateContract/`,
      body: {
        ref_contract: id_contract,
        ref_advisor: user._id,
        reason: reasonTermination,
        advisorName: `${user.first_name} ${user.last_name}`
      }
    });
    message.success('La demande de résilisation a été bien transmise');
    setRefresh(!refresh);
  };

  const reActivateContract = async () => {
    await dispatchAPI('POST', {
      url: `/contracts/activateContract/`,
      body: {
        ref_contract: id_contract
      }
    });
    message.success('La demande de réactivation à bien été transmise');
    setRefresh(!refresh);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setLoaderElement();
  };

  const handleOk = async () => {
    terminateContract();
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    (async () => {
      const params = location.search.split('?')[1];
      const objectParams = new URLSearchParams(params);
      const outputValue = [...objectParams.values()];
      if (outputValue.length !== 0) {
        if (outputValue[0] === 'resiliation_completed') {
          let body;
          const reasonStory = 'resiliate';
          if (outputValue[1]) {
            body = {
              ref_user: user._id,
              ref_contract: id_contract,
              message: outputValue[1],
              reason: reasonStory
            };
          } else {
            body = {
              ref_user: user._id,
              ref_contract: id_contract,
              reason: reasonStory,
              message: '-'
            };
          }
          await dispatchAPI('POST', {
            url: '/advisors/postCall/',
            body: {
              ref_contract: id,
              ref_advisor: user._id,
              reason: 1,
              type: 'TERMINATE'
            }
          });
          await dispatchAPI('POST', { url: `/story/`, body });
          message.success(
            'La demande de résiliation a été établie avec succès !'
          );
        }
        if (outputValue[0] === 'resiliation_failed') {
          if (outputValue[1] === '3') {
            // The advisor convinced the user to not resiliate it's contract, rather to allocate one of it's benefits
            setLoaderElement(
              <CreateUpdateRequest purpose="create" id_benefit={id_contract} />
            );
          } else if (outputValue[1] === '4') {
            message.success("Le changement d'offre a été établi avec succès !");
            handleCancel();
          }
          await dispatchAPI('POST', {
            url: '/advisors/postCall/',
            body: {
              ref_contract: id_contract,
              ref_advisor: user._id,
              reason: parseInt(outputValue[1], 10),
              type: 'TERMINATE'
            }
          });
        }
        handleCancel();
      }
    })();
  }, [location]);

  const downloadSEPAXmlFile = async (element) => {
    let response = null;
    try {
      response = await dispatchAPI('GET', {
        url: `/contracts/generate-download-xml/${id}/${element._id}`
      });
      const blob = new Blob([response.data], {
        type: 'text/xml;charset=UTF-8'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `SEPA_file.xml`;
      a.click();
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const downloadContract = async () => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/yousign/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const checkIfCurrentMonthInvoicesIsGenerated = () => {
    if (invoicesList.length === 0) {
      return true;
    }
    let returnValue = false;
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    invoicesList.forEach((invoice) => {
      const number = invoice.number.split('-');
      const invoiceMonth = number[2];
      const invoiceYear = number[3];
      if (invoiceMonth === currentMonth && invoiceYear === currentYear) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const generateCurrentMonthInvoice = async () => {
    if (contract.signed !== 'SIGNED') {
      message.error("Action impossible. Le contrat n'est pas encore signé");
    } else {
      const contractId = id;
      const bodyData = {};
      // bodyData.number = 2;
      bodyData.linked_contract = contractId;
      bodyData.contract_type = contract.ref_type_contract._id;
      bodyData.subject = 'Paiment mensuel pour le contrat souscrit';
      bodyData.comment = 'Un commentaire';
      bodyData.total = contract.ref_type_contract.price;
      bodyData.linked_customer = contract.customer._id;
      try {
        await dispatchAPI('POST', {
          url: `/invoices/${id}/${contract.customer._id}`,
          body: bodyData
        });
        message.success('Facture générer avec succès !');
        await fetchLinkedInvocies(contract);
      } catch (error) {
        message.error('Erreur lors de la création de la facture.');
      }
    }
  };

  // Not using this button for the moment
  // Download is directly call from the invoices datatable
  const downloadSEPAFileButton = () => {
    return (
      <Popconfirm
        title={t('datatable.column.action.download.sepa-file')}
        okText={t('datatable.column.action.download.ok')}
        okButtonProps={{ danger: true }}
        cancelText={t('datatable.column.action.download.cancel')}
        onConfirm={() => downloadSEPAXmlFile()}
        icon={<WarningOutlined />}
      >
        <Button
          type="primary"
          style={{
            marginRight: '0.5rem',
            marginLeft: '0.5rem'
          }}
          className="sendButton"
        >
          {`${t('buttons.generate-xml')} `}
          <Tooltip title={t('buttons.generate-xml')}>
            <SolutionOutlined />
          </Tooltip>
        </Button>
      </Popconfirm>
    );
  };

  // ALl invoices for contract are generated when contract is created
  // Not need to have this button in showContract
  const generateCurrentInvoiceButton = () => {
    return (
      <Popconfirm
        title={t('datatable.column.action.generate.title')}
        okText={t('datatable.column.action.generate.ok')}
        okButtonProps={{ danger: true }}
        cancelText={t('datatable.column.action.generate.cancel')}
        onConfirm={() => generateCurrentMonthInvoice()}
        icon={<WarningOutlined />}
      >
        <Button
          type="primary"
          style={{
            marginRight: '0.5rem',
            marginLeft: '0.5rem'
          }}
          className="sendButton"
        >
          {`${t('buttons.generate-invoice')} `}
          <SolutionOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const displayInvoiceTag = (element) => {
    return (
      (element &&
        state_signed &&
        state_signed === 'SIGNED' &&
        ((new Date(element.emission_date).getTime() < new Date().getTime() && (
          <Tooltip title={t('buttons.download')}>
            <DownloadOutlined
              onClick={() => downloadSEPAXmlFile(element)}
              style={{ fontSize: 18 }}
            />
          </Tooltip>
        )) || <Tag>Facture non effective</Tag>)) || <Tag>Contrat non signé</Tag>
    );
  };

  const downloadInvoice = async (element) => {
    try {
      let response = null;
      response = await dispatchAPI('GET', {
        url: `/invoices/download/${element._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${element.number}.pdf`;
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const displayPDFInvoice = (element) => {
    return (
      (element &&
        state_signed &&
        state_signed === 'SIGNED' &&
        ((new Date(element.payment_date).getTime() < new Date().getTime() && (
          <Tooltip title={t('buttons.download')}>
            <DownloadOutlined
              onClick={() => downloadInvoice(element)}
              style={{ fontSize: 18 }}
            />
          </Tooltip>
        )) || <Tag>Facture non effective</Tag>)) || <Tag>Contrat non signé</Tag>
    );
  };

  const downloadFile = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/${id}`
      });
      // console.log(data.contentContract);
      const a = document.createElement('a');
      a.href = data.contentContract;
      a.download = 'contract.pdf';
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const listLinkedInvoices = () => {
    return (
      <div style={{ padding: '24px' }}>
        {/* <span>
          <h2>Historique des factures</h2>
          {checkIfCurrentMonthInvoicesIsGenerated() &&
            generateCurrentInvoiceButton()}
        </span> */}
        <Table
          dataSource={invoicesList}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {}, // click row
              onDoubleClick: (event) => {} // double click row
            };
          }}
        >
          <Column
            title={t('invoices.list.column.title.number')}
            dataIndex="title"
          />

          <Column
            title={t('invoices.list.column.title.payment_date')}
            dataIndex="payment_date"
            render={(date) => {
              return new Date(date).toLocaleString().split(',')[0];
            }}
          />
          <Column
            title={t('invoices.list.column.title.total')}
            dataIndex="total"
            render={(total) => {
              return `${total} €`;
            }}
          />
          {/* <Column
            title="Fichier XML"
            render={element => displayInvoiceTag(element)}
          />
          <Column
            title="Facture PDF"
            render={element => displayPDFInvoice(element)}
          /> */}
        </Table>
      </div>
    );
  };

  const getActionsButton = () => {
    return (
      <>
        <Dropdown
          overlay={
            <>
              {!terminated ? (
                <Popconfirm
                  title={t('datatable.column.action.terminate.title')}
                  okText={t('datatable.column.action.terminate.ok')}
                  okButtonProps={{ danger: true }}
                  cancelText={t('datatable.column.action.terminate.cancel')}
                  onConfirm={showModal}
                  icon={<WarningOutlined />}
                >
                  <Button type="danger" style={{ marginRight: '0.5rem' }}>
                    {labelTerminate}
                    <CloseCircleOutlined />
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title={t('datatable.column.action.activate.title')}
                  okText={t('datatable.column.action.activate.ok')}
                  okButtonProps={{ danger: true }}
                  cancelText={t('datatable.column.action.activate.cancel')}
                  onConfirm={showModal}
                  icon={<WarningOutlined />}
                >
                  <Button type="add" style={{ marginRight: '0.5rem' }}>
                    {`${t('buttons.activate')} `}
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                  </Button>
                </Popconfirm>
              )}
              {user.role === 'admins:DIRECTION' && (
                <>
                  {state_signed && state_signed === 'PENDING' ? (
                    <Yousign_snippet
                      id={id}
                      route_doc={`/contracts/download/${id}?populate=ref_type_contract`}
                      label_button_initial_state="Faire signer"
                      label_button_loading_state={t(
                        'buttons.loading_sign_self'
                      )}
                      contract_name={cont_name}
                      yousignClicked
                    />
                  ) : null}
                  <Popconfirm
                    title={t('datatable.column.action.download.title')}
                    okText={t('datatable.column.action.download.ok')}
                    okButtonProps={{ danger: true }}
                    cancelText={t('datatable.column.action.download.cancel')}
                    onConfirm={() => downloadContract()}
                    icon={<WarningOutlined />}
                  >
                    <Button
                      type="primary"
                      style={{
                        marginRight: '0.5rem',
                        marginLeft: '0.5rem'
                      }}
                      className="sendButton"
                    >
                      {`${t('buttons.download')} `}
                      {state_signed && state_signed === 'PENDING' ? (
                        <DownloadOutlined />
                      ) : (
                        <SolutionOutlined />
                      )}
                    </Button>
                  </Popconfirm>
                </>
              )}
              <Emails />
            </>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <div>
            <Button type="primary" className="sendButton">
              Actions
              <PlusOutlined size="large" />
            </Button>
          </div>
        </Dropdown>
      </>
    );
  };

  switch (user.role) {
    case 'users:VENDEUR':
    case 'users:PARTENAIRE':
    case 'users:MANAGER':
      return (
        <>
          <>
            <PageHeaderCustom
              title={`${t('contracts.show.title')} - ${customerName}`}
              withSubRoutes
              extra={
                <>
                  {state_signed && state_signed === 'PENDING' ? (
                    <>
                      <Yousign_snippet
                        id={id}
                        route_doc={`/contracts/download/${id}?populate=ref_type_contract`}
                        label_button_initial_state={t(
                          'buttons.sign_self_manuel'
                        )}
                        label_button_loading_state={t(
                          'buttons.loading_sign_self'
                        )}
                        contract_name={cont_name}
                        yousignClicked
                      />
                    </>
                  ) : null}

                  {(!terminated &&
                    canTerminate &&
                    user.role !== 'users:VENDEUR' && (
                      <Popconfirm
                        title={t('datatable.column.action.terminate.title')}
                        okText={t('datatable.column.action.terminate.ok')}
                        okButtonProps={{ danger: true }}
                        cancelText={t(
                          'datatable.column.action.terminate.cancel'
                        )}
                        onConfirm={() => {
                          setIsModalVisible(true);
                        }}
                        icon={<WarningOutlined />}
                      >
                        {user.role !== 'users:MANAGER' &&
                          user.role !== 'users:PARTENAIRE' && (
                            <Button type="danger" danger>
                              {labelTerminate}
                              <CloseCircleOutlined />
                            </Button>
                          )}
                      </Popconfirm>
                    )) ||
                    (user.role !== 'users:VENDEUR' && (
                      <Popconfirm
                        title={t('datatable.column.action.activate.title')}
                        okText={t('datatable.column.action.activate.ok')}
                        okButtonProps={{ danger: true }}
                        cancelText={t(
                          'datatable.column.action.activate.cancel'
                        )}
                        onConfirm={reActivateContract}
                        icon={<WarningOutlined />}
                      >
                        <Button type="add">
                          {`${t('buttons.activate')} `}
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        </Button>
                      </Popconfirm>
                    ))}

                  {authContext.checkShouldDisplayActionItem(
                    'contracts',
                    'delete'
                  ) && (
                    <Link
                      to={{
                        pathname: `${routes.CONTRACTS}/edit/${id}`
                      }}
                    >
                      <Button type="primary">
                        {`${t('buttons.edit')} `}
                        <EditOutlined />
                      </Button>
                    </Link>
                  )}
                  {authContext.checkShouldDisplayActionItem(
                    'contracts',
                    'delete'
                  ) && (
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ danger: true }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={deleteContract}
                      icon={<WarningOutlined />}
                    >
                      <Button type="primary" danger>
                        {`${t('buttons.delete')} `}
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  )}
                  {state_signed && state_signed !== 'PENDING' ? (
                    <Popconfirm
                      title={t('datatable.column.action.download.title')}
                      okText={t('datatable.column.action.download.ok')}
                      okButtonProps={{ danger: true }}
                      cancelText={t('datatable.column.action.download.cancel')}
                      onConfirm={downloadFile}
                      icon={<WarningOutlined />}
                    >
                      <Button type="primary">
                        {`${t('buttons.download')} `}
                        {state_signed && state_signed === 'PENDING' ? (
                          <DownloadOutlined />
                        ) : (
                          <SolutionOutlined />
                        )}
                      </Button>
                    </Popconfirm>
                  ) : null}
                </>
              }
            />
            <ContentCustom>
              <Row>
                <Col span={24}>
                  <Skeleton loading={isLoading}>
                    {reload && (
                      <DescriptionList data={listContent(contract)} translate />
                    )}
                  </Skeleton>
                </Col>
              </Row>
            </ContentCustom>
          </>
          <Modal
            title="Demande de résiliation"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
            className="modalBenefit"
          >
            {loaderElement || (
              <ResiliateModal
                validation_func={handleOk}
                setReasonTermination={setReasonTermination}
                id_contract={id_contract}
                ref_seller={refSeller}
                ref_customer={customer}
              />
            )}
          </Modal>
        </>
      );
    default:
      return (
        <>
          <>
            <PageHeaderCustom
              title={`${t('contracts.show.title')} - ${customerName}`}
              withSubRoutes
              is_home
            />
            <div>
              <ContentCustom background="none !important">
                {/* TODO: removed the extra which was the actions button */}
                <ShowContractAlt extra={getActionsButton()} />
              </ContentCustom>
              {listLinkedInvoices()}
            </div>
          </>
          <Modal
            title="Demande de résiliation"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
            className="modalBenefit"
          >
            {loaderElement || (
              <ResiliateModal
                validation_func={handleOk}
                setReasonTermination={setReasonTermination}
                id_contract={id_contract}
                ref_seller={refSeller}
                ref_customer={customer}
              />
            )}
          </Modal>
        </>
      );
  }
};

export default ShowContract;
