import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Divider, Button, Popconfirm, Skeleton, Steps, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import format from 'date-fns/format';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import { routes, subRoutes } from '../../utils/constants/routes';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import useErrorMessage from '../../utils/ErrorMessage';
import Documents from './Documents';
import moment from 'moment';

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ShowRequest = ({ purpose }) => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [benefit, setBenefit] = useState({});
  const { dispatchAPI, checkShouldDisplayActionItem, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { Step } = Steps;
  const [state, setState] = useState();
  const [generalSteps, setGeneralSteps] = useState([]);
  const [statusDevice, setStatusDevice] = useState();
  const [statusValidation, setStatusValidation] = useState();
  const [disabled, setdisabled] = useState(true);
  const [first_status, setFirstStatus] = useState();
  const [alert, setAlert] = useState();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await dispatchAPI('GET', {
        url: `/requests/${id}`
      });
      setBenefit(data.data);
      const enums_data = await dispatchAPI('GET', {
        url: `/requests/enumsBenefits`
      });
      if (data.data.steps === 2) {
        setGeneralSteps([
          { index: 0, value: 'envoi en cours' },
          { index: 1, value: 'réceptionné par le client' }
        ]);
      } else {
        setGeneralSteps([
          { index: 0, value: 'en attente de facture' },
          { index: 1, value: 'facture reçue' },
          { index: 2, value: 'remboursement en cours' },
          { index: 3, value: 'facture payée' }
        ]);
      }
      setStatusValidation(enums_data.data.status_validation);
      if (data.data.status_activation === 'ACCEPTED') {
        setdisabled(false);
        setFirstStatus('process');
      } else {
        setdisabled(true);
        setFirstStatus('wait');
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  const getEnums = async () => {};

  useEffect(() => {
    (async () => {
      await getEnums();
      await fetchData();
    })();
  }, []);

  const deleteBenefits = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/requests/deleteByEmail/${id}/${user.email}`
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  // TODO: Integrate new XML generation
  const downloadPaymentXML = async body => {
    const { data } = await dispatchAPI('GET', {
      url: `/requests/generate/${id}`,
      body
    });
    const blob = new Blob([data], {
      type: 'text/xml;charset=UTF-8'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${format(new Date(), 'yyyyMMdd_hhmmss')}_prlvt.xml`;
    a.click();
  };

  const onChange = async current => {
    try {
      const body = {
        ...benefit,
        status_validation: generalSteps[current].value
      };
      if (benefit.steps === 4) {
        if (current === 2) {
          downloadPaymentXML(body);
        }
      }
      await dispatchAPI('PATCH', {
        url: `/requests/${id}`,
        body
      });
      await fetchData();
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const onClickValidate = async () => {
    try {
      const days =
        moment().diff(moment(benefit.ref_contract.begin_date), 'days') % 365;
      const remainingDays = benefit.ref_benefitType.startingPeriod - days;
      if (remainingDays > 0 && benefit.title_benefit === 'Offre de bienvenue')
        setAlert(
          `Le client n'est pas éligible à cette offre il reste ${remainingDays} jours`
        );
      else {
        await dispatchAPI('PATCH', {
          url: `/requests/${id}`,
          body: {
            _id: benefit._id,
            status_activation: statusValidation[2]
          }
        });
        setdisabled(false);
        setFirstStatus('process');
        fetchData();
      }
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const onClickRefuse = async () => {
    try {
      const body = { _id: benefit._id, status_activation: statusValidation[1] };
      await dispatchAPI('PATCH', {
        url: `/requests/${id}`,
        body
      });
      setdisabled(true);
      setFirstStatus('wait');
      fetchData();
    } catch (e) {
      if (e.response) message(e.response);
    }
  };

  const displayInfoMsg = () => {
    if (benefit.status_activation === 'WAITING') {
      return <div>{t('requests.status.waiting')}</div>;
    }
    if (benefit.status_activation === 'ACCEPTED') {
      return <div>{t('requests.status.accepted')}</div>;
    }
    if (benefit.status_activation === 'REFUSED') {
      return <div>{t('requests.status.refused')}</div>;
    }
  };

  return (
    <>
      {alert && <Alert message={alert} type="error" />}
      <PageHeaderCustom
        title={t('benefits.show.title')}
        withSubRoutes
        extra={
          !purpose && (
            <div style={{ marginTop: 16 }}>
              {benefit.status_activation === 'WAITING' &&
                checkShouldDisplayActionItem('requests', 'validate') && (
                  <>
                    <Button
                      type="add"
                      onClick={onClickValidate}
                      style={{ marginRight: 10 }}
                    >
                      {t('buttons.validate')}
                      <CheckCircleOutlined />
                    </Button>
                    <Button
                      type="danger"
                      onClick={onClickRefuse}
                      style={{ marginRight: 10 }}
                    >
                      {t('buttons.refuse')}
                      <CloseCircleOutlined />
                    </Button>
                  </>
                )}
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteBenefits}
                icon={<WarningOutlined />}
              >
                <Button type="link" type="danger">
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          )
        }
      />
      <ContentCustom>
        <Alert
          banner
          message={displayInfoMsg()}
          style={{ marginBottom: '20px' }}
        />
        <Steps
          current={generalSteps.findIndex(
            item => item.value === benefit.status_validation
          )}
          onChange={onChange}
          status={first_status}
          style={{ marginBottom: 24 }}
        >
          {generalSteps.map(stepBenefit => (
            <Step
              key={stepBenefit.index}
              title={capitalizeFirstLetter(stepBenefit.value)}
              disabled={disabled}
            />
          ))}
        </Steps>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(benefit)} translate />
        </Skeleton>
        <Divider orientation="left" style={{ marginTop: 48 }}>
          {t('benefits.show.filesList')}
        </Divider>
        <Documents dataSource={benefit.files} benefitId={id} />
      </ContentCustom>
    </>
  );
};
export default ShowRequest;
