import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/plots';
import { Empty, message, Spin } from 'antd';
import useAuthContext from '../../contexts/AuthContext';

export const RetentionRatesChart = ({ range, advisors }) => {
  const { dispatchAPI } = useAuthContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: d } = await dispatchAPI('GET', {
        url: `/manager-advisors/retention-stats?start=${range[0]}&end=${
          range[1]
        }${
          advisors ? `&advisors=${advisors.map((advisor) => advisor._id)}` : ''
        }`
      });

      setData(d);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
    setIsLoading(false);
  }, [range, advisors]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const config = {
    data,
    xField: 'supervisor',
    yField: 'retention_rate',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    },
    scrollbar: {
      type: 'horizontal'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false
      }
    ]
  };

  return (
    <Spin spinning={isLoading}>
      {data.length ? <Column {...config} /> : <Empty />}
    </Spin>
  );
};

RetentionRatesChart.propTypes = {
  range: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape({}))
};

RetentionRatesChart.defaultProps = {
  advisors: undefined
};
