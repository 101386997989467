import React, { useState, useEffect, useCallback } from 'react';
import { message, Spin } from 'antd';
import { motion } from 'framer-motion';
import useAuthContext from '../../../contexts/AuthContext';
import SearchDatas from '../components_home/SearchDatas';
import { SearchContainer } from '../components_home/ExtraComponents';
import { ManagerAdvisorsStats } from '../../ManagerAdvisors/ManagerAdvisorsStats';

const HomeManagerAdvisor = () => {
  const { dispatchAPI, user } = useAuthContext();
  const [searchResults, setSearchResults] = useState([]);
  const [advisors, setAdvisors] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getDatas = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/home_seller/getSearchResults/`
      });
      setSearchResults(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  }, []);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const getAdvisors = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/advisors?ref_manager_advisor=${user._id}`
      });
      setAdvisors(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAdvisors();
  }, [getAdvisors]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Spin tip="Veuillez patienter..." size="large" />
        </div>
      ) : (
        <div
          style={{
            padding: '20px',
            marginTop: '0rem',
            paddingTop: '2rem',
            maxWidth: '1600px',
            margin: 'auto'
          }}
        >
          <motion.div
            animate={{ width: ['0%', '100%'], opacity: [0, 1] }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchContainer className="Search_container">
                <SearchDatas
                  searchResults={searchResults}
                  placeholder="Rechercher des clients, des contrats"
                />
              </SearchContainer>
            </div>
          </motion.div>
          {advisors && <ManagerAdvisorsStats advisors={advisors} withDetails />}
        </div>
      )}
    </>
  );
};

export default HomeManagerAdvisor;
