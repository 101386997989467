import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Table } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import { roundUp } from '../../utils';

export const RetentionTable = ({ range }) => {
  const { dispatchAPI } = useAuthContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: d } = await dispatchAPI('GET', {
        url: `/manager-advisors/retention-stats-manager?start=${range[0]}&end=${range[1]}`
      });
      setData(d);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
    setIsLoading(false);
  }, [range]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <Table
      dataSource={data}
      loading={isLoading}
      scroll={{ x: true }}
      columns={[
        { title: 'Superviseurs', dataIndex: 'supervisor', key: 'supervisor' },
        {
          title: "Volume d'appel à l'année",
          dataIndex: 'calls_per_year',
          key: 'calls_per_year',
          align: 'right'
        },
        {
          title: "Volume d'appel sur la période en cours",
          dataIndex: 'calls_current_period',
          key: 'calls_current_period',
          align: 'right'
        },
        {
          title: 'Période -1',
          dataIndex: 'calls_previous_period',
          key: 'calls_previous_period',
          align: 'right'
        },
        {
          title: 'Evolution A-1',
          dataIndex: 'calls_previous_year',
          key: 'calls_previous_year',
          align: 'right'
        },
        {
          title: 'Taux de Rétention',
          dataIndex: 'retention_rate',
          key: 'retention_rate',
          align: 'right',
          render: (rate) => `${roundUp(rate)}%`
        }
      ]}
    />
  );
};

RetentionTable.propTypes = {
  range: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
