import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateCommission = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        liberation_date: data.liberation_date && moment(data.liberation_date)
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="commissions"
      resource="commissions"
      withSubRoutes
      config={config}
    />
  );
};

CreateUpdateCommission.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCommission;
