import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IBAN from 'iban';
import { DatePicker, Input, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import PlacesAutocomplete from 'react-places-autocomplete';
import { isValidPhoneNumber } from 'react-phone-number-input';
import countryListMap from 'country-flags-dial-code';
import { genders } from '../../../utils/constants/misc';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import emailField from '../../../utils/formFields/emailField';

const ibantools = require('ibantools');

const { Option } = Select;

const useFields = (purpose) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState();
  const [mails, setMails] = useState([]);
  const { id } = useParams();
  const [value_date, setValueDate] = useState(moment().subtract('18', 'years'));
  const [formated_value, setFormated_value] = useState(value_date);
  const [datas_date, setDatas_date] = useState({
    state: false,
    date: value_date
  });
  const [defaultCode, setDefaultCode] = useState('+33');
  const [defaultIban, setDefaultIban] = useState('');

  // Function useful for disabling by default all dates before the actual date
  const [className_datepicker, setClassName_datepicker] = useState([]);

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers`
      });
      setCustomers(data);
      console.log('data:', data);
      data.forEach((json_array) => {
        if (json_array._id === id) {
          const parsed_date = moment().format(json_array.dob, 'YYYY-MM-DD');
          setValueDate(parsed_date);
          setDefaultIban(json_array.rib.IBAN);
        }
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomer = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customers/${id}`
      });
      setCurrentCustomer(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getCustomer();
      })();
    }
  }, []);

  const disabledDate = (current) => moment().diff(current, 'years') >= 18;

  /* Part responsible for handling inputs dealing with iban && bic */
  const checkIBAN = (iban) => {
    let exist = false;
    const dataFilter = customers.filter(
      (customer) =>
        customer.rib.IBAN ===
          iban
            .replace(/\s+/g, '')
            .replace(/(.{4})/g, '$& ')
            .toUpperCase() && customer._id !== id
    );
    if (!dataFilter.length) {
      exist = true;
    }
    return exist;
  };

  const validate_iban = (_, value) => {
    // Checking if it's a valid iban
    const value_formatted = value.replace(/ /g, '');
    if (!IBAN.isValid(value_formatted) && value_formatted.trim().length > 0) {
      return Promise.reject(new Error('IBAN non valide'));
    }
    // Checking if the currently entered iban already exists in the db
    if (!checkIBAN(value_formatted) && value_formatted.trim().length > 0) {
      return Promise.reject(
        new Error("IBAN déja utilisé par quelqu'un d'autre")
      );
    }
    return Promise.resolve();
  };

  const validate_bic = (_, value) => {
    const value_formatted = value.replace(/ /g, '');
    if (!ibantools.isValidBIC(value_formatted) && value_formatted.length > 0) {
      return Promise.reject(new Error('BIC non valide'));
    }
    return Promise.resolve();
  };

  const getMails = async () => {
    const { data } = await dispatchAPI('GET', { url: '/users/mails/' });
    setMails(data);
  };

  useEffect(() => {
    (async () => {
      await getCustomers();
      await getMails();
    })();
  }, []);

  const countries_code = countryListMap.getCountryListMap();
  let code_array = [];
  Object.keys(countries_code).forEach((key) => {
    code_array.push(countries_code[key].dialCode);
  });
  const compare_code = (a, b) => {
    const a_num = parseInt(a.substring(1), 10);
    const b_num = parseInt(b.substring(1), 10);
    return a_num - b_num;
  };
  code_array.sort(compare_code);
  code_array = [...new Set(code_array)];

  const validate_phone = (_, value) => {
    if (
      (!isValidPhoneNumber(defaultCode + value) && value.length > 0) ||
      value.charAt(0) === '0'
    ) {
      return Promise.reject(new Error('Numéro non valide'));
    }

    return Promise.resolve();
  };

  const setChange_iban = (value) => {
    // Remove all spaces between characters before setting again that one with the formatted value
    const value_formatted = value.replace(/ /g, '');
    setDefaultIban(value_formatted);
  };

  // Part responsible for handling autocomplete address
  const [suggestion, setSuggestion] = useState(
    []
  ); /* For autocomplete purpose */
  const [address, setAddress] = useState('');

  // Getting the postal code and at the same time loading it to the next fields down below itself.
  const load_datas = (value) => {
    setSuggestion(value); /* For autocomplete purpose */
  };

  /* Part handling the datepicker UX part (Only select dates after the today's date and always be to the current date on panel opened */
  moment.locale('fr', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: 'seconde',
      ss: '%ss',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'une année',
      yy: '%d ans'
    }
  });

  const disabledDate_birth = (current) => {
    // Can not select days before today
    return current > moment().subtract(1, 'day');
  };

  const get_state_opened = (value, data) => {
    if (value === true) {
      // Here we must come back to the default settled value
      setFormated_value(value_date);
      setDatas_date({ state: true, date: value_date });
    } else {
      setDatas_date({ state: false, date: value_date });
    }
  };

  const get_panel_change = (value, mode) => {
    setFormated_value(moment(value._d).format());
  };

  useEffect(() => {
    const diff = moment() - moment(formated_value);
    if (diff <= 110612704) {
      // Freeze the prev button of the datepicker
      setClassName_datepicker(['disable-arrow3', 'disable-arrow4']);
    } else {
      setClassName_datepicker([]);
    }
  }, [formated_value]);

  const formatDate = 'DD/MM/YYYY';

  const fields = [
    {
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select>
          {genders.map((title) => (
            <Option key={title} value={title}>
              {t(`customers.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['dob'],
      input: (
        <DatePicker
          style={{ width: '100%' }}
          allowClear={false}
          value={value_date}
          defaultValue={value_date}
          format={formatDate}
          defaultPickerValue={moment()}
          onChange={(date, dateString) => {
            setValueDate(date);
          }}
          disabledDate={disabledDate_birth}
          onOpenChange={get_state_opened}
          onPanelChange={get_panel_change}
          showToday={false}
          renderExtraFooter={() =>
            `Âge:
            ${
              moment().diff(value_date, 'days') >= 0
                ? moment(value_date, 'YYYY-MM-DD').fromNow() === 'seconde'
                  ? 'Pas renseigné'
                  : moment(value_date, 'YYYY-MM-DD').fromNow()
                : 0
            }`
          }
          dropdownClassName={className_datepicker.join(' ')}
        />
      ),
      rules: [
        { required: true },
        {
          validator: (_, value) =>
            disabledDate(value)
              ? Promise.resolve()
              : Promise.reject(new Error('Plus de 18ans requis'))
        }
      ]
    },
    {
      name: ['birth_city'],
      rules: [{ required: true }]
    },
    emailField([...customers, ...mails], currentCustomer?.email, purpose, true),
    {
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue={defaultCode}
          >
            <Select
              style={{ width: '25%' }}
              onChange={(e) => setDefaultCode(e)}
            >
              {code_array.map((code) => (
                <Option key={code} value={code}>
                  {code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['phone_number', 'number']}
            rules={[{ required: true }, { validator: validate_phone }]}
            initialValue={currentCustomer?.phone_number.slice(3)}
          >
            <Input type="text" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['address', 'street'],
      rules: [{ required: true }],
      input: (
        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: 'Renseignez une adresse ...',
                  className: 'location-search-input'
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={{
                  boxShadow:
                    '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
                  position: 'absolute',
                  top: '100%',
                  zIndex: 10
                }}
              >
                {loading && <div>Veuillez patienter...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px',
                        paddingLeft: '10px'
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span onClick={(value) => load_datas(suggestion)}>
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )
    },
    {
      name: ['address', 'number'],
      input: (
        <Form.Item
          noStyle
          name={['address', 'number']}
          initialValue={currentCustomer?.address.number}
        >
          <Input type="number" />
        </Form.Item>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['address', 'additional'],
      input: (
        <Form.Item
          noStyle
          name={['address', 'additional']}
          initialValue={currentCustomer?.address.additional}
        >
          <Input type="text" />
        </Form.Item>
      )
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['rib', 'IBAN'],
      rules: [{ required: true }, { validator: validate_iban }],
      input: (
        <Input
          value={defaultIban}
          onChange={(e) => setChange_iban(e.target.value)}
        />
      )
    },
    {
      name: ['rib', 'BIC'],
      rules: [{ required: true }, { validator: validate_bic }]
    },
    {
      name: ['comment']
    }
  ];

  // Configure the differents steps and associated fields indexes
  const steps = [
    {
      title: 'Informations personnelles',
      prev: 0,
      next: 5
    },
    {
      title: 'Coordonnées',
      prev: 5,
      next: 12
    },
    {
      title: 'Informations bancaires',
      prev: 12,
      next: 14
    },
    {
      title: 'Commentaires',
      prev: 14,
      next: 15
    }
  ];

  return {
    fields,
    suggestion,
    datas_date,
    default_iban: defaultIban,
    steps
  };
};

useFields.PropTypes = {
  purpose: PropTypes.string.isRequired
};
export default useFields;
