import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Steps, message } from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import ContentCustom from '../ContentCustom/ContentCustom';
import PageHeaderCustom from '../PageHeader/PageHeader';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import DocumentScan from '../../routes/Documents/Scan/DocumentScan';
import { ScanContextProvider } from '../../routes/Documents/Scan/ScanContext';

const modes = {
  FORM: 'FORM',
  SCAN: 'SCAN'
};

const CreateUpdateContainer = ({
  purpose,
  fields,
  loadingFields,
  resource,
  baseUrl,
  config,
  formExtra,
  tradKey,
  withSubRoutes,
  submitLabel,
  customSubmit,
  isParentLoading,
  extraValues,
  setContract,
  updatedValues /* Populate postal code, street... from adresses */,
  datasDate /* For datepicker purpose: select date between ranges and disable others */,
  steps /* To transform a form into steps instantaneously */,
  isTrackable /* Track the evolution of a form submission as long as we goes */,
  isVotable /* Can vote after updated/posted in the db */,
  legalInfos /* Populate dynamically legal informations like siren, siret, ... */,
  partner_name /* Getting a name to populate it on a foreign resource */,
  extraQuery /* use if you need to pass aditionnal info */,
  filterParameter /* used for filtering with aqp */,
  populate /** used as the populate parameter for aqp */
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [mode] = useState(modes.FORM);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { onGetResource, onCreateResource, onUpdateResource } = config;
  const { Step } = Steps;

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${baseUrl}/${id}`,
        body:
          onUpdateResource && onUpdateResource.setBody
            ? onUpdateResource.setBody(body)
            : body
      });
      if (!isVotable && resource === 'contracts') {
        history.push(`?event=resiliation_failed&value=4`);
      } else {
        history.goBack();
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const createResource = async (body) => {
    try {
      if (extraValues)
        body[Object.keys(extraValues)[0]] =
          extraValues[Object.keys(extraValues)[0]];
      const { data } = await dispatchAPI('POST', {
        url: `${baseUrl}${extraQuery ? `?${extraQuery}` : ''}`,
        body:
          onCreateResource && onCreateResource.setBody
            ? onCreateResource.setBody(body)
            : body
      });
      if (resource === 'customers') {
        window.location.search.split('=')[1] === 'dashboard'
          ? history.push(`/contracts/create?from=dashboard&id=${data[0]._id}`)
          : history.goBack();
      } else if (resource === 'contracts') {
        window.location.search.split('=')[0] === '?from'
          ? history.push(`/contracts/show/${data[0]._id}`)
          : history.push('/contracts');
      } else if (isVotable && resource === 'benefits') {
        history.push(`?event=allocation_benefit&comment=${data[0].comment}`);
      } else if (
        resource === 'sellers' ||
        resource === 'advisors' ||
        resource === 'stores' ||
        resource === 'partners'
      ) {
        message.success(data.message1);
        message.success(data.message2);
        history.goBack();
      } else {
        history.goBack();
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message)
        message.error(`${e.response.data.message}`);
      else if (e.response) message.error(`${e.response.status}`);
    }
  };
  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${baseUrl}/${id}${
          extraQuery ? `?${extraQuery}&` : ''
        }${filterParameter || ''}${
          populate !== null ? `?populate=${populate}` : ''
        }`
      });

      form.setFieldsValue(
        onGetResource && onGetResource.setFields
          ? await onGetResource.setFields(data)
          : data
      );
      if (setContract) {
        setContract(data);
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  }, [purpose, id, loadingFields]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      if (!loadingFields)
        (async () => {
          await getResource();
        })();
    }
  }, [getResource]);

  const [readyToSubmit, setReadyToSubmit] = useState(true);

  const handleSubmit = async () => {
    await form.validateFields();
    if (readyToSubmit) {
      const values = form.getFieldValue();
      setReadyToSubmit(false);
      if (customSubmit) return customSubmit(values);
      if (purpose === 'edit') await updateResource(values);
      if (purpose === 'create') await createResource(values);
    }
  };

  /* Autocomplete for address street and phone numnber */
  const process_address = async (value) => {
    const [place] = await geocodeByPlaceId(value.placeId);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    const final_post_code = parseInt(postalCode);
    const output = {
      street_name: null,
      street_number: null,
      post_code: null,
      city: null
    };

    !isNaN(final_post_code)
      ? (output.post_code = final_post_code)
      : (output.post_code = 0); /* Postal code */

    const tb_address = value.description.split(', ');
    const num = parseInt(tb_address[0].split(' ')[0]);
    !isNaN(num)
      ? (output.street_number = num)
      : (output.street_number = 0); /* Street number */

    output.city = `${tb_address[1]}, ${tb_address[2]} ${
      tb_address[3] != undefined ? `, ${tb_address[3]}` : ''
    } ${tb_address[4] != undefined ? `, ${tb_address[4]}` : ''} `; /* City */

    return output;
  };

  const [newAddress, setNewAdress] = useState([]);

  // Check first the context: The route for modifying a particular user's data ord customer's data
  if (
    (purpose === 'edit' || purpose === 'create') &&
    resource !== 'partners' &&
    resource !== 'sellers' &&
    resource !== 'contracts' &&
    resource !== 'advisors' &&
    resource !== 'manager-advisors'
  ) {
    // Watch for unexpected modifications on a street address

    if (Object.keys(updatedValues).length > 0) {
      // We received datas from autocomplete address street
      // Process them and load them to the appropriated fields if necessary
      process_address(updatedValues).then((res) => {
        // Load the associated values in the street, postal code and city's field
        const fields = form.getFieldValue();
        const { address } = fields;
        address.number = res.street_number;
        address.postal_code = res.post_code;
        address.city = res.city.trim();
        setNewAdress(address);
      });
    }
  }

  useEffect(() => {
    if (legalInfos) {
      form.setFieldsValue(legalInfos);
    }
  }, [legalInfos]);

  useEffect(() => {
    if (Object.keys(newAddress).length > 0) {
      form.setFieldsValue(newAddress);
    }
  }, [newAddress]);

  /* Handle the datepicker form */
  useEffect(() => {
    if (resource === 'contracts') {
      form.setFieldsValue({
        begin_date: moment()
      });
    } else if (resource === 'customers') {
      form.setFieldsValue({
        dob: moment().subtract('18', 'years')
      });
    }
  }, []);
  useEffect(() => {
    if (purpose === 'edit' || purpose === 'create') {
      // Checking if the datepicker is being closed while no date modifications has been settled on it
      if (datasDate.state == true) {
        if (resource == 'contracts') {
          form.setFieldsValue({
            begin_date: moment(datasDate.date)
          });
        } else if (resource === 'users') {
          form.setFieldsValue({
            date_of_birth: moment(datasDate.date)
          });
        } else if (resource === 'customers') {
          form.setFieldsValue({
            dob: moment(datasDate.date)
          });
        }
      }
    }
  }, [datasDate.state]);

  useEffect(() => {
    if (resource === 'stores') {
      form.setFieldsValue({
        partner_infos: partner_name
      });
    }
  }, [partner_name]);

  const [current, setCurrent] = useState(0);
  const [pairs, setPairs] = useState(steps ? steps[0] : null);
  const [fields_form, setFields_form] = useState({});
  const next = async () => {
    await form.validateFields();
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (steps) {
      setPairs(steps[current]);
    }
  }, [current]);

  return (
    <>
      {isTrackable && (
        <PageHeaderCustom
          title={
            resource === 'stores' && purpose === 'create'
              ? t(`${resource}.form.alt_title`)
              : resource === 'stores' && purpose === 'edit'
              ? t(`${resource}.form.alt_edit`)
              : t(`${resource}.form.title.${purpose}`)
          }
          withSubRoutes={withSubRoutes}
        />
      )}
      <ContentCustom>
        <Spin spinning={isLoading || isParentLoading}>
          {mode === modes.SCAN && (
            <ScanContextProvider>
              <DocumentScan
                resource={resource === 'customers' ? 'companies' : resource}
              />
            </ScanContextProvider>
          )}
          {mode === modes.FORM && (
            <div>
              {steps ? (
                <div>
                  {isTrackable && (
                    <Steps
                      type="default"
                      current={current}
                      style={{ marginBottom: '25px' }}
                    >
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                  )}
                  <div className="steps-content" style={{ marginTop: '1rem' }}>
                    <Form
                      {...formItemLayout}
                      onFinish={handleSubmit}
                      fields={fields.slice(pairs.prev, pairs.next)}
                      form={form}
                      className={`${resource}-${current}`}
                    >
                      {fields.slice(pairs.prev, pairs.next).map((field) => {
                        return generateFields(tradKey || resource, field);
                      })}
                      {formExtra}
                      <Form.Item {...tailFormItemLayout}>
                        <Row justify="end">
                          {current === 0 && (
                            <>
                              {resource !== 'benefits' && (
                                <Button
                                  style={{ margin: '0 10px' }}
                                  type="link"
                                  danger
                                  onClick={() => history.goBack()}
                                >
                                  {`${t('buttons.cancel')} `}
                                  <CloseOutlined />
                                </Button>
                              )}
                            </>
                          )}
                          {current > 0 && (
                            <Button
                              type="primary"
                              style={{ margin: '0 8px' }}
                              onClick={() => prev()}
                            >
                              <ArrowLeftOutlined />
                              {`${t('buttons.prev')}`}
                            </Button>
                          )}
                          {current === steps.length - 1 && (
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => handleSubmit(fields_form)}
                            >
                              {`${t(submitLabel || 'buttons.save')} `}
                              <CheckOutlined />
                            </Button>
                          )}
                          {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                              {`${t('buttons.next')}`}
                              <ArrowRightOutlined />
                            </Button>
                          )}
                        </Row>
                        {!isTrackable && (
                          <div
                            style={{
                              float: 'right',
                              marginTop: '0.5rem',
                              color: '#f7951f'
                            }}
                          >
                            {`Étape ${current + 1}/${steps.length}`}
                          </div>
                        )}
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              ) : (
                <Form {...formItemLayout} onFinish={handleSubmit} form={form}>
                  {fields.map((field) => {
                    return generateFields(tradKey || resource, field);
                  })}
                  {formExtra}
                  <Form.Item {...tailFormItemLayout}>
                    <Row justify="end">
                      <Button
                        style={{ margin: '0 10px' }}
                        type="link"
                        danger
                        onClick={() => history.goBack()}
                      >
                        {`${t('buttons.cancel')} `}
                        <CloseOutlined />
                      </Button>
                      <Button type="primary" htmlType="submit">
                        {`${t(submitLabel || 'buttons.save')} `}
                        <CheckOutlined />
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
              )}
            </div>
          )}
        </Spin>
      </ContentCustom>
    </>
  );
};

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  withSubRoutes: PropTypes.bool,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  extraValues: PropTypes.string,
  updatedValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  datasDate: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ibanDatas: PropTypes.string,
  steps: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isTrackable: PropTypes.bool,
  isVotable: PropTypes.bool,
  legalInfos: PropTypes.object,
  /* partner_name: PropTypes.string, */
  extraQuery: PropTypes.string,
  filterParameter: PropTypes.string,
  populate: PropTypes.string,
  setForm: PropTypes.func
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  withSubRoutes: false,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  extraValues: null,
  updatedValues: null,
  datasDate: { state: false, date: null },
  ibanDatas: null,
  steps: null,
  isTrackable: true,
  isVotable: false,
  legalInfos: null,
  /* partner_name: 0, */
  extraQuery: null,
  filterParameter: null,
  populate: null,
  setForm: () => {}
};

export default CreateUpdateContainer;
