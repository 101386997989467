import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Divider, Button, Table } from 'antd';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';
import moment from 'moment';

const Payments = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [payments, setPayments] = useState([]);

  const getPayements = async record => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payment-benefits`
      });
      setPayments(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async record => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/payment-benefits/generate-global-xml/${record.date}`
      });
      const blob = new Blob([data], {
        type: 'text/xml;charset=UTF-8'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${record.date}.xml`;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getPayements();
    })();
  }, []);

  const actionColumn = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      key: 'action',
      render: record => {
        return (
          <span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={async () => {
                await downloadFile(record);
              }}
            >
              <DownloadOutlined />
            </Button>

            <Divider type="vertical" />
          </span>
        );
      }
    }
  ];

  return (
    <Table
      dataSource={payments}
      columns={actionColumn}
      style={{
        width: '50%',
        'margin-left': '5%'
      }}
    />
  );
};

export default Payments;
