import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListAdvisors from './ListAdvisors';
import CreateUpdateAdvisor from './CreateUpdateAdvisor';
import ShowAdvisor from './ShowAdvisor';

const AdvisorRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateAdvisor purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateAdvisor purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} render={() => <ShowAdvisor />} />
      <Route path={`${path}`} render={() => <ListAdvisors />} />
    </Switch>
  );
};

export default AdvisorRouter;
