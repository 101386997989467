import React, { createContext, useContext } from 'react';
import LightTheme from '../assets/styles/Theme/LightTheme';
import DarkTheme from '../assets/styles/Theme/DarkTheme';
import SFRTheme from '../assets/styles/Theme/SFRTheme';
import WelComTheme from '../assets/styles/Theme/WelComTheme';
import { useStateWithLocalStorage } from '../utils';
import useAuthContext from './AuthContext';
import Logo from '../assets/images/logostrategin.png';
import LogoWelCom from '../assets/images/logowelcomversion.png';
import LogoCollapsed from '../assets/images/logostrateginCollapsed.png';

const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useStateWithLocalStorage('darkTheme', false);
  const { user } = useAuthContext();

  // TODO: Appropiate condition for checking if it's from sfr
  const isFromSFR = email => {
    const expr = new RegExp('@sfr.fr$');

    return expr.test(email);
  };

  const isFromWelCom = email => {
    const expr = new RegExp('@wel-com.fr');

    return expr.test(email);
  };

  const isCustomTheme = email => {
    const customTheme =
      (isFromSFR(email) && <SFRTheme />) ||
      (isFromWelCom(email) && <WelComTheme />);
    return customTheme;
  };

  const getCustomLogo = email => {
    let customLogo = Logo;

    if (email && isFromWelCom(email)) {
      customLogo = LogoWelCom;
    }
    return customLogo;
  };

  const themedLogo = getCustomLogo(user?.email);
  const themedLogoCollapsed = LogoCollapsed;

  return (
    <ThemeContext.Provider
      value={{ darkMode, setDarkMode, themedLogo, themedLogoCollapsed }}
    >
      {(darkMode && <DarkTheme />) || (user && isCustomTheme(user.email)) || (
        <LightTheme />
      )}
      {children}
    </ThemeContext.Provider>
  );
};

export default () => useContext(ThemeContext);
