import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateAdvisor = ({ purpose }) => {
  const { fields } = useFields(purpose);
  const { user } = useAuthContext();

  const [store, setStore] = useState();

  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onCreateResource: {
      setBody: data => ({
        ...data,
        title: store,
        ref_partner: user._id
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="manager-advisors"
      resource="manager-advisors"
      config={config}
    />
  );
};

CreateUpdateAdvisor.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateAdvisor;
