import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const useListContent = ({
  name,
  siren_number,
  siret_number,
  tva,
  adress,
  rcs,
  ape_number,
  capital,
  manager,
  contact
}) => {
/*   const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext(); */
/*   const getManager = async () => {
    try {
      if(manager){
        const { data } = await dispatchAPI('GET', { url: `/contacts/${manager}` });
        setContact(data.first_name+" "+data.last_name);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getManager();
    })();
  }, [manager]); */

  return [
    {
      label: 'partners.form.partner.display.name',
      content: name || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.siren_number',
      content: siren_number || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.tva',
      content: tva || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.adress',
      content: adress || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.rcs',
      content: rcs || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.ape_number',
      content: ape_number || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.capital',
      content: capital || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.manager',
      content: manager || '-',
      span: 3
    },
    {
      label: 'partners.form.partner.display.contact',
      content: `${contact?.country_code}${contact?.number}` || '-',
      span: 3
    }
  ];
};

export default useListContent;
