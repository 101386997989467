import React from 'react';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListContractTypes = () => {
  const columns = useColumns();

  const headers = [
    {
      label: 'ref',
      key: 'ref'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'price',
      key: 'price'
    },
    {
      label: 'show',
      key: 'show'
    },
    {
      label: 'benefits',
      key: 'benefits'
    },
    {
      label: 'benefits',
      key: 'benefits'
    },
    {
      label: 'benefits',
      key: 'benefits'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="contract-types"
      withSubRoutes
      headers={headers}
      resourceModelName="ContractType"
      worth_styling={true}
    />
  );
};

export default ListContractTypes;
