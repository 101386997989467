import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListCustomers from './ListCustomers';
import CreateUpdateCustomer from './CreateUpdateCustomer';
import ShowCustomer from './ShowCustomer';
import CreateUpdateRequest from '../../Requests/CreateUpdateRequest';
import { routes } from '../../../utils/constants/routes';
import CreateTemplate from '../../Documents/Templates/CreateTemplate';
import ShowRequest from '../../Requests/ShowRequest';

const InvoiceRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/show/:id/create`}
        render={() => <CreateUpdateRequest purpose="create" />}
      />
      <Route
        path={`${path}/show/:benefitsId/edit/:id`}
        render={() => <CreateUpdateRequest purpose="edit" />}
      />
      <Route
        path={`${path}/show/:id/show/:benefitsId/create`}
        render={() => (
          <CreateTemplate purpose={`${routes.BENEFITS}`} id="benefitsId" />
        )}
      />
      <Route
        path={`${path}/show/:id/show/:benefitsId`}
        component={ShowRequest}
      />
      <Route
        path={`${path}/create?from=dashboard`}
        render={() => <CreateUpdateCustomer purpose="create" />}
      />
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateCustomer purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateCustomer purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowCustomer} />
      <Route path={path} component={ListCustomers} />
    </Switch>
  );
};

export default InvoiceRouter;
