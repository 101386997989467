import React, { useState } from 'react';
import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { CallsChart } from './CallsChart';
import { RetentionTable } from './RetentionTable';
import { RetentionDetailedTable } from './RetentionDetailedTable';
import { RetentionPieChart } from './RententionPieChart';

const { RangePicker } = DatePicker;

export const AdvisorStats = () => {
  const [range, setRange] = useState([
    moment([2022, 0, 1]).startOf('month'),
    moment([2022, 11, 31])
  ]);
  // const [range, setRange] = useState([moment().startOf('month'), moment()]);

  return (
    <ContentCustom>
      <Row style={{ marginBottom: 16 }}>
        <RangePicker
          value={range}
          onChange={(v) => setRange(v)}
          allowClear={false}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <Col sm={{ span: 24 }} xxl={12}>
          <CallsChart range={range} />
        </Col>
        <Col sm={{ span: 24 }} xxl={12}>
          <RetentionTable range={range} />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col sm={{ span: 24 }} xxl={12}>
          <RetentionPieChart range={range} />
        </Col>
        <Col sm={{ span: 24 }} xxl={12}>
          <RetentionDetailedTable range={range} />
        </Col>
      </Row>
    </ContentCustom>
  );
};
