import React from 'react';
import { Button, Modal } from 'antd';

export const FallbackComponent = () => (
  <div>
    <Modal
      title="Erreur"
      visible
      closable={false}
      footer={
        <Button type="primary" onClick={() => window.open('/', '_self')}>
          Retour à l&apos;accueil
        </Button>
      }
    >
      <p>Une erreur vient de se produire.</p>
      <p>
        Un rapport détaillé vient d&apos;être envoyé à notre équipe afin que
        nous puissions améliorer notre logiciel.
      </p>
      <p>
        Si l&apos;erreur persiste, n&apos;hésitez pas à nous contacter
        directement.
      </p>
      <p>En vous remerciant pour votre compréhension</p>
    </Modal>
  </div>
);
