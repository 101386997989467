import React, { useCallback, useEffect, useState } from 'react';
import { Select, Input, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [contracts, setContracts] = useState([]);

  const fields = [
    {
      label: 'commission.fields.type',
      name: ['type'],
      rule: [{ required: true }]
    },
    {
      label: 'commission.fields.status',
      name: ['status'],
      rule: [{ required: true }]
    },
    {
      label: 'commission.fields.date',
      name: ['date'],
      rule: [{ required: true }]
    },
    {
      label: 'commission.fields.contract',
      name: ['contract'],
      input: (
        <Select loading={isFieldsLoading}>
          {(contracts || []).map(elem => (
            <Option key={elem.name} value={elem._id}>
              {elem.name}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const getPartners = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/partners' });
      setPartners(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContracts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contracts' });
      setContracts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getPartners();
    await getContracts();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
