import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateDirection = ({ purpose }) => {
  const {
    fields,
    isFieldsLoading,
    steps,
    businessInformations,
    draggerImg
  } = useFields();

  const config = {
    onGetResource: {
      setFields: data => ({
        ...data,
        liberation_date: data.liberation_date && moment(data.liberation_date)
      })
    },
    onCreateResource: {
      setBody: data => ({
        ...data,
        profile: draggerImg,
        contact: data.phone_number.country_code + data.phone_number.number
      })
    },
    onUpdateResource: {
      setBody: data => {
        return {
          ...data
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="directions"
      resource="directions"
      withSubRoutes
      config={config}
      steps={steps}
      legalInfos={businessInformations}
    />
  );
};

CreateUpdateDirection.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateDirection;
