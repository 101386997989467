import React from 'react';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ErrorBoundary } from 'react-error-boundary';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { FallbackComponent } from './FallbackModal/FallbackComponent';
import Router from '../routes/Router';
import '../assets/styles/SCSS/index.scss';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

const handleError = async (error, { componentStack }) => {
  if (process.env.NODE_ENV === 'production') {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/gitlab-issues`, {
        method: 'POST',
        body: JSON.stringify({
          error: { title: error?.message, description: error.toString() },
          path: window.location.href,
          stack: componentStack
        }),
        headers: new Headers({ 'Content-type': 'application/json' })
      });
    } catch (e) {
      notification.error({ message: "Impossible de signaler l'erreur" });
    }
  }
};

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
      <LanguageContextProvider>
        <AuthContextProvider>
          <ThemeContextProvider>
            <Router />
          </ThemeContextProvider>
        </AuthContextProvider>
      </LanguageContextProvider>
    </ErrorBoundary>
  );
};

export default App;
