import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useColumns from './columns';
import useAuthContext from '../../contexts/AuthContext';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { Form, Select, Button, Modal, InputNumber } from 'antd';

const { Option } = Select;

const ListLots = () => {
  const columns = useColumns();
  const { dispatchAPI, checkShouldDisplayActionItem } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [partners, setPartners] = useState([]);

  const { t } = useTranslation();
  const generateXMLFile = async values => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/payment-partner?id=${values.id}&&amount=${values.amount}`
      });
      if (response.data) {
        const blob = new Blob([response.data], {
          type: 'text/xml;charset=UTF-8'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `SEPA_file.xml`;
        a.click();
        window.location.reload();
      }
    } catch (error) {}
  };
  const addExtraButtons = () => {
    const extraButtonArray = [];
    if (checkShouldDisplayActionItem('contracts', 'download')) {
      extraButtonArray.push(
        <Button
          onClick={showModal}
          // onClick={generateXMLFile}
          name={t('contracts.list.download')}
          icon={<DownloadOutlined />}
          style={{ 'margin-right': 5 }}
        >
          {t('contracts.list.download')}
        </Button>
      );
    }
    return extraButtonArray;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const getPartners = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/partners`
      });
      setPartners(data);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      await getPartners();
    })();
  }, []);

  return (
    <>
      <Modal
        title="Générer un fichier XML pour une partenaire virement"
        visible={isModalVisible}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={generateXMLFile}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Nom de l'entreprise"
            name="id"
            rules={[
              {
                required: true,
                message: 'Veuillez entre le nom de partenaire!'
              }
            ]}
          >
            <Select
              // defaultValue="lucy"
              style={{ width: 120 }}
              // onChange={handleChange}
            >
              {partners.map(({ _id, name }) => (
                <Option value={_id}>{name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Montant"
            name="amount"
            rules={[{ required: true, message: 'Veuillez entre le montant!' }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Générer le fichier XML
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <SimpleResourceLandingLayout
        extraButtons={addExtraButtons()}
        columns={columns}
        resourceName="partners"
        withSubRoutes
        resourceModelName="partners"
        withUploadButton={false}
        worth_styling
      />
    </>
  );
};

export default ListLots;
